import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IProductReport } from './product-report.interface'

export const useProductsByCountry = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IProductReport[]>()

  const getProductsByCountry = () => {
    execute({
      method: 'GET',
      url: `product/report/${authUser.user.company.countryCode}`,
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com'
    })
  }

  return {
    getProductsByCountry,
    loading,
    products: response ? response : []
  }
}
