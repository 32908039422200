import { ICatalogPrivate } from '../../services/catalog/catalog.interface'
  
export const actionTypes = {
  SET_CATALOG_SELECTED: 'SET_CATALOG_SELECTED',
  SET_CATALOG_UPDATED: 'SET_CATALOG_UPDATED',
}
  
const setCatalogSelected = (data: ICatalogPrivate | null) => ({
  type: actionTypes.SET_CATALOG_SELECTED,
  payload: data,
})
  
const setCatalogUpdated = (data: ICatalogPrivate) => ({
  type: actionTypes.SET_CATALOG_UPDATED,
  payload: data,
})
  
export const catalogActions = {
  setCatalogSelected,
  setCatalogUpdated,
}
