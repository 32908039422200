/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './dispatch-guide.actions'
import { ICreateDispatchGuideResponse } from '../../services/dispatch-guide-create/dispatch-guide-create.interface'
import { IDispatchGuideResponse } from '../../services/dispatch-guide-list/dispatch-guide-list.interface'
import { IGuideAssignResponse } from '../../services/dispatch-guide-assign/dispatch-guide-assign.interface'
import {
  IAddOrderDispatchResponse,
  ISummaryProductEditResponse
} from '../../services/summary-products/summary-products.interface'
import { IDispatchGuideDeleteResponse } from '../../services/dispatch-guide-delete/dispatch-guide-delete.interface'

export interface IDispatchGuideState {
  dispatchGuideSelected: IDispatchGuideResponse | null;
  dispatchGuideUpdated: ICreateDispatchGuideResponse | null;
  guideAssignUpdated: IGuideAssignResponse[] | null;
  summaryProductEdited: ISummaryProductEditResponse[] | null;
  orderAddedToDispatch: IAddOrderDispatchResponse | null;
  dispatchGuideDeleted: IDispatchGuideDeleteResponse | null;
}

const initialState: IDispatchGuideState = {
  dispatchGuideSelected: null,
  dispatchGuideUpdated: null,
  guideAssignUpdated: null,
  summaryProductEdited: null,
  orderAddedToDispatch: null,
  dispatchGuideDeleted: null,
}

export const dispatchGuideReducer = (
  state: IDispatchGuideState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_DISPATCH_GUIDE_SELECTED:
    return {
      ...state,
      dispatchGuideSelected: action.payload,
    }
  case actionTypes.SET_DISPATCH_GUIDE_UPDATED:
    return {
      ...state,
      dispatchGuideUpdated: action.payload,
    }
  case actionTypes.SET_GUIDE_ASSIGN_UPDATED:
    return {
      ...state,
      guideAssignUpdated: action.payload,
    }
  case actionTypes.SET_SUMMARY_PRODUCTS_EDITED: 
    return {
      ...state,
      summaryProductEdited: action.payload,
    }
  case actionTypes.SET_ORDER_ADDED_TO_DISPATCH:
    return {
      ...state,
      orderAddedToDispatch: action.payload,
    }
  case actionTypes.SET_DISPTACH_GUIDE_DELETED:
    return {
      ...state,
      dispatchGuideDeleted: action.payload,
    }
  default:
    return state
  }
}
