import { useAxios } from '../../hooks'
import { IPreOrderDuplicateRequest, IPreOrderDuplicateResponse } from './pre-order-duplicate.interface'

export const usePreOrderDuplicate = () => {
  const { execute, response: preOrderUpdated, loading } = useAxios<IPreOrderDuplicateResponse>()

  const preOrderDuplicate = (data: IPreOrderDuplicateRequest) => {

    execute({
      method: 'POST',
      url: '/pre-order/assign-duplicates',
      data,
    })
  }

  return {
    preOrderDuplicate,
    preOrderUpdated,
    loading,
  }
}
