import React, { useEffect, useState } from 'react'
import { Button, Form, FormInstance, Modal, Space, Typography, message, Radio, Input } from 'antd'
import { IProductSearchSchema } from '../../../../../../services/product/product.schema'
import { useProductStore } from '../../../../../../services/product/product.store'
import { ProductListSearch } from '../../../../MyPreOrdersView/components'
import { ProductDeliverySearchForm } from '../../../../OrdersView/forms'
import { IOrderShopifySchema } from '../../../../../../services/order-shopify/order-shopify.schema'
import { ProductAgencySearchForm } from '../../../../OrderAgencyView/forms'
import { CLOSING_HOURS_WAREHOUSE } from '../../../../../../services/order-create/order.params'

const { Title } = Typography

const OrderShopifyProductForm = ({
  disabled, form
}: { disabled: boolean, form: FormInstance<IOrderShopifySchema> }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { setProductSearch, productSearch, productDelete } = useProductStore()
  const [total, setTotal] = useState<number>(0)
  const shippingByAgency = Form.useWatch(['shipping', 'shippingByAgency'], form)
  const warehouseDpto = Form.useWatch(['shipping', 'dptoJson'], form)
  const warehouseToday = Form.useWatch(['warehouseCurrent'], form)

  const addDays = (days: number) => {
    form.setFieldValue('addDays', days)
  }

  const hoursWork = (hour: number) => {
    form.setFieldValue('hoursWork', hour)
  }

  const warehouseCurrent = (warName: string) => {
    form.setFieldValue('warehouseCurrent', warName)
  }

  const setTypePattern = (pattern: number) => {
    form.setFieldValue('typePattern', pattern)
  }

  const setDeliveryCost = (deliveryCost: number) => {
    form.setFieldValue(['shippingCost'], deliveryCost)
  }

  const handleShowModal = () => {
    setIsModalVisible(true)
  }

  const handleCancelModal = () => {
    setIsModalVisible(false)
  }

  const handleSubmit = (data: IProductSearchSchema) => {
    const prod = productSearch[0]

    if (prod) {
      if (prod.warehouseId === data.warehouseId) {
        setProductSearch(data)
        handleCancelModal()
      } else {
        message.warning(`Debe seleccionar un producto del almacén ${prod.warehouseName}`)
      }
    } else {
      setProductSearch(data)
      handleCancelModal()
    }
    
  }

  const handleDeleteProduct = (item: IProductSearchSchema) => {
    productDelete(item.skuId)
  }

  useEffect(() => {

    if (shippingByAgency === false) {
      if (productSearch.length > 0) {
        form.setFieldValue('enableDpto', true)
      } else {
        form.setFieldValue('enableDpto', false)
        form.setFieldValue(['shipping', 'scheduleDate'], undefined)
        form.setFieldValue(['shipping', 'dispatchDate'], undefined)
      }
    } else {
      if (productSearch.length === 0) {
        form.setFieldValue('enableDpto', false)
      } else {
        form.setFieldValue('enableDpto', true)
      }
    }

    const prodMap = productSearch?.map(p => p.subtotal)

    setTotal(prodMap?.length ? prodMap.reduce((a, b) => a + b) : 0)
  }, [productSearch])

  const hourWarehouse = (warName?: string) => {
    return warName && warName.length > 0 ? 
      (CLOSING_HOURS_WAREHOUSE[warName] ? CLOSING_HOURS_WAREHOUSE[warName].orderHour : '') : ''
  }

  return <>
    <Space style={{ marginBottom: '.5rem' }}>
      <Title level={4} style={{ marginBottom: 0 }}>Productos</Title>
      <Button type="link" onClick={handleShowModal} hidden={disabled}>+ Agregar</Button>
      <Title level={5} style={{ marginBottom: 0, fontSize: '12px' }}>
        Almacén: {warehouseToday}<br/>
        <div dangerouslySetInnerHTML={{ __html: hourWarehouse(warehouseToday) }} />
        Horario de entrega del pedido de 10AM a 5PM
      </Title>
      <Modal
        title="Buscar Producto"
        visible={isModalVisible}
        onCancel={handleCancelModal}
        footer={null}
        destroyOnClose
      >
        { shippingByAgency ? <>
          <ProductAgencySearchForm
            onSubmit={handleSubmit}
          />
        </> : <>
          <ProductDeliverySearchForm
            warehouseDpto={warehouseDpto}
            onSubmit={handleSubmit}
            addDays={addDays}
            hoursWork={hoursWork}
            warhSelected={warehouseCurrent}
            typePattern={setTypePattern}
            deliveryCost={setDeliveryCost}
          />

        </>}
        
      </Modal>
    </Space>

    <ProductListSearch
      data={productSearch}
      total={total}
      onDelete={handleDeleteProduct}
    />

    <Form.Item name={['addDays']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['hoursWork']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['warehouseCurrent']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['typePattern']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['enableDpto']} label="Activar departamento" hidden>
      <Radio.Group
        options={[
          { label: 'No', value: false },
          { label: 'Si', value: true },
        ]}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>

  </>
}

export default OrderShopifyProductForm
