import React from 'react'
import { WarehousesMaintContainer } from './containers'

const WarehousesMaintView = () => {
  return (
    <>
      <WarehousesMaintContainer/>
    </>
  )
}

export default WarehousesMaintView
