/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { useCompanyStore } from '../company-create/company-create.service'
import { useAuthStore } from '../auth/auth.service'
import { IMovementCreateSchema } from './movement.schema'
import { IMovementCreateResponse } from './movement.interface'
import { mapToMovementCreateRequest } from './movement.mapper'

export const useMovementCreate = () => {
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()
  const { execute, response: movementCreated, loading } = useAxios<IMovementCreateResponse>()

  const movementCreate = (movement: IMovementCreateSchema) => {
    
    const movementRequest = mapToMovementCreateRequest(
      movement, authUser, companyGlobal
    )

    if (movement.operation == 'TRANSFER') {
      execute({
        method: 'POST',
        url: '/warehouse-sku/transfer',
        data: movementRequest,
      })
    } else {
      execute({
        method: 'POST',
        url: '/warehouse-sku',
        data: movementRequest,
      })
    }
  }

  return {
    movementCreate,
    movementCreated,
    loading,
  }
}
