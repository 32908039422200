import React from 'react'
import { OrdersAssignmentListContainer } from './containers'

const OrderAssignmentView = () => {
  return (
    <>
      <OrdersAssignmentListContainer/>
    </>
  )
}

export default OrderAssignmentView
