import { DatePicker, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
import { OrdersMotorizedSummary, OrdersTable } from '../../components'
import moment from 'moment'
import { useMotoriedListAll } from '../../../../../services/user-motorized/user-motorized.service'
import { subDays } from 'date-fns'
import { useOrdersToReturn } from '../../../../../services/order-return/order-return.service'
import { useLocationLevel1 } from '../../../../../services/location/location.service'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { CompanyChangeContainer } from '../../../../../containers'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'

const { RangePicker } = DatePicker
const { Option } = Select

const OrdersToReturnContainer = () => {
  const dateFormat = 'YYYY-MM-DD'
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { getMotorizedAll, loadingMt, motorizeds } = useMotoriedListAll()
  const [motoSelected, setMotoSelected] = useState<string>('ALL')
  const [dptoSelected, setDptoSelected] = useState<string>('ALL')
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } = useLocationLevel1()
  const { getOrdersToReturn, orders, ordersMotorized, loading } = useOrdersToReturn()
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    moment(subDays(new Date(), 30), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])

  useEffect(() => {
    getMotorizedAll()
    getLocationsGeoLevel1()
  }, [])

  useEffect(() => {
    if (companyGlobal) {
      getOrdersToReturn(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        dptoSelected,
        motoSelected,
        `${companyGlobal.id}`
      )
    }
  }, [companyGlobal])

  const handleChangeMotorized = (motoId: string) => {
    
    if (companyGlobal) {
      getOrdersToReturn(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        dptoSelected,
        motoId,
        `${companyGlobal.id}`
      )
    }

    setMotoSelected(motoId)
  }

  const handleChangeDepartment = (name: string) => {

    if (companyGlobal) {
      getOrdersToReturn(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        name,
        motoSelected,
        `${companyGlobal.id}`
      )
    }

    setDptoSelected(name)
  }

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {

    if (companyGlobal) {
      getOrdersToReturn(
        dates[0].format('yyyy-MM-DD'),
        dates[1].format('yyyy-MM-DD'),
        dptoSelected,
        motoSelected,
        `${companyGlobal.id}`
      )
    }
    
    setSelectedDatesOrder(dates)
  }

  return (
    <>
      <PageHeader>

        <div
          style={{
            display: 'flex', justifyContent: 'end', gap: '.5rem', alignItems: 'center',
          }}
        >

          <h5 style={{ alignContent: 'center' }}>Tiendas</h5>

          { authUser.user.role?.permissions?.order_company_change ? (
            <CompanyChangeContainer isAll={true} />
          ) : null}

          <h5 style={{ alignContent: 'center' }}>Fechas</h5>

          <RangePicker format={dateFormat} 
            defaultValue={[moment(subDays(new Date(), 30), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
            onCalendarChange={handleCalendarChangeOrder} clearIcon={false}/>

          <h5 style={{ alignContent: 'center' }}>Motorizado</h5>

          <Select placeholder='Motorizado' value={motoSelected} onChange={handleChangeMotorized} 
            loading={loadingMt} style={{ display: 'flex', width: '200px' }}
            showSearch
            optionFilterProp='key'
            filterOption={(input, option) =>
              (option?.key as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            
            <Option key={'ALL'} value={'ALL'}>TODOS</Option>
            {motorizeds.map(c => (
              <Option key={c.email} value={`${c.email}`} label={c.email}>{c.email}</Option>
            ))}
            
          </Select>

          <h5 style={{ alignContent: 'center' }}>Departamento</h5>

          <Select
            onChange={handleChangeDepartment}
            showSearch
            optionFilterProp="key"
            loading={loadingGeo1}
            value={dptoSelected}
            style={{ minWidth: '120px' }}
            filterOption={(input, option) =>
              (option?.key as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option key='ALL' value='ALL'>
              TODOS
            </Option>
            {locationsGeolevel1?.map(key => (
              <Option
                key={key.name}
                value={key.name}
              >
                {key.name}
              </Option>
            ))}
          </Select>

          
        </div>

      </PageHeader>

      <OrdersMotorizedSummary 
        ordersMotorized={ordersMotorized} />

      <OrdersTable
        orders={orders}
        loading={loading}
      />

    </>
  )
}

export default OrdersToReturnContainer
