import React, { useEffect, useState } from 'react'
import { Space, Spin } from 'antd'
import { usePreOrderUpdate } from '../../../../../services/pre-order/pre-order.service'
import { PreOrderEditForm } from '../../forms'
import { useDrawer } from '../../../../../context/DrawerContext'
import Title from 'antd/lib/typography/Title'
import { formatDate } from '../../../../../utils/date'
import { EnvironmentOutlined, PhoneOutlined, TagOutlined, UserOutlined } from '@ant-design/icons'
import { IPreOrderUpdateSchema } from '../../../../../services/pre-order/pre-order.schema'
import { useOrderCreate } from '../../../../../services/order-create/order.service'
import { IS_NOT_AVAILABLE_STATUS, IS_SUCCESS_STATUS } from '../../../../../services/pre-order/pre-order.params'
import { usePreOrderStore } from '../../../../../services/pre-order/pre-order.store'
import { PreOrderDuplicates } from '../../components'

const PreOrderEditContainer = () => {
  const { preOrderUpdateStatus, orderUpdated, loading: loadingUpdate } = usePreOrderUpdate()
  const { orderCreate, orderCreated, loading: loadingSave } = useOrderCreate()
  const { preOrderSelected } = usePreOrderStore()
  const { closeDrawer } = useDrawer()
  const [preOrdeSchema, setPreOrdeSchema] = useState<IPreOrderUpdateSchema>()

  const handleFinish = (data: IPreOrderUpdateSchema) => {
    setPreOrdeSchema(data)

    if (
      IS_NOT_AVAILABLE_STATUS.includes(data.orderStatus as string) ||
      IS_SUCCESS_STATUS.includes(data.orderStatus as string)
    ) {
      orderCreate(data)
    } else {
      preOrderUpdateStatus(preOrderSelected?.orderNumber as string, data as IPreOrderUpdateSchema)
    }
  }

  useEffect(() => {
    if (orderCreated) {
      preOrderUpdateStatus(preOrderSelected?.orderNumber as string, preOrdeSchema as IPreOrderUpdateSchema)
    }
  }, [orderCreated])

  useEffect(() => {
    if (orderUpdated) {
      closeDrawer()
    }
  }, [orderUpdated])

  return <>
    {preOrderSelected ? (
      <>
        <Space align="baseline" style={{ marginBottom: '.5rem' }}>
          <Title level={3}>Pre-orden #{preOrderSelected.orderNumber}</Title>
          <span>{formatDate(preOrderSelected.createOrder)}</span>
        </Space>

        <PreOrderDuplicates />

        <div style={{ marginBottom: '0.35rem' }}>
          <Space>
            <div><TagOutlined /></div>
            <div>
              <div>{preOrderSelected.productName}</div>
              <div>{preOrderSelected.quantity} x S/{preOrderSelected.price}</div>
            </div>
          </Space>
        </div>

        <div style={{ marginBottom: '0.35rem' }}>
          <Space>
            <div><UserOutlined /></div>
            <div>
              {preOrderSelected.firstName} {preOrderSelected.lastName}
            </div>
          </Space>
        </div>

        <div style={{ marginBottom: '0.35rem' }}>
          <Space>
            <div><PhoneOutlined /></div>
            <div>
              {preOrderSelected.phone}
            </div>
          </Space>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <Space>
            <div><EnvironmentOutlined /></div>
            <div>
              {preOrderSelected.address1}. {preOrderSelected.address2}
            </div>
          </Space>
        </div>

        <Spin spinning={loadingUpdate || loadingSave}>
          <PreOrderEditForm
            initialValues={preOrderSelected}
            onSubmit={handleFinish}
          />
        </Spin>
      </>
    ) : null}
  </>
}

export default PreOrderEditContainer
