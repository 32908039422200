export interface IRoutePath {
  PATH: string;
  TITLE?: string;
  CHILDREN?: {
    [key: string]: IRoutePath,
  }
}

export const ROUTES: { [key: string]: IRoutePath } = {
  SIGN_IN: {
    PATH: '/login',
  },
  FREQUENT_QUESTION: {
    PATH: '/faq',
    TITLE: '¿Cómo usar Aliclik?'
  },
  METRICS: {
    PATH: '/metrics',
    TITLE: 'Mis métricas'
  },
  ALINEWS: {
    PATH: '/alinews',
    TITLE: 'AliNews'
  },
  DASHBOARD: {
    PATH: '/dashboard',
    TITLE: 'Dashboard'
  },
  ORDER: {
    PATH: '/order',
    TITLE: 'Pedidos',
    CHILDREN: {
      PRE_ORDERS: { PATH: 'pre-orders', TITLE: 'PreOrdenes' },
      ORDERS: { PATH: 'orders', TITLE: 'Pedidos Contraentrega' },
      DELIVERIES: { PATH: 'deliveries', TITLE: 'Seguimiento Contraentrega' },
      ORDERS_AGENCY: { PATH: 'orders-agency', TITLE: 'Envíos Agencia' },
      ORDERS_SHOPIFY: { PATH: 'orders-shopify', TITLE: 'Shopify' },
    },
  },
  MAINTENANCE: {
    PATH: '/maintenance',
    TITLE: 'Mantenimiento',
    CHILDREN: {
      STORES: { PATH: 'stores', TITLE: 'Tiendas' },
      USERS: { PATH: 'users', TITLE: 'Usuarios' },
      PRODUCTS: { PATH: 'products', TITLE: 'Productos' },
      WAREHOUSES: { PATH: 'warehouses', TITLE: 'Almacenes' },
      MOVEMENTS: { PATH: 'movements', TITLE: 'Movimientos' },
    },
  },
  ROUTESMANAGEMENT: {
    PATH: '/routes-management',
    TITLE: 'Gestión de rutas',
    CHILDREN: {
      COVERAGES: { PATH: 'coverages', TITLE: 'Coberturas' },
      ROUTES: { PATH: 'routes', TITLE: 'Rutas' },
    },
  },
  DISPATCHES: {
    PATH: '/dispatches',
    TITLE: 'Traslados',
    CHILDREN: {
      DISPATCH_MANAGEMENT: { PATH: 'dispatch-management', TITLE: 'Gestión Guías' },
      DISPATCH_ASSIGNMENT: { PATH: 'dispatch-assignment', TITLE: 'Asignación' },
      RETURN_MANAGEMENT: { PATH: 'return-management', TITLE: 'Devoluciones' },
      ORDERS_TO_RETURN: { PATH: 'orders-to-return', TITLE: 'Pedidos no entregados' },
    },
  },
  LIQUIDATIONS: {
    PATH: '/liquidations',
    TITLE: 'Liquidación',
    CHILDREN: {
      DEPOSIT_VALIDATION: { PATH: 'deposits', TITLE: 'Depósitos' },
    },
  },
  CATALOG: {
    PATH: '/catalog',
    TITLE: 'Catálogo',
    CHILDREN: {
      GENERAL_CATALOG: { PATH: 'general', TITLE: 'Catálogo General' },
      MANAGE_CATALOG: { PATH: 'manage', TITLE: 'Adminitrar Catálogos' },
    },
  },
  SETTING: {
    PATH: '/setting',
    TITLE: 'Configuración'
  },
  SUPPORT: {
    PATH: '/ticket',
    TITLE: 'Atención al Cliente',
  },
  REPORTS: {
    PATH: '/reports',
    TITLE: 'Reportes'
  },
  TASK: {
    PATH: '/task',
    TITLE: 'Logs'
  },
}
