import React from 'react'
import { OrderAgencyListContainer } from './containers'

const OrdersAgencyView = () => {
  return (
    <>
      <OrderAgencyListContainer/>
    </>
  )
}

export default OrdersAgencyView
