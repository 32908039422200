import React from 'react'
import { Table } from 'antd'
import { IProductResponse } from '../../../../../services/product/product.interface'
import { ProductSkusTable } from './parts'
import { ColumnsType } from 'antd/lib/table'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useProductStore } from '../../../../../services/product/product.store'
import useMeasureY from '../../../../../hooks/use-measurey'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { CheckCircleFilled } from '@ant-design/icons'

interface IProductsListTableProps {
  products: IProductResponse[]
  loading: boolean
  isEditable: boolean
  handlePagination: (page: number) => void
  count: number
  currentPage: number
}

const ProductsListTable: React.FC<IProductsListTableProps> = (
  { products, loading, isEditable, handlePagination, count, currentPage }
) => {
  const { windowSize } = useMeasureY()
  const { openDrawer } = useDrawer()
  const { setProductSelected } = useProductStore()
  const { authUser } = useAuthStore()

  const handleSelected = (data: IProductResponse) => {
    setProductSelected(data)
    openDrawer('PRODUCT_EDIT', 'Editar producto')
  }

  const validEdit = (prod: IProductResponse) => {
    if (authUser.user.role.name === 'ADMIN_STORE') {
      const companyId = prod.skus[0].companyId
      
      if (companyId === authUser.user.company.id) {
        return false
      }

      return true
    }

    return isEditable
  }

  const columns: ColumnsType<IProductResponse> = [
    {
      title: 'Nombre',
      dataIndex: 'name', width: '20%', align: 'center',
      render: (_, product) => <>{product.name} - {product.skus[0].company.setting.companyPrefix}</>
    },
    {
      title: 'Descripción',
      dataIndex: 'shortDescription', width: '30%',
    },
    {
      title: 'Es global',
      dataIndex: 'isProductGlobal', align: 'center', width: '10%',
      render: (_, product) => <>
        {product.isProductGlobal ? <><CheckCircleFilled style={{ color: '#009688' }}/></> : null}
      </>
    },
    {
      title: 'Precio dropshipping',
      dataIndex: 'salePriceDrop',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Precio Venta',
      dataIndex: 'retailPriceSuggested', align: 'center', width: '15%',
    },
    {
      title: 'Editar', dataIndex: 'id', align: 'center', width: '10%',
      render: (_, product) => <a onClick={() => handleSelected(product)} hidden={validEdit(product)}>Editar</a>,
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={products}
      columns={columns}
      rowClassName="editable-row"
      pagination={{ pageSize: 15, current: currentPage, total: count, onChange(page, _) {
        handlePagination(page)
      }, }}
      scroll={{
        y: windowSize.height - 200,
      }}
      loading={loading}
      expandable={{
        expandedRowRender: (record) => <ProductSkusTable record={record} />,
        rowExpandable: record => !!record.skus?.length,
      }}
      size="small"
    />
  )
}

export default ProductsListTable
