/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IOrderUpdateResponse } from '../order-update/order-update.interface'
import { IOrderLevelUser } from './order-level.interface'

export const useLevelUser = () => {
  const { execute, response, loading } = useAxios<IOrderLevelUser>()
  const { authUser } = useAuthStore()

  const getLevelUser = () => {
    execute({
      method: 'GET',
      url: '/order/level',
      params: {
        companyId: authUser.user.company.id,
      },
    })
  }

  return {
    getLevelUser,
    loadingLevel: loading,
    level: response,
  }
}

export const useOrderRescheduled = () => {
  const { execute, response, loading } = useAxios<IOrderUpdateResponse>()

  const updateValidateOrderRescheduled = (orderDeliveryId: number) => {
    execute({
      method: 'PATCH',
      url: `/order/validate/reschedule/${orderDeliveryId}`,
    })
  }

  return {
    updateValidateOrderRescheduled,
    loadingUpdated: loading,
    orderUpdated: response,
  }
}
