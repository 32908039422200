import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Form, Input, message } from 'antd'
import { ICodeAuthRequest, IEmailSchema } from '../../../../services/auth/auth.interface'
import { useSendCodeAuth, useVerifyCodeAuth } from '../../../../services/auth/auth.service'

interface IEmailVerifyFormProps {
  userId: number | undefined,
  email: boolean,
  showAuth: (isSession: boolean) => void
}

const EmailVerifyForm: FC<IEmailVerifyFormProps> = ({ userId, email, showAuth }) => {

  const [form] = Form.useForm<IEmailSchema>()
  const [isNew, setIsNew] = useState<boolean>(false)
  //const [ isSendEmail, setIsSendEmail] = useState<boolean>(false)
  const [messageAlert, setMessageAlert] = useState<string>('')
  const emailText = Form.useWatch(['email'], form)
  const codeAuthText = Form.useWatch(['codeAuth'], form)
  const { sendCodeAuth, response } = useSendCodeAuth()
  const { verifyCodeAuth, loadingCodeAuth, responseCodeAuth } = useVerifyCodeAuth()

  const onSubmit = () => {
    const request: IEmailSchema = {
      isNew: isNew,
      userId: userId,
      email: emailText
    }

    sendCodeAuth(request)

  }

  const verifyCode = () => {

    const request: ICodeAuthRequest = {
      userId: userId,
      codeAuth: codeAuthText
    }

    verifyCodeAuth(request)
  }

  useEffect(() => {
    if (email === null) {
      setIsNew(true)
    } else {
      setIsNew(false)
      onSubmit()
    }
  }, [])

  useEffect(() => {
    if (response) {
      //setIsSendEmail(true)
      setMessageAlert(`Se le envio el código de autorización a su correo ${response.email}.`)
    }
  }, [response])

  useEffect(() => {
    if (responseCodeAuth) {
      if (responseCodeAuth.isVerify) {
        showAuth(false)
        message.info('Código de autorización verificado exitosamente, volver a iniciar sesión.')
      }
    }
  }, [responseCodeAuth])

  return <>
    <Form
      name="basic"
      initialValues={{ remember: true }}
      form={form}
      onFinish={verifyCode}
      autoComplete="off"
      layout="vertical"
    >

      <Form.Item
        label={<span style={{ fontSize: '13px' }}>
        Ingrese su código de autorización
        </span>}
        name="codeAuth"
        rules={[{ 
          required: true,
          message: 'Ingrese su código autorización enviado a su correo.'
        }]}
      >
        <Input style={{ borderRadius: '20px', borderColor: '#ff9800', textAlign: 'center' }}/>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loadingCodeAuth}
          shape='round'
          style={{ backgroundColor: '#ff9800', borderColor: '#ff9800', fontWeight: 'bold' }}
          block
        >
          Verificar código de autorización
        </Button>
      </Form.Item>

      { messageAlert.length > 0 ? (
        <Alert
          message={messageAlert}
          type="error"
          style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px',
            fontSize: '12px'
          }}
        />
      ) : null } 
                  
      {/*<Form.Item
          label="Correo electrónico"
          name="email"
          rules={[{ required: true, message: 'Ingrese su correo electrónico' }]}
        >
          <Input disabled={!isNew}/>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
          >
            Recibir código de verificación
          </Button>
        </Form.Item>*/}
      
    </Form>
  </>
}

export default EmailVerifyForm
