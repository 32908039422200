import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Spin, Typography } from 'antd'
import moment from 'moment'
import { downloadExcel } from 'react-export-table-to-excel'
import { 
  useDropShalomReport
} from '../../../../services/order-report-created/order-report-created.service'
import { RangePickerProps } from 'antd/lib/date-picker'

const { Title } = Typography
const { RangePicker } = DatePicker

const DropShalomReport = () => {
  const dateFormat = 'YYYY-MM-DD'
  const { getDropShalomReport, reports, loading } = useDropShalomReport()
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    moment(new Date(), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])
  
  const headerOrder = [
    'NRO. PEDIDO', 'ESTADO', 'LINK VOUCHER', 'PAGO AGENCIA', 'ALMACÉN', 'TIENDA', 'TELÉFONO', 'DROPSHALOM'
  ]

  useEffect(() => {
    if (reports && reports.length > 0) {
      downloadExcel({
        fileName: 
          `dropShalom-report-${selectedDatesOrder[0].format('yyyy-MM-DD')}-to-
          ${selectedDatesOrder[1].format('yyyy-MM-DD')}`,
        sheet: 'react-export-table-to-excel',
        tablePayload: {
          header: headerOrder,
          // accept two different data structures
          body: reports.map(o => {
            return { 
              orderNumber: o.orderNumber,
              status: o.status,
              voucherAgency: o.voucherAgency,
              amountPayAgency: o.amountPayAgency,
              warehouse: o.warehouse,
              company: o.company,
              phone: o.phone,
              dropShalom: o.dropShalom,
            }
          })
        }
      })
    }
  }, [reports])

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {
    setSelectedDatesOrder(dates)
  }

  const handleDownloadExcelOrders = () => {
    getDropShalomReport(selectedDatesOrder[0].format('yyyy-MM-DD'), selectedDatesOrder[1].format('yyyy-MM-DD'))
  }

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > moment().startOf('day').add(1, 'day')
  }
  
  return <>
    <Spin spinning={loading}>

      <Divider />

      <Title level={5} style={{ marginBottom: '0', alignContent: 'center', padding: '.0rem 1.5rem' }}>
        Reporte de Drop Shalom y Propia
      </Title>

      <div
        style={{
          padding: '.85rem 1.5rem', display: 'flex',
          gap: '1rem', alignItems: 'center',
        }}
      >

        <Title level={5} style={{ marginBottom: '0' }}>
          Seleccionar una fecha
        </Title>

        
        <RangePicker format={dateFormat} disabledDate={disabledDate}
          defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
          onCalendarChange={handleCalendarChangeOrder} clearIcon={false}/>
        

        <Button type='primary' onClick={handleDownloadExcelOrders} 
          loading={loading}>
            Descargar reporte
        </Button>

      </div>

    </Spin>

  </>
}

export default DropShalomReport
