import { useAuthStore } from '../auth/auth.service'

export const useProductEdit = () => {
  const { roleUser } = useAuthStore()
  let companyUser = 0
  let isEditable = true

  if (roleUser?.name === 'ADMIN_STORE' || roleUser?.name === 'SELLER') {
    companyUser = 2
    isEditable = true
  } else if (roleUser?.name === 'ADMIN' || roleUser?.name === 'MASTER' || roleUser?.name === 'STORE') {
    companyUser = 1
    isEditable = false
  }

  return { companyUser, isEditable }
}
