import React, { FC } from 'react'
import { Button, Form, Input } from 'antd'
import { IAuthRequest } from '../../../../services/auth/auth.interface'
import { useDrawer } from '../../../../context/DrawerContext'
import { useNavigate } from 'react-router-dom'

interface ISignInFormProps {
  loading: boolean
  onSubmit: (auth: IAuthRequest) => void
}

const SignInForm: FC<ISignInFormProps> = ({ loading, onSubmit }) => {
  const { openDrawer } = useDrawer()
  const navigate = useNavigate()

  const handleOpenDrawer = () => {
    openDrawer('REGISTER_USER', '')
  }
  
  const handleResetPassword = () => {
    openDrawer('RESET_PASS', '')
  }

  const handleChangeQuestions = () => {
    navigate('/preguntas-frecuentes', { replace: false })
  }

  return <>
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onSubmit}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label={<span style={{ fontSize: '13px' }}>
        Usuario
        </span>}
        name="email"
        rules={[{ required: true, message: 'Ingrese su usuario' }]}
      >
        <Input style={{ borderRadius: '20px', borderColor: '#ff9800' }}/>
      </Form.Item>

      <Form.Item
        label={<span style={{ fontSize: '13px' }}>
        Contraseña
        </span>}
        name="password"
        rules={[{ required: true, message: 'Ingrese una contraseña' }]}
      >
        <Input.Password style={{ borderRadius: '20px', borderColor: '#ff9800' }}/>
      </Form.Item>

      <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
        <a onClick={handleResetPassword} style={{ color: '#ff9800' }}>
          ¿Has olvidado tu contraseña?
        </a>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          shape='round'
          style={{ backgroundColor: '#ff9800', borderColor: '#ff9800', fontWeight: 'bold' }}
          loading={loading}
          block
        >
          Iniciar sesión
        </Button>
      </Form.Item>

      <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
        <b>¿No tienes cuenta? </b><a onClick={handleOpenDrawer} style={{ color: '#ff9800', fontWeight: 'bold' }}
        >Crear nueva cuenta</a>
      </Form.Item>

      <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
        <b>¿No sabes cómo usar Aliclik? </b>
        <a onClick={handleChangeQuestions} style={{ color: '#ff9800', fontWeight: 'bold' }}>
          Haz Click Aquí 🙌
        </a>
      </Form.Item>
      
    </Form>
  </>
}

export default SignInForm
