import React from 'react'
import { ReturnMaintContainer } from './containers'

const ReturnMaintView = () => {
  return (
    <>
      <ReturnMaintContainer/>
    </>
  )
}

export default ReturnMaintView
