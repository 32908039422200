import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { useCompanyStore } from '../company-create/company-create.service'
import { ITicketSupport, ITopic } from './ticket-support-list.interface'

export const useTikectList = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading } = useAxios<ITicketSupport[]>()
  
  const getTickets = (startDate: string, endDate: string, type?: string, status?: string, search?: string) => {
    execute({
      method: 'GET',
      url: '/ticket-support',
      params: {
        companyId: companyGlobal ? companyGlobal.id === 0 ? '' : companyGlobal.id : authUser.user.company.id,
        status: status ? status === 'ALL' ? '' : status : status,
        search: search,
        type: type ? type === 'ALL' ? '' : type : type,
        startDate: startDate,
        endDate: endDate,
      },
    })
  }
  
  return {
    getTickets,
    loading,
    tickets: response ? response : []
  }
  
}

export const useTopics = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ITopic[]>()

  const getTopics = (type: string) => {
    execute({
      method: 'GET',
      url: '/ticket-support/topics',
      params: {
        countryCode: authUser.user.company.countryCode,
        type: type
      },
    })
  }
  
  return {
    getTopics,
    loadingTopics: loading,
    topics: response ? response : []
  }
}
