//import { Alert } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../../routes/params'
import { IAuthRequest } from '../../../../services/auth/auth.interface'
import { useAuthentication } from '../../../../services/auth/auth.service'
import { EmailVerifyForm, SignInForm } from '../../forms'
import { Alert } from 'antd'
import { useDrawer } from '../../../../context/DrawerContext'

const SignInContainer = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { getAuthentication, loading, error, authUser } = useAuthentication()
  const handleSubmit = (auth: IAuthRequest) => {
    getAuthentication(auth)
  }
  const [userId, setUserId] = useState<number>()
  const [session, setSession] = useState<boolean>(false)
  const [userMail, setUserMail] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [showCredentials, setShowCredencials] = useState<boolean>(false)
  const { openDrawer } = useDrawer()
  const [openSignUp, setOpenSignUp] = useState<boolean>(false)

  const showSession = (value: boolean) => {
    setSession(value)
  }

  useEffect(() => {
    const regex = /^ALICLIK-\d+$/

    if (params.code && params.code.length > 0) {

      if (regex.test(params.code)) {
        localStorage.setItem('codeReference', params.code)
        setOpenSignUp(true)
      } else {
        navigate(ROUTES.SIGN_IN.PATH, { replace: true })
      }

    }
  }, [params.code])

  useEffect(() => {
    if (openSignUp) {
      openDrawer('REGISTER_USER', 'Registrarse')
    }
  }, [openSignUp])

  useEffect(() => {
    if (authUser) {
      if (authUser.user.role.name === 'ADMIN_STORE' || authUser.user.role.name === 'ADMIN' || 
        authUser.user.role.name === 'SELLER') {

        if (authUser.user.daysCreated > 15) {
          navigate(ROUTES.ALINEWS.PATH, { replace: true })
        } else {
          navigate(ROUTES.FREQUENT_QUESTION.PATH, { replace: true })
        }

      } else if (authUser.user.role.name === 'STORE') {
        navigate('/order/orders', { replace: true })
      } else {
        navigate(ROUTES.DASHBOARD.PATH, { replace: true })
      }
    }
  }, [authUser])

  useEffect(() => {
    if (error) {
      const userId = (error as any)?.response?.data?.userId || null
      const session = (error as any)?.response?.data?.session || false
      const userMail = (error as any)?.response?.data?.userMail || false
      const isInvalidateCredentials = (error as any)?.response?.data?.isInvalidateCredentials as boolean || false

      if (isInvalidateCredentials) {
        setShowCredencials(true)
        setShowError(false)
      } 
      
      if (userMail === false && !isInvalidateCredentials) {
        setShowCredencials(false)
        setShowError(true)
      } else if (!isInvalidateCredentials) {
        setShowCredencials(false)
        setShowError(false)
      }

      if (userId && userId != null) {
        setUserId(userId)
        setSession(session)
        setUserMail(userMail)
      }
    }
  }, [error])

  return <>
    { session && userMail ? <>
    
      <EmailVerifyForm
        userId={userId}
        email={userMail}
        showAuth={showSession}
      />

    </> : <>

      <SignInForm
        onSubmit={handleSubmit}
        loading={loading}
      />

      { !showCredentials && showError ? (
        <Alert
          message="No tiene correo registrado comuníquese con soporte."
          type="error"
          style={{ marginBottom: '2rem' }}
        />
      ) : null } 

    </> }
    
    

  </>
}

export default SignInContainer
