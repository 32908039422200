import React, { FC } from 'react'
import { Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { IPreOrderItemResponse } from '../../../../../services/pre-order/pre-order.interface'
import { ORDER_STATUS_LIST_WORKING, STAGE_STATUS } from '../../../../../services/pre-order/pre-order.params'
import { StopOutlined, UserOutlined } from '@ant-design/icons'
import { formatDate } from '../../../../../utils/date'
import './PreOrderTable.css'
import { PreOrderActionIcons } from '..'
import { useAuthStore } from '../../../../../services/auth/auth.service'

interface IPreOrdersTableProps {
  data: IPreOrderItemResponse[]
  onSelectedOrder: (order: IPreOrderItemResponse) => void
  loading: boolean
}

const PreOrdersTable: FC<IPreOrdersTableProps> = ({ data, onSelectedOrder, loading }) => {
  const { authUser } = useAuthStore()
  const columns: ColumnsType<IPreOrderItemResponse> = [
    {
      title: 'Order', dataIndex: 'orderNumber', fixed: 'left', width: 100,
      render: (_, preOrder) => <>
        {authUser.user.role?.permissions?.preorder_create ? (
          <a onClick={() => onSelectedOrder(preOrder)}>{preOrder.orderNumber}</a>
        ) : (
          <span>{preOrder.orderNumber}</span>
        )}
      </>,
    },
    {
      title: 'Actions', dataIndex: 'comunication', fixed: 'left', width: 100,
      render: (_, preOrder) => <PreOrderActionIcons order={preOrder} />
    },
    {
      title: 'Stage', dataIndex: 'status', fixed: 'left', width: 100,
      render: (_, { stage }) => (
        <>
          {stage === STAGE_STATUS.WORKING ? <Tag color="#ff9800">{stage}</Tag> : null}
          {stage === STAGE_STATUS.ASSIGNED ? <Tag color="#009688">{stage}</Tag> : null}
          {stage === STAGE_STATUS.NOT_AVAILABLE ? <Tag color="#ff0066">
            <StopOutlined />
          </Tag> : null}
        </>
      ),
    },
    {
      title: 'Vendedor', dataIndex: 'userId', width: 100,
      render: (_, { user }) => (
        <>
          <Tag color="#999"><UserOutlined /> {user ? user.email : 'Ninguno'}</Tag>
        </>
      ),
    },
    {
      title: 'Status', dataIndex: 'orderStatus', width: 150,
      render: (_, { stage, order }) => (
        <>
          <Tag color={stage === STAGE_STATUS.WORKING ? '#ff9800' : '#ff0066'}>
            {ORDER_STATUS_LIST_WORKING.find(e => e.value === order?.orderStatus as string)?.label}
          </Tag>
        </>
      ),
    },
    {
      title: 'ALIBOT', dataIndex: 'responseBot', width: 140,
      render: (_, { responseBot }) => <>{responseBot}</>,
    },
    {
      title: 'Nota', dataIndex: 'orderNote', width: 140,
      render: (_, { order }) => <>{order?.note}</>,
    },
    {
      title: 'Fecha', dataIndex: 'createOrder', width: 100,
      render: (_, { createOrder }) => formatDate(createOrder),
    },
    { title: 'Producto', dataIndex: 'productName', width: 240, },
    { title: 'Variante', dataIndex: 'productVariant', width: 180, },
    { title: 'Cant.', dataIndex: 'quantity', width: 60, },
    { title: 'Precio', dataIndex: 'price', width: 60, },
    { title: 'Subtotal', dataIndex: 'subTotal', width: 80, },
    { title: 'Nombres', dataIndex: 'firstName', width: 100, },
    { title: 'Apellidos', dataIndex: 'lastName', width: 160, },
    { title: 'Telf.', dataIndex: 'phone', width: 120, },
    { title: 'Address 1', dataIndex: 'address1', width: 160, },
    { title: 'Address 2', dataIndex: 'address2', width: 160, },
    { title: 'Provincia', dataIndex: 'province', width: 160, },
    { title: 'Ciudad', dataIndex: 'city', width: 160, },
  ]

  return <>
    <Table
      columns={columns}
      dataSource={data}
      pagination={{ pageSize: 40 }}
      scroll={{
        x: 1300,
      }}
      loading={loading}
      size="small"
    />
  </>
}

export default PreOrdersTable
