import { COUNTRY_LIST, CURRENCY_LIST, BANK_LIST } from '../../config/data.params'
import { ISettingRequest } from './setting.interface'
import { ISettingSchema } from './setting.schema'

const mapToSettingRequest = (data: ISettingSchema): ISettingRequest => {
  const currency = CURRENCY_LIST.find((e) => e.code === data.currencyCode)
  const country = COUNTRY_LIST.find((e) => e.code === data.countryCode)
  const bank = BANK_LIST.find((b) => b.code === data.codeBank)

  return {
    companyId: data.companyId,
    spreadsheetOrder: data.spreadsheetOrder,
    companyPrefix: data.companyPrefix,
    currency,
    country,
    amountDelivery: +data.amountDelivery,
    phone: data.phone,
    codeBank: bank?.code,
    nameBank: bank?.value,
    accountNumber: data.accountNumber,
    cci: data.cci,
    holderName: data.holderName,
    businessDocument: data.businessDocument,
    emailTransfer: data.emailTransfer,
    businessName: data.businessName,
    documentHolderAccount: data.documentHolderAccount,
  }
}

export {
  mapToSettingRequest,
}
