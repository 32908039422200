import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IFrequentQuestion } from './frequent-question.interface'

export const useFrequentQuestions = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IFrequentQuestion[]>()

  const getQuestions = () => {
    execute({
      method: 'GET',
      url: '/frequent-question',
      params: {
        countryCode: authUser ? authUser.user.company.countryCode : 'PER',
      },
    })
  }

  return {
    getQuestions, 
    loading,
    questions: response
  }
}
