import React, { FC, useEffect, useState } from 'react'
import { Button, Form, Input, InputNumber, Select, Spin, Space, Alert } from 'antd'
import { ISelect } from '../../../../../config/data.interface'
import { useProductWarList } from '../../../../../services/product/product.service'
import { userProductStock } from '../../../../../services/warehouse-sku-all/warehouse-sku-all.service'
import { IWarehouseSkuAllResponse } from '../../../../../services/warehouse-sku-all/warehouse-sku-all.interface'
import { IProductResponse, ISkuItemResponse } from '../../../../../services/product/product.interface'
import { IProductSearchSchema } from '../../../../../services/product/product.schema'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { ILocationWarehouse } from '../../../../../services/location/location.interface'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useUserMasterAdmin } from '../../../../../services/order-create/order.edit'

const { Option } = Select

interface IProductSearchFormProps {
  warehouseDpto: any
  onSubmit: (data: IProductSearchSchema) => void
  addDays: (days: number) => void
  hoursWork: (hours: number) => void
  warhSelected: (warName: string) => void
  typePattern: (pattern: number) => void
  deliveryCost: (delivery: number) => void
}

const ProductDeliverySearchForm: FC<IProductSearchFormProps> = ({
  onSubmit, warehouseDpto, addDays, hoursWork, warhSelected, typePattern, deliveryCost
}) => {
  const [form] = Form.useForm<IProductSearchSchema>()
  const { getProductsWar, productsWar, loading } = useProductWarList()
  const { getProductStock, productStock, loadingStock } = userProductStock()
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()
  const [productList, setProductList] = useState<ISelect[]>([])
  const [variantList, setVariantList] = useState<ISelect[]>([])
  const [warehouseList, setWarehouseList] = useState<ISelect[]>([])
  const [productSelect, setProductSelect] = useState<IProductResponse>()
  const [skuSelect, setSkuSelect] = useState<ISkuItemResponse>()
  const [warMainSelect, setWarMainSelect] = useState(false)
  const [warMainMessage, setWarMainMessage] = useState(String())
  const [ warehouseSkuSelect, setWarehouseSkuSelect] = useState<IWarehouseSkuAllResponse | null>()
  const [ warSelected, setWarSelect ] = useState<ILocationWarehouse>()
  const { rolUserMaster } = useUserMasterAdmin()

  const optionsId = Form.useWatch('optionsId', form)
  const quantity = Form.useWatch('quantity', form)
  const subtotal = Form.useWatch('subtotal', form)
  const price = Form.useWatch('price', form)

  const handleChangeWarehouse = (warId: number) => {
    const war = warehouseDpto?.find((p: ILocationWarehouse) => p.id === warId)

    if (war) {
      form.setFieldValue('warehouseId', war.id)
      setSkuSelect(undefined)
      form.setFieldValue('productId', undefined)
      setWarSelect(war)
    }
  }

  const handleChangeProduct = (productId: number) => {
    const product = productsWar?.find((p) => p.id === productId)
    const variants = product?.variants.map((v) => ({
      label: v.name,
      value: v.variantId,
      options: v.options.map((o) => ({
        label: o.name,
        value: o.optionId
      }))
    }))

    setProductSelect(product)
    setVariantList(variants as ISelect[])
    if (variants?.length === 0) {
      const sku = product?.skus[0]
      
      form.setFieldValue('productName', `${productSelect?.name} (${sku?.company?.setting?.companyPrefix})`)
      form.setFieldValue('dropPrice', productSelect?.salePriceDrop)
      form.setFieldValue('sku', sku)
      form.setFieldValue('skuId', sku?.id)
      form.setFieldValue('storeCentralProductId', sku?.storeCentralProductId)
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
      form.setFieldValue('subtotal', productSelect ? productSelect?.retailPriceSuggested * quantity : 0)
      form.setFieldValue('companyId', sku?.companyId)
      setSkuSelect(sku)
    }
  }

  useEffect(() => {
    form.setFieldValue('quantity', 1)
  }, [])

  useEffect(() => {
    if (productsWar) {
      setProductList(productsWar.map((e: IProductResponse) => ({
        label: `${e.name} (${e.skus[0].company.setting.companyPrefix})`,
        value: e.id,
      })))
    }
  }, [productsWar])

  useEffect(() => {
    if (optionsId) {
      const optionsIdArray: string[] = []

      for (const key in optionsId) {
        if (Object.prototype.hasOwnProperty.call(optionsId, key)) {
          const element = optionsId[key]

          element && optionsIdArray.push(optionsId[key])
        }
      }

      const skuFound = productSelect?.skus?.find((sku) => {
        const skuOptionsArray = sku.skuOptions.map(s => s.optionId)

        return optionsIdArray.sort().toString() == skuOptionsArray.sort().toString()
      })

      //form.setFieldsValue({ warehouseId: undefined })
      form.setFieldsValue({ quantity: 1 })

      // Obtener nombre de options desde variantList
      const myOptions = variantList
        .map(e => ({
          variant: e.label,
          option: e.options.find((p: ISelect) => optionsIdArray.includes(p.value as string))?.label
        }))

      // const productName = `${productSelect?.name} / ${myOptions.map(e => `${e.variant} ${e.option}`).join(' / ')}`
      const productName = `${productSelect?.name} ${myOptions.map(e => `${e.option}`).join(' ')}`

      // Setear en fields
      form.setFieldValue('productName', `${productName} (${skuFound?.company?.setting?.companyPrefix})`)
      form.setFieldValue('dropPrice', productSelect?.salePriceDrop)
      form.setFieldValue('sku', skuFound)
      form.setFieldValue('skuId', skuFound?.id)
      form.setFieldValue('storeCentralProductId', skuFound?.storeCentralProductId)
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
      form.setFieldValue('subtotal', productSelect?.retailPriceSuggested)
      form.setFieldValue('companyId', skuFound?.companyId)
      setSkuSelect(skuFound)
    }
  }, [optionsId])

  useEffect(() => {
    const optionsIdArray: string[] = []

    for (const key in optionsId) {
      if (Object.prototype.hasOwnProperty.call(optionsId, key)) {
        const element = optionsId[key]

        element && optionsIdArray.push(optionsId[key])
      }
    }
    const myOptions = variantList
      .map(e => ({
        variant: e.label,
        option: e.options.find((p: ISelect) => optionsIdArray.includes(p.value as string))?.label
      }))

    const productName = `${productSelect?.name} ${myOptions.map(e => `${e.option}`).join(' ')}`

    form.setFieldValue('productName', `${productName} (${skuSelect?.company?.setting?.companyPrefix})`)
    form.setFieldValue('dropPrice', productSelect?.salePriceDrop)
    if (subtotal > 0) {
      form.setFieldValue('price', (subtotal / quantity).toFixed(2))
    } else {
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
    }
    form.setFieldValue('companyId', skuSelect?.companyId)
  }, [quantity, subtotal])

  useEffect(() => {
    if (warSelected) {
      form.setFieldValue('warehouseName', warSelected.name)
      form.setFieldValue('warCompanyId', warSelected.companyId)
      addDays(warSelected.addDays || 0)
      hoursWork(warSelected.hoursWork || 0)
      warhSelected(warSelected.name)
      typePattern(warSelected.typePattern || 0)
      deliveryCost(warSelected.deliveryCost || 0)

      const companyParentId = companyGlobal ? companyGlobal.parentId : authUser.user.company.parentId

      if (warSelected.companyId === companyParentId) {
        setWarMainSelect(true)
        if (warSelected.addDays === 0) {
          //setWarMainMessage('Bodega del sistema Aliclik su pedido será despachado con Alidriver')
        } else {
          //setWarMainMessage('Desde este almacén el tiempo de entrega'
          //  .concat(` aproximado será en \b${24 * (warSelected.addDays || 1)} horas\b.`))
        }
      } else {
        setWarMainSelect(false)
        setWarMainMessage('')
        //setWarMainMessage(`Eligió su propia bodega coordinar con Alidriver ${
        //  authUser.user.company.countryCode === 'PER' ? '+51953771093' : '+59169445691'
        //} para su despacho.`)
      }

      getProductsWar(String(warSelected.id), companyGlobal ? String(companyGlobal.id) : String(), rolUserMaster)
    }
  }, [warSelected])

  useEffect(() => {
    setWarehouseSkuSelect(null)
    if (skuSelect) {

      getProductStock(String(warSelected?.id), String(skuSelect.id), String(skuSelect.companyId))

    }
  }, [skuSelect])

  useEffect(() => {
    if (productStock) {
      setWarehouseSkuSelect(productStock)
    }
  }, [productStock])

  useEffect(() => {
    if (productSelect && productSelect.variants.length == 0) {
      //form.setFieldsValue({ warehouseId: undefined })
      form.setFieldValue('quantity', 1)
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
      form.setFieldValue('subtotal', productSelect?.retailPriceSuggested)
      form.setFieldValue('companyId', skuSelect?.companyId)
      form.setFieldValue('dropPrice', productSelect.salePriceDrop)
      form.setFieldValue('storeCentralProductId', skuSelect?.storeCentralProductId)
    }
  }, [productSelect])

  useEffect(() => {
    if (warehouseDpto.length > 0) {

      const companyId = companyGlobal ? companyGlobal.id : authUser.user.company.id
      const companyParentId = companyGlobal ? companyGlobal.parentId : authUser.user.company.parentId

      const wars: any[] = []

      warehouseDpto?.forEach((e: ILocationWarehouse) => {

        if (e.companyId === companyId || e.companyId === companyParentId) {
          const v = { id: e.id, name: e.name }
          
          wars.push(v)

        }

      })

      setWarehouseList(wars)
    }
  }, [warehouseDpto])

  return <>
    <Spin spinning={loading}>
      <Form
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        name="productSearch"
        labelWrap
        onFinish={onSubmit}
      >

        { warSelected ? <>
          { warMainSelect ? <>
            <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }} hidden>
              <Alert message={warMainMessage} type="info" />
            </Space>
          </> : <>
            <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }} hidden>
              <Alert message={warMainMessage} 
                type="info" />
            </Space>
          </> }
        </> : null}

        <Form.Item 
          name={['warehouseId']}
          label="Almacén"
          rules={[{ required: true, message: 'Obligatorio' }]}>
          
          { warehouseList.length > 0 ? <>

            <Select
              onChange={handleChangeWarehouse}
              showSearch
              placeholder="Seleccione un almacén"
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
              optionFilterProp="label"
            >
              {warehouseList?.map((r: ISelect) => (
                <Option key={r.name} value={r.id} label={r.name}>
                  {r.name}
                </Option>
              ))}
            </Select>

          </> : null}

        </Form.Item>

        <Form.Item name={['productId']}
          label="Producto"
          rules={[{ required: true, message: 'Obligatorio' }]}>
          <Select
            onChange={handleChangeProduct}
            showSearch
            placeholder="Search to Select"
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
            options={productList}
          />
        </Form.Item>

        {variantList?.map((variant) =>
          <Form.Item
            key={variant.value}
            name={['optionsId', variant.label]}
            label={variant.label}
            rules={[{ required: true, message: 'Obligatorio' }]}>
            <Select
              placeholder="Seleccione una opción"
              allowClear
              options={variant.options}
            />
          </Form.Item>
        )}

        <Spin spinning={loadingStock}>
          {skuSelect ? <>
            { warSelected && warehouseSkuSelect?.id && warehouseSkuSelect.stockAvailable > 0 ? <>
              <Form.Item label=''>
                <Form.Item
                  label="Stock Disponible"
                  style={{ display: 'inline-block', width: 'calc(50%)' }}
                >
                  <div style={{ color: '#0000FF' }}>{warehouseSkuSelect.stockAvailable}</div>
                </Form.Item>
                <Form.Item
                  label="Stock en bodega"
                  style={{ display: 'inline-block', width: 'calc(50%)' }}
                >
                  <div style={{ color: '#0000FF' }}>{warehouseSkuSelect.stockStore}</div>
                </Form.Item>
              </Form.Item>
              
              <Form.Item
                label="Cantidad"
                name={['quantity']}
                rules={[{ required: true, message: 'Obligatorio' }]}>
                <InputNumber
                  min={1}
                  parser={(value) => value?.replace(/\D/g, '') as unknown as number}
                  formatter={(value) => `${value}`.replace(/\D/g, '')}
                  max={warehouseSkuSelect.stockAvailable <= 6 ? warehouseSkuSelect.stockAvailable : 6 } 
                />
              </Form.Item>
              <Form.Item 
                label="Precio Unitario"
                name={['price']}>
                <div>{skuSelect?.currency.symbol} {price}</div>
              </Form.Item>
              <Form.Item 
                label="Subtotal"
                name={['subtotal']}
                rules={[{ required: true, message: 'Obligatorio' }]}>
                <InputNumber min={0}
                  parser={(value) => value?.replace(/\D/g, '') as unknown as number}
                  formatter={(value) => `${value}`.replace(/\D/g, '')}
                  value={subtotal}/>
              </Form.Item>

              <div style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" size="large" disabled={!skuSelect}>
                  Guardar
                </Button>
              </div>

            </> : <>
              <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }}>
                <Alert
                  message={`No hay stock disponible, contactarse con el PROVEDOR 
                  ${skuSelect.company.setting.phone ? skuSelect.company.setting.phone : ''}`}
                  type="info" />
              </Space>
            </>}
          </> : null}
        </Spin>


        <Form.Item name={['productName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['sku']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['dropPrice']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['skuId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['warehouseName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['warCompanyId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['companyId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['storeCentralProductId']} hidden>
          <Input />
        </Form.Item>

      </Form>
    </Spin>
  </>
}

export default ProductDeliverySearchForm
