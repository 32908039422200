import React, { FC, useEffect } from 'react'
import { IProductSearchSchema } from '../../../../../services/product/product.schema'
import { Button, Form, Input } from 'antd'

interface IProductEditProps {
  onSubmit: (data: IProductSearchSchema) => void
  product: IProductSearchSchema
  isProductRemnant?: boolean
}

const ProductEditForm: FC<IProductEditProps> = ({
  onSubmit, product, isProductRemnant
}) => {
  const [form] = Form.useForm<IProductSearchSchema>()

  useEffect(() => {
    form.setFieldsValue({
      quantity: product.quantity,
      quantityRemnant: product.quantityRemnant,
      productName: product.productName,
      price: product.price,
      id: product.id,
      skuId: product.skuId,
      sku: product.sku,
      subtotal: product.subtotal,
      warCompanyId: product.warCompanyId,
      warehouseId: product.warehouseId,
      warehouseName: product.warehouseName,
      companyId: product.companyId,
      dropPrice: product.dropPrice
    })
  }, [])

  return <>
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      name="productEdit"
      labelWrap
      form={form}
      onFinish={onSubmit}
    >

      <Form.Item label='Cantidad' name={['quantity']}>
        <Input disabled/>
      </Form.Item>

      <Form.Item label='Cantidad sobrante' name={['quantityRemnant']} hidden={!isProductRemnant}>
        <Input min={0} max={product.quantity}/>
      </Form.Item>

      <Form.Item label='Precio Unitario' name={['price']}>
        <Input disabled/>
      </Form.Item>

      <Form.Item label='Subtotal' name={['subtotal']}>
        <Input />
      </Form.Item>

      <Form.Item name={['skuId']} hidden>
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="middle">
          Guardar cambios
        </Button>
      </Form.Item>

    </Form>
  </>
}

export default ProductEditForm
