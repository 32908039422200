import React from 'react'
import { FrequentQuestionContainer } from './containers'

const FrequentQuestionView = () => {
  return <>
    <FrequentQuestionContainer/>
  </>
}

export default FrequentQuestionView
