import { IAuthResponse, IAuthUserResponse } from '../auth/auth.interface'
import { getLocations } from '../location/location.service'
import { ICoverageCreateRequest } from './coverage-create.interface'
import { ICoverageCreateSchema } from './coverage-create.schema'

export const mapToCoverageCreateRequest = (
  authUser: IAuthResponse,
  coverage: ICoverageCreateSchema,
  userSelected: IAuthUserResponse,
): ICoverageCreateRequest[] => {
  const locations: ICoverageCreateRequest[] = []

  coverage.district?.forEach(d => {
    const location = getLocations(coverage.province, d)

    locations.push({
      userId: userSelected.id,
      countryName: authUser.user.company?.setting?.country?.name,
      countryCode: authUser.user.company?.setting?.country?.code,
      departmentName: location?.department.nombre_ubigeo,
      departmentCode: location?.department.id_ubigeo,
      provinceName: location?.province.nombre_ubigeo,
      provinceCode: location?.province.id_ubigeo,
      districtName: location?.district.nombre_ubigeo,
      districtCode: location?.district.id_ubigeo,
    })
  })

  return locations
}
