//import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip, Select, Badge, Input, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
import { CompanyChangeContainer } from '../../../../../containers'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import {
  ORDER_STATUS_LIST_CONFIRMED,
  ORDER_STATUS_LIST_NOT_AVAILABLE,
} from '../../../../../services/pre-order/pre-order.params'
import { TRACKING_STATUS_LABEL } from '../../../../../services/order-create/order.params'
import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useDrawer } from '../../../../../context/DrawerContext'
import OrderAgencyTable from '../../components/OrderAgencyTable/OrderAgencyTable'
import { useOrderAgencyList, useOrdersAgencyDispatch } from '../../../../../services/order-agency/order-agency.service'
import { OrderResumen } from '../../components'
import { PAYMENT_TYPE_LABEL } from '../../../../../services/payment-create/payment.params'
import { useLocationLevel1 } from '../../../../../services/location/location.service'
import moment from 'moment'
import { subDays } from 'date-fns'
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service'
import { Document, Image, PDFDownloadLink, Page, StyleSheet, Text, View } from '@react-pdf/renderer'

const { Option, OptGroup } = Select
const { Search } = Input
const { RangePicker } = DatePicker

const OrderAgencyListContainer = () => {
  const { getOrdersAgency, loading, response, page, count, resumen } = useOrderAgencyList()
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } = useLocationLevel1()
  const { orderUpdated, setOrderSelected } = useOrderStore()
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()
  const { openDrawer } = useDrawer()
  const [defaultSelected, setDefaultSelected] = useState<string>('CONFIRMED')
  const [defaultStatus, setDefaultStatus] = useState<string>('ALL')
  const [defaultPayType, setDefaultPayType] = useState<string>('ALL')
  const [defaultManagement, setDefaultManagement] = useState<string>('ALL')
  const [defaultDpto, setDefaultDpto] = useState<string>('ALL')
  const [warSelected, setWarSelected] = useState<string>('ALL')
  const { getWarehouses, loadingWarehouses, warehouses } = useWarehouseByCountry()
  const [hiddenWar, setHiddenWar] = useState(false)
  const dateFormat = 'YYYY-MM-DD'
  const [selectedDates, setSelectedDates] = useState([
    moment(subDays(new Date(), 30), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])
  const { getOrdersAgencyDispatch, loadingOrders, ordersAgencyDispatch } = useOrdersAgencyDispatch()

  useEffect(() => {
    getLocationsGeoLevel1()
    if (authUser.user.role.name === 'STORE') {
      setWarSelected(`${authUser.user.warehouse?.id}`)
      setHiddenWar(true)
    } else {
      setWarSelected('ALL')
      setHiddenWar(false)
    }
  }, [])

  const handlePagination = (page: number) => {
    getOrdersAgency({
      callStatus: defaultSelected,
      companyId: companyGlobal?.id,
      trackingStatus: defaultStatus,
      paymentType: defaultPayType,
      departmentName: defaultDpto,
      managementType: defaultManagement,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      warehouseId: warSelected,
      page: page
    })
  }

  useEffect(() => {
    if (orderUpdated) {
      getOrdersAgency({
        callStatus: defaultSelected,
        companyId: companyGlobal?.id,
        trackingStatus: defaultStatus,
        paymentType: defaultPayType,
        departmentName: defaultDpto,
        managementType: defaultManagement,
        startDate: selectedDates[0].format('yyyy-MM-DD'),
        endDate: selectedDates[1].format('yyyy-MM-DD'),
        warehouseId: warSelected,
        page: 1,
      })
    }
  }, [orderUpdated])

  useEffect(() => {
    setDefaultSelected('CONFIRMED')
    setDefaultStatus('ALL')

    if (authUser.user.company.countryCode === 'PER') {
      if (authUser.user.role.name === 'STORE') {
        setWarSelected(`${authUser.user.warehouse?.id}`)
        setHiddenWar(true)
      } else {
        setWarSelected('ALL')
        setHiddenWar(false)
        getWarehouses()
      }

      if (companyGlobal) {
        getOrdersAgency({
          callStatus: defaultSelected,
          companyId: companyGlobal?.id,
          trackingStatus: defaultStatus,
          paymentType: defaultPayType,
          departmentName: defaultDpto,
          managementType: defaultManagement,
          warehouseId: authUser.user.role.name === 'STORE' ? `${authUser.user.warehouse?.id}` : warSelected,
          startDate: selectedDates[0].format('yyyy-MM-DD'),
          endDate: selectedDates[1].format('yyyy-MM-DD'),
          page: 1,
        })
      } else if (authUser.user.role.name === 'ADMIN_STORE') {
        getOrdersAgency({
          callStatus: defaultSelected,
          companyId: authUser.user.company.id,
          trackingStatus: defaultStatus,
          paymentType: defaultPayType,
          departmentName: defaultDpto,
          warehouseId: warSelected,
          managementType: defaultManagement,
          startDate: selectedDates[0].format('yyyy-MM-DD'),
          endDate: selectedDates[1].format('yyyy-MM-DD'),
          page: 1,
        })
      }
    } else if (authUser.user.company.countryCode === 'BOL') {
      getOrdersAgency({
        callStatus: defaultSelected,
        companyId: authUser.user.company.id,
        trackingStatus: defaultStatus,
        paymentType: defaultPayType,
        departmentName: defaultDpto,
        managementType: defaultManagement,
        warehouseId: warSelected,
        startDate: selectedDates[0].format('yyyy-MM-DD'),
        endDate: selectedDates[1].format('yyyy-MM-DD'),
        page: 1,
      })
    }
    
    
  }, [companyGlobal])

  const handleChange = (callStatus: string) => {
    getOrdersAgency({
      callStatus: callStatus,
      companyId: companyGlobal?.id,
      trackingStatus: defaultStatus,
      paymentType: defaultPayType,
      departmentName: defaultDpto,
      managementType: defaultManagement,
      warehouseId: warSelected,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      page: 1,
    })
    setDefaultSelected(callStatus)
  }

  const handleChangePay = (payType: string) => {
    getOrdersAgency({
      callStatus: defaultSelected,
      companyId: companyGlobal?.id,
      trackingStatus: defaultStatus,
      page: 1,
      paymentType: payType,
      departmentName: defaultDpto,
      managementType: defaultManagement,
      warehouseId: warSelected,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
    })
    setDefaultPayType(payType)
  }

  const handleChangeManagementType = (managementType: string) => {
    getOrdersAgency({
      callStatus: defaultSelected,
      companyId: companyGlobal?.id,
      trackingStatus: defaultStatus,
      page: 1,
      paymentType: defaultPayType,
      departmentName: defaultDpto,
      managementType: managementType,
      warehouseId: warSelected,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
    })
    setDefaultManagement(managementType)
  }

  const handleOrderCreate = () => {
    setOrderSelected(null)
    openDrawer('ORDER_AGENCY_EDIT', 'Agregar orden')
  }

  const handleOrderAgencyDispatch = () => {
    getOrdersAgencyDispatch(
      selectedDates[0].format('yyyy-MM-DD'),
      selectedDates[1].format('yyyy-MM-DD'),
    )
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    row: {
      flexGrow: 1,
      fontSize: 5,
      flexDirection: 'row',
    },
    col1: {
      width: '40%',
    },
    text: {
      width: '60%',
    },
    text1: {
      width: '100%',
    },
    text3: {
      width: '100%',
      fontSize: 8,
    },
    textRigth: {
      textAlign: 'right',
    },
    textRigth1: {
      textAlign: 'right',
      fontSize: 8,
    },
    textMedia: {
      width: '50%',
    },
    textHeader: {
      width: '100%',
      fontSize: 8,
      fontWeight: 'ultrabold',
      paddingBottom: 2,
    },
    section1: {
      borderColor: '#000',
      borderWidth: '0.5px',
      borderRadius: '5px',
      borderStyle: 'solid',
      marginTop: 4,
      marginLeft: 8,
      marginRight: 8,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section: {
      marginTop: 8,
      marginLeft: 8,
      marginRight: 8,
      paddingTop: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section2: {
      marginTop: 2,
      marginLeft: 8,
      marginRight: 8,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section3: {
      marginTop: 0,
      marginLeft: 8,
      marginRight: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    textCenter: {
      fontSize: 10,
      textAlign: 'center',
      fontWeight: 'ultrabold',
    }
  })

  const DocPdf = () => {
    return (<Document>
      { ordersAgencyDispatch && ordersAgencyDispatch.length > 0 ? <>
        { ordersAgencyDispatch.map(order => (
          <Page size={[162, 162]} key={order.orderNumber}>
            <View style={styles.section}>
              <View style={styles.row}>
                <Text style={styles.textHeader}> 
                  {order.companyName}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.text}> 
                  Destino: {order.destinationPlace}
                </Text>
                <View style={styles.col1}>
                  <Text style={styles.textRigth1}>{order.orderNumber}</Text>
                </View>
              </View>
            </View>
            <View style={styles.section1}>
              <View style={styles.row}>
                <View style={{ width: '70%' }}>
                  <View style={styles.row}>
                    <Text style={styles.text1}>
                      Cliente: {order.customerName} {order.customerLastName}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text3}>
                      Documento: {order.customerDocument}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>Cel: {order.customerPhone}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>Dirección: {order.address}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>Referencia: {order.reference}</Text>
                  </View>
                </View>
                <View style={{ width: '30%' }}>
                  <Image source={'https://aliclikapp-api.herokuapp.com/order-public/generate-qr'
                    .concat(`/${order.orderNumber}`)} />
                </View>
              </View>
            </View>
            <View style={styles.section1}>
              <View style={styles.row}>
                <Text style={styles.text}>Producto(s):</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.text1}>
                  {order.products}
                </Text>
              </View>
            </View>
          </Page>)
        )}
      </> : null}
    </Document>)
  }

  const handleChangeStatus = (status: string) => {
    getOrdersAgency({
      callStatus: defaultSelected,
      companyId: companyGlobal?.id,
      trackingStatus: status,
      paymentType: defaultPayType,
      departmentName: defaultDpto,
      managementType: defaultManagement,
      warehouseId: warSelected,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      page: 1,
    })
    setDefaultStatus(status)
  }

  const handleChangeDepartment = (name: string) => {
    getOrdersAgency({
      callStatus: defaultSelected,
      companyId: companyGlobal?.id,
      trackingStatus: defaultStatus,
      paymentType: defaultPayType,
      departmentName: name,
      managementType: defaultManagement,
      warehouseId: warSelected,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      page: 1,
    })
    setDefaultDpto(name)
  }

  const onSearch = (value: string) => {

    if (value.length > 0) {

      getOrdersAgency({
        callStatus: defaultSelected,
        companyId: companyGlobal?.id,
        trackingStatus: defaultStatus,
        paymentType: defaultPayType,
        departmentName: defaultDpto,
        managementType: defaultManagement,
        warehouseId: warSelected,
        startDate: selectedDates[0].format('yyyy-MM-DD'),
        endDate: selectedDates[1].format('yyyy-MM-DD'),
        search: value,
        page: 1,
      })

    } else {
      getOrdersAgency({
        callStatus: defaultSelected,
        companyId: companyGlobal?.id,
        trackingStatus: defaultStatus,
        search: value,
        paymentType: defaultPayType,
        departmentName: defaultDpto,
        managementType: defaultManagement,
        warehouseId: warSelected,
        startDate: selectedDates[0].format('yyyy-MM-DD'),
        endDate: selectedDates[1].format('yyyy-MM-DD'),
        page: 1
      })
    }
  }

  const handleCalendarChange = (dates: any, dateStrings: any) => {
    getOrdersAgency({
      callStatus: defaultSelected,
      companyId: companyGlobal?.id,
      trackingStatus: defaultStatus,
      paymentType: defaultPayType,
      departmentName: defaultDpto,
      managementType: defaultManagement,
      warehouseId: warSelected,
      startDate: dates[0].format('yyyy-MM-DD'),
      endDate: dates[1].format('yyyy-MM-DD'),
      page: 1
    })
    setSelectedDates(dates)
  }

  const handleChangeWarehouse = (warId: string) => {

    getOrdersAgency({
      callStatus: defaultSelected,
      companyId: companyGlobal?.id,
      trackingStatus: defaultStatus,
      paymentType: defaultPayType,
      departmentName: defaultDpto,
      managementType: defaultManagement,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      warehouseId: warId,
      page: 1
    })
    
    setWarSelected(warId)
  }

  return (
    <>
      <PageHeader>
        
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center', justifyContent: 'start', flexWrap: 'wrap' }}>
          {authUser.user.role?.permissions?.order_company_change && authUser.user.company.countryCode === 'PER' ? (
            <CompanyChangeContainer isAll={true}/>
          ) : null}

          <Search placeholder="Buscar pedidos" onSearch={onSearch} enterButton style={{ width: '200px' }} />

          <div>|</div>

          <h5>Estado llamada</h5>

          <Select
            placeholder="Estado llamada"
            style={{ minWidth: '140px' }}
            onChange={handleChange}
            value={defaultSelected}
          >
            <Option key='ALL' value='ALL'>
              <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                TODOS
              </Badge>
            </Option>
            {ORDER_STATUS_LIST_CONFIRMED.map(status => (
              <Option key={status.value} value={status.value}>{status.label}</Option>
            ))}
            <OptGroup label="ORDEN">
              {ORDER_STATUS_LIST_NOT_AVAILABLE.map(status => (
                <Option key={status.value} value={status.value}>{status.label}</Option>
              ))}
            </OptGroup>
          </Select>

          <h5>Estado de entrega</h5>

          <Select
            placeholder="Estado de seguimiento"
            style={{ minWidth: '160px' }}
            onChange={handleChangeStatus}
            value={defaultStatus}
          >
            <Option key='ALL' value='ALL'>
              <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                TODOS
              </Badge>
            </Option>
            {Object.keys(TRACKING_STATUS_LABEL).map(key => (
              <Option
                key={key}
                value={key}
              >
                {TRACKING_STATUS_LABEL[key].label}
              </Option>
            ))}
          </Select>

          <h5>Tipo de pago</h5>

          <Select
            placeholder="Tipo de pago"
            style={{ minWidth: '100px' }}
            onChange={handleChangePay}
            value={defaultPayType}
          >
            <Option key='ALL' value='ALL'>
              <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                TODOS
              </Badge>
            </Option>
            {Object.keys(PAYMENT_TYPE_LABEL).map(key => (
              <Option
                key={key}
                value={key}
              >
                {PAYMENT_TYPE_LABEL[key].label}
              </Option>
            ))}
          </Select>

          <h5>Tipo de gestión</h5>

          <Select
            placeholder="Tipo de gestión"
            style={{ minWidth: '80px' }}
            onChange={handleChangeManagementType}
            value={defaultManagement}
          >
            <Option key='ALL' value='ALL'>
              <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                TODOS
              </Badge>
            </Option>
            <Option
              key={'OWN'}
              value={'OWN'}
            >
              Propia
            </Option>
            <Option
              key={'PARTNER'}
              value={'PARTNER'}
            >
              Drop Shalom
            </Option>
          </Select>

        </div>

        <div
          style={{
            display: 'flex', justifyContent: 'start', gap: '.5rem', alignItems: 'center', marginTop: '10px',
            flexWrap: 'wrap'
          }}
        >

          { !hiddenWar ? <>
            <h5>Almacén</h5>
            <Select placeholder='Almacén' value={warSelected} onChange={handleChangeWarehouse} 
              loading={loadingWarehouses} style={{ display: 'flex', width: '150px' }}
              showSearch
              optionFilterProp='label'
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option key={'ALL'} value={'ALL'} label={'TODOS'}>TODOS</Option>
              {warehouses.map(c => (
                <Option key={c.id} value={`${c.id}`} label={c.name}>{c.name}</Option>
              ))}
            </Select>

          </> : null }

          <h5>Departamento</h5>
          <Select
            onChange={handleChangeDepartment}
            showSearch
            optionFilterProp="key"
            loading={loadingGeo1}
            value={defaultDpto}
            style={{ minWidth: '120px' }}
            filterOption={(input, option) =>
              (option?.key as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option key='ALL' value='ALL'>
              <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                TODOS
              </Badge>
            </Option>
            {locationsGeolevel1?.map(key => (
              <Option
                key={key.name}
                value={key.name}
              >
                {key.name}
              </Option>
            ))}
          </Select>

          <h5>Fechas</h5>

          <RangePicker format={dateFormat} 
            defaultValue={[moment(subDays(new Date(), 30), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
            onCalendarChange={handleCalendarChange} clearIcon={false}/>

          { authUser.user.role?.permissions?.order_create ? <>
            <Tooltip placement="bottom" title="Agregar orden">
              <Button type="primary" onClick={handleOrderCreate}>
                <PlusCircleOutlined />Nuevo Pedido
              </Button>
            </Tooltip>

            { authUser.user.role.name === 'ADMIN' ? <>
              <Button type="primary" loading={loadingOrders}
                onClick={handleOrderAgencyDispatch} style={{ marginLeft: '12px' }}>
                <DownloadOutlined />Descargar Rótulos
              </Button>
            </> : <></> }

            { ordersAgencyDispatch && ordersAgencyDispatch.length > 0 ? <>
              <PDFDownloadLink document={DocPdf()} fileName={'orders-agency.pdf'}>
                {({ blob, url, loading, error }) =>
                  <Tooltip placement='left' title="descargar rótulos">
                    <DownloadOutlined style={{ fontSize: '1.1rem', margin: '0 .25rem', cursor: 'pointer' }} />
                  </Tooltip>
                }
              </PDFDownloadLink>
            </> : <></>}
          </> : null }

          {resumen ? <OrderResumen resumen={resumen} /> : null}

        </div>

      </PageHeader>

      <OrderAgencyTable
        data={response?.result}
        loading={loading}
        handlePagination={handlePagination}
        count={count}
        currentPage={page}
      />
    </>
  )
}

export default OrderAgencyListContainer
