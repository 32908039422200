/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './return-guide.actions'
import {
  ISummaryProductEditResponse
} from '../../services/summary-products/summary-products.interface'
import { IReturnResponse } from '../../services/return-list/return-list.interface'
import { ICreateReturnResponse } from '../../services/return-create/return-create.interface'

export interface IReturnState {
  returnSelected: IReturnResponse | null;
  returnUpdated: ICreateReturnResponse | null;
  summaryProductEdited: ISummaryProductEditResponse[] | null;
}

const initialState: IReturnState = {
  returnSelected: null,
  returnUpdated: null,
  summaryProductEdited: null,
}

export const returnReducer = (
  state: IReturnState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_RETURN_SELECTED:
    return {
      ...state,
      returnSelected: action.payload,
    }
  case actionTypes.SET_RETURN_UPDATED:
    return {
      ...state,
      returnUpdated: action.payload,
    }
  case actionTypes.SET_SUMMARY_PRODUCTS_EDITED: 
    return {
      ...state,
      summaryProductEdited: action.payload,
    }
  default:
    return state
  }
}
