/* eslint-disable max-len */
import React, { FC } from 'react'
import { Tooltip } from 'antd'
import { EnvironmentOutlined } from '@ant-design/icons'
import './DeliveryActionIcons.css'
import { IOrderDeliveryItemResponse } from '../../../../../services/order-motorized/order-motorized.interface'


interface IDeliveryActionIconsProps {
  order: IOrderDeliveryItemResponse
}

const DeliveryActionIcons: FC<IDeliveryActionIconsProps> = ({ order }) => {

  return <>
    <div className="DeliveryActionIcons">

      {(order.shipping?.lat && order.shipping?.lng) ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.google.com/maps/search/?api=1&query=${order.shipping?.lat},${order.shipping?.lng}`}>
          <Tooltip placement="bottom" title="GPS">
            <EnvironmentOutlined
              className='DeliveryActionIcons-gray'
            />
          </Tooltip>
        </a>
      ) : null}
    </div>
  </>

}

export default DeliveryActionIcons
