import { IDispatchGuideResponse } from '../../services/dispatch-guide-list/dispatch-guide-list.interface'
import { ICreateDispatchGuideResponse } from '../../services/dispatch-guide-create/dispatch-guide-create.interface'
import { IGuideAssignResponse } from '../../services/dispatch-guide-assign/dispatch-guide-assign.interface'
import {
  IAddOrderDispatchResponse,
  ISummaryProductEditResponse
} from '../../services/summary-products/summary-products.interface'
import { IDispatchGuideDeleteResponse } from '../../services/dispatch-guide-delete/dispatch-guide-delete.interface'

export const actionTypes = {
  SET_DISPATCH_GUIDE_SELECTED: 'SET_DISPATCH_GUIDE_SELECTED',
  SET_DISPATCH_GUIDE_UPDATED: 'SET_DISPATCH_GUIDE_UPDATED',
  SET_GUIDE_ASSIGN_UPDATED: 'SET_GUIDE_ASSIGN_UPDATED',
  SET_SUMMARY_PRODUCTS_EDITED: 'SET_SUMMARY_PRODUCTS_EDITED',
  SET_ORDER_ADDED_TO_DISPATCH: 'SET_ORDER_ADDED_TO_DISPATCH',
  SET_DISPTACH_GUIDE_DELETED: 'SET_DISPTACH_GUIDE_DELETED',
}

const setDispatchGuideSelected = (data: IDispatchGuideResponse | null) => ({
  type: actionTypes.SET_DISPATCH_GUIDE_SELECTED,
  payload: data,
})

const setDispatchGuideUpdated = (data: ICreateDispatchGuideResponse) => ({
  type: actionTypes.SET_DISPATCH_GUIDE_UPDATED,
  payload: data,
})

const setDispatchGuideDeleted = (data: IDispatchGuideDeleteResponse) => ({
  type: actionTypes.SET_DISPTACH_GUIDE_DELETED,
  payload: data,
})

const setGuideAssignUpdated = (data: IGuideAssignResponse[]) => ({
  type: actionTypes.SET_GUIDE_ASSIGN_UPDATED,
  payload: data,
})

const setSummaryProductEdited = (data: ISummaryProductEditResponse[]) => ({
  type: actionTypes.SET_SUMMARY_PRODUCTS_EDITED,
  payload: data,
})

const setOrderAddedToDispatch = (data: IAddOrderDispatchResponse) => ({
  type: actionTypes.SET_ORDER_ADDED_TO_DISPATCH,
  payload: data,
})

export const dispatchGuideActions = {
  setDispatchGuideSelected,
  setDispatchGuideUpdated,
  setGuideAssignUpdated,
  setSummaryProductEdited,
  setOrderAddedToDispatch,
  setDispatchGuideDeleted,
}
