/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './user.actions'
import { IUserStatusResponse } from '../../services/user/user.interface'
import { IAuthUserResponse } from '../../services/auth/auth.interface'
import { IUserCreateResponse } from '../../services/user-create/user-create.interface'

export interface IUserState {
  userStatus: IUserStatusResponse | null;
  userSelected: IAuthUserResponse | null;
  userUpdated: IUserCreateResponse | null;
}

const initialState: IUserState = {
  userStatus: null,
  userSelected: null,
  userUpdated: null,
}

export const userReducer = (
  state: IUserState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_USER_STATUS:
    return {
      ...state,
      userStatus: action.payload,
    }
  case actionTypes.SET_USER_SELECTED:
    return {
      ...state,
      userSelected: action.payload,
    }
  case actionTypes.SET_USER_UPDATED:
    return {
      ...state,
      userUpdated: action.payload,
    }
  default:
    return state
  }
}
