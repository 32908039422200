/* eslint-disable max-len */
import { useAxios, useFirebase } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IPreOrderUpdateSchema } from '../pre-order/pre-order.schema'
import { mapToOrderCreateRequest, mapToOrderShopifyCreateRequest } from './order.mapper'
import { IOrderCreateResponse } from './order.interface'
import { useProductStore } from '../product/product.store'
import { usePreOrderStore } from '../pre-order/pre-order.store'
import { message } from 'antd'
import { CALL_STATUS_LIST } from '../pre-order/pre-order.params'
import { datadogLogs } from '../../hooks/use-datadog'
import { useEffect } from 'react'
import { useUserMaster } from './order.edit'
import { IOrderShopifySchema } from '../order-shopify/order-shopify.schema'
import { useOrderShopifyStore } from '../order-shopify/order-shopify.store'
import { IOrderShopify } from '../order-shopify/order-shopify.interface'

export const useOrderCreate = () => {
  const { authUser } = useAuthStore()
  const { productSearch } = useProductStore()
  const { preOrderSelected } = usePreOrderStore()
  const { orderShopifySelected } = useOrderShopifyStore()
  const { execute, response: orderCreated, loading } = useAxios<IOrderCreateResponse>()
  const { isMaster } = useUserMaster()
  const { deleteFromDatabase, updateFromDatabase } = useFirebase()

  const orderCreate = (order: IPreOrderUpdateSchema) => {
    if (!authUser.user.company.setting?.country) {
      message.warning('Configura un país en tu cuenta')

      return
    }

    if (!authUser.user.company.setting?.companyPrefix) {
      message.warning('Configura un prefijo en tu cuenta')

      return
    }

    if (!authUser.user.company.setting?.currency) {
      message.warning('Configura la moneda en tu cuenta')

      return
    }

    if (order.orderStatus === CALL_STATUS_LIST.CONFIRMED) {
      if (!productSearch?.length) {
        message.warning('Agrega productos a la orden')

        return
      }

      if (!order.shipping.shippingByAgency && !isMaster) {
        const total = productSearch.length ? productSearch
          .map(p => p.subtotal)
          .reduce((a, b) => a + b)
          : 0

        const dropCost = productSearch.length ? productSearch
          .map(p => p.dropPrice * p.quantity)
          .reduce((a, b) => a + b)
          : 0

        const sCost = () => {
          const department = order.shipping.departmentId || 0
      
          if (department === 0) return 0
          if (authUser.user.company.countryCode === 'PER') {
            return parseFloat(String(order.shippingCost)) 
          } else if (authUser.user.company.countryCode === 'BOL') {
            //potosi - tarija - oruro - cochabamba
            if (department === 4591 || department === 4589 || department === 4611 || department === 4587) {
              return 20
            } else if (department === 4588) { //la paz
              return 25
            } else if (department === 4590) { //sta cruz
              return 30
            } else {
              return 20 //default
            }
          } else {
            return order.shippingCost ? parseInt(String(order.shippingCost)) 
              : authUser.user.company.setting.amountDelivery
          }
        }

        if (total < (sCost() + dropCost + 0)) {
          message.warning(`Para agendar su pedido recuerde que el monto a cobrar mínimo es ${(sCost() + dropCost + 1.5).toFixed(2)}, lo cual cubre el costo de envío, mercadería y comisión de plataforma ℹ`)

          return
        }

      }
      
    }

    const orderRequest = mapToOrderCreateRequest(
      authUser,
      order,
      productSearch,
      preOrderSelected,
    )

    datadogLogs.logger.info('ORDER_NEW', { action: 'ORDER_NEW', order: orderRequest,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
      orderStatus: orderRequest.status,
      orderNumber: orderRequest.orderNumber
    })

    execute({
      method: 'POST',
      url: '/order',
      data: orderRequest,
    })
  }

  const orderShopifyCreate = (order: IOrderShopifySchema) => {
    if (!authUser.user.company.setting?.country) {
      message.warning('Configura un país en tu cuenta')

      return
    }

    if (!authUser.user.company.setting?.companyPrefix) {
      message.warning('Configura un prefijo en tu cuenta')

      return
    }

    if (!authUser.user.company.setting?.currency) {
      message.warning('Configura la moneda en tu cuenta')

      return
    }

    if (order.orderStatus === CALL_STATUS_LIST.CONFIRMED) {
      if (!productSearch?.length) {
        message.warning('Agrega productos a la orden')

        return
      }

      if (`${order.customer.phone}`.length < 11 && !order.shipping.shippingByAgency) {
        message.warning('Registre un número telefónico válido, agrega el 51 adelanté del número ejem: 51987458626, o verifica si los números están completos')
  
        return
      }

      if (!order.shipping.shippingByAgency && !isMaster) {
        const total = productSearch.length ? productSearch
          .map(p => p.subtotal)
          .reduce((a, b) => a + b)
          : 0

        const dropCost = productSearch.length ? productSearch
          .map(p => p.dropPrice * p.quantity)
          .reduce((a, b) => a + b)
          : 0

        const sCost = () => {
          const department = order.shipping.departmentId || 0
      
          if (department === 0) return 0
          if (authUser.user.company.countryCode === 'PER') {
            return parseFloat(String(order.shippingCost)) 
          } else if (authUser.user.company.countryCode === 'BOL') {
            return parseFloat(String(order.shippingCost))
          } else {
            return authUser.user.company.setting.amountDelivery
          }
        }

        if (total < (sCost() + dropCost + 0)) {
          message.warning(`Para agendar su pedido recuerde que el monto a cobrar mínimo es ${(sCost() + dropCost + 1.5).toFixed(2)}, lo cual cubre el costo de envío, mercadería y comisión de plataforma ℹ`)

          return
        }

      }
      
    }

    const orderRequest = mapToOrderShopifyCreateRequest(
      authUser,
      order,
      productSearch,
      orderShopifySelected,
    )

    datadogLogs.logger.info('ORDER_SHOPIFY:NEW', { action: 'ORDER_SHOPIFY:NEW', order: orderRequest,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
      orderStatus: orderRequest.status,
      orderNumber: orderRequest.orderNumber
    })

    execute({
      method: 'POST',
      url: '/order',
      data: orderRequest,
    })
  }

  useEffect(() => {
    if (orderCreated) {
      const action = 'ORDER_NEW:POST'

      datadogLogs.logger.info(action, { action: action,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        orderResponse: orderCreated,
        orderId: orderCreated?.id,
      })
    }
  }, [orderCreated])


  const deleteOrderShopify = (order: IOrderShopify) => {
    deleteFromDatabase(`shopify_orders/company_${order.companyId}/${order.id}`)
  }

  const updatedOrderShopify = (order: IOrderShopify, schema: IOrderShopifySchema) => {
    const newOrder = {
      ...order
    }

    newOrder.status = schema.orderStatus
    newOrder.note = schema.note || ''
    updateFromDatabase(`shopify_orders/company_${order.companyId}/${order.id}`, newOrder)
  }

  return {
    updatedOrderShopify,
    orderShopifyCreate,
    orderCreate,
    orderCreated,
    loading,
    deleteOrderShopify,
  }
}

