import { useAxios } from '../../hooks'
import { ICompanyItemResponse } from './company-list.interface'
import { useAuthStore } from '../auth/auth.service'
import { useEffect } from 'react'

export const useCompanyList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ICompanyItemResponse[]>()

  const getCompanies = (search? : string) => {
    execute({
      method: 'GET',
      url: '/maintenance/company',
      params: {
        countryCode: authUser.user.company.countryCode,
        search: search ? search : '',
        parentId: authUser.user.company.parentId,
      }
    })
  }

  useEffect(() => {
    getCompanies()
  }, [])

  return {
    getCompanies,
    loading,
    companies: response ? response : []
  }
}

export const useCompanyNewList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ICompanyItemResponse[]>()

  const getCompanies = (search? : string) => {
    execute({
      method: 'GET',
      url: '/maintenance/company',
      params: {
        countryCode: authUser.user.company.countryCode,
        search: search ? search : '',
        parentId: authUser.user.company.parentId
      }
    })
  }

  return {
    getCompanies,
    loading,
    companies: response ? response : []
  }
}
