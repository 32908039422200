
export const generateCombination = (groups: string[][]) => {
  if (!groups.filter(e => e)?.length) {
    return []
  }
  let auxCombinacionesFinal: string[] = []
  let auxCombinacionesTemporal: string[] = []

  /* Lista items primer grupo, deposito en auxiliar que
   utilizaré para formar las combinaciones */
  for (const item of groups[0]) {
    auxCombinacionesFinal.push(item)
  }

  /* Recorro todos los elementos desde la posición 1 de grupos hasta
  el final, y en cada grupo concateno con los de la primer lista */
  for (let indice = 1; indice < groups.length; indice++) {
    auxCombinacionesTemporal = []
    for (const item of auxCombinacionesFinal) {
      for (const elem of groups[indice]) {
        auxCombinacionesTemporal.push(item + ' ' + elem)
      }
    }
    //Igualo la lista y la vació arriba para seguir usando la temporal
    auxCombinacionesFinal = auxCombinacionesTemporal
  }

  return auxCombinacionesFinal
}
