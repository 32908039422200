import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { dispatchGuideActions } from '../../state/dispatch-guide/dispatch-guide.actions'
import { IDispatchGuideState } from '../../state/dispatch-guide/dispatch-guide.reducer'
import { IDispatchGuideResponse } from '../dispatch-guide-list/dispatch-guide-list.interface'
import { ICreateDispatchGuideResponse } from './dispatch-guide-create.interface'
import { IGuideAssignResponse } from '../dispatch-guide-assign/dispatch-guide-assign.interface'
import { IAddOrderDispatchResponse, ISummaryProductEditResponse } from '../summary-products/summary-products.interface'
import { IDispatchGuideDeleteResponse } from '../dispatch-guide-delete/dispatch-guide-delete.interface'

export const useDispatchGuideStore = () => {
  const dispatch = useDispatch()
  const {
    dispatchGuideSelected, dispatchGuideUpdated, guideAssignUpdated,
    summaryProductEdited, orderAddedToDispatch, dispatchGuideDeleted,
  } = useSelector((state: IRootState) => state.dispatchGuider as IDispatchGuideState)

  const setDispatchGuideSelected = (data: IDispatchGuideResponse | null) => {
    dispatch(dispatchGuideActions.setDispatchGuideSelected(data))
  }

  const setDispatchGuideUpdated = (data: ICreateDispatchGuideResponse) => {
    dispatch(dispatchGuideActions.setDispatchGuideUpdated(data))
  }

  const setGuideAssignUpdated = (data: IGuideAssignResponse[]) => {
    dispatch(dispatchGuideActions.setGuideAssignUpdated(data))
  }

  const setSummaryProductEdited = (data: ISummaryProductEditResponse[]) => {
    dispatch(dispatchGuideActions.setSummaryProductEdited(data))
  }

  const setOrderAddedToDispatch = (data: IAddOrderDispatchResponse) => {
    dispatch(dispatchGuideActions.setOrderAddedToDispatch(data))
  }

  const setDispatchGuideDeleted = (data: IDispatchGuideDeleteResponse) => {
    dispatch(dispatchGuideActions.setDispatchGuideDeleted(data))
  }

  return {
    setDispatchGuideSelected,
    setDispatchGuideUpdated,
    setGuideAssignUpdated,
    setSummaryProductEdited,
    setOrderAddedToDispatch,
    setDispatchGuideDeleted,
    dispatchGuideSelected,
    dispatchGuideUpdated,
    guideAssignUpdated,
    summaryProductEdited,
    orderAddedToDispatch,
    dispatchGuideDeleted,
  }
}
