import { useAxios } from '../../hooks'
import { IOrderSummary } from './order-summary.interface'

export const useOrderSummary = () => {
  const { execute, response, loading } = useAxios<IOrderSummary>()
  
  const getOrderSummary = (startDate: string, endDate: string, companyId: number, countryCode?: string) => {
    execute({
      method: 'GET',
      url: '/order/summary-order',
      params: {
        companyId: companyId,
        startDate: startDate,
        endDate: endDate,
        countryCode: countryCode,
      },
    })
  }
  
  return {
    getOrderSummary,
    loading,
    response,
  }

}
