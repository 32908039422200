import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, Space, InputNumber } from 'antd'
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store'
import {
  DISPATCH_STATUS_LIST
} from '../../../../../services/dispatch-guide-create/dispatch.params'
import {
  IProductEditSchema,
  ISummaryProductCreateSchema
} from '../../../../../services/summary-products/summary-products.schema'
import { useSummaryProducts } from '../../../../../services/summary-products/summary-products.service'

interface ISummaryProductEditFormProps {
  onSubmit: (data: ISummaryProductCreateSchema) => void
}

const SummaryProductEditForm: FC<ISummaryProductEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<ISummaryProductCreateSchema>()
  const { dispatchGuideSelected } = useDispatchGuideStore()
  const [ productEdit, setProductEdit ] = useState<boolean>(false)
  const { getSummaryProducts, summaryProducts } = useSummaryProducts()

  useEffect(() => {
    if (dispatchGuideSelected) {

      if (dispatchGuideSelected.summaryDispatchProduct.length > 0) {
        const products: IProductEditSchema[] = dispatchGuideSelected.summaryDispatchProduct.map(p => {
          const value: IProductEditSchema = {
            id: p.id,
            skuId: p.skuId,
            skuName: p.skuName,
            quantity: p.quantity,
            codeSku: p.codeSku,
            companyName: p.companyName,
            warehouseName: p.warehouseName,
            ean: p.ean,
          }
                
          return value
        })

        form.setFieldsValue({
          products,
          orderNumber: undefined,
          dispatchGuideId: dispatchGuideSelected.id,
          isEditedProducts: false
        })

      } else {

        if (dispatchGuideSelected.order.length > 0) {
          getSummaryProducts(dispatchGuideSelected.order.map(o => o.id))
        }

      }

      if (dispatchGuideSelected.dispatchStatus === DISPATCH_STATUS_LIST.REGISTERED 
        && !dispatchGuideSelected.isValidated) {
        setProductEdit(false)
      } else if (dispatchGuideSelected.dispatchStatus === DISPATCH_STATUS_LIST.IN_AGENCY) {
        setProductEdit(true)
      } else {
        setProductEdit(true)
      }

    }
  }, [dispatchGuideSelected])

  useEffect(() => {
    if (summaryProducts && summaryProducts.length > 0) {
      const products: IProductEditSchema[] = summaryProducts.map(p => {
        const value: IProductEditSchema = {
          skuId: p.skuId,
          skuName: p.name,
          quantity: p.quantity,
          codeSku: p.sku,
          warehouseId: p.warehouseId,
          warehouseName: p.warehouseName,
          companyId: p.companyId,
          companyName: p.companyName,
          ean: p.ean,
          dispatchGuideId: dispatchGuideSelected?.id
        }
              
        return value
      })

      form.setFieldsValue({
        products,
        orderNumber: undefined,
        dispatchGuideId: dispatchGuideSelected?.id,
        isEditedProducts: true
      })

    }
  }, [summaryProducts])

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='DispatchGuideForm'
        labelWrap
        onFinish={onSubmit}
      >

        <Form.Item name="dispatchGuideId" hidden={true}>
          <Input />
        </Form.Item>

        <Form.Item name="isEditedProducts" hidden={true}>
          <Input />
        </Form.Item>

        <Form.List name="products">
          {(fields) => (<>

            <Space style={{ display: 'flex', justifyContent: 'space-around' }}>
              <span></span>
              <span style={{ color: '#666' }}>EAN</span>
              <span style={{ color: '#666' }}>PRODUCTO</span>
              <span style={{ color: '#666' }}>PROP.</span>
              <span style={{ color: '#666' }}>BODEGA</span>
              <span style={{ color: '#666' }}>CANT.</span>
            </Space>

            <Space><span></span></Space>

            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex', justifyContent: 'space-around' }}>

                <Form.Item name={[name, 'id']} hidden>
                  <Input />
                </Form.Item>

                <Form.Item name={[name, 'warehouseId']} hidden>
                  <Input />
                </Form.Item>

                <Form.Item name={[name, 'companyId']} hidden>
                  <Input />
                </Form.Item>

                <Form.Item className='fullWidth' name={[name, 'ean']}>
                  <Input readOnly bordered={false} />
                </Form.Item>

                <Form.Item className='fullWidth' name={[name, 'skuName']}>
                  <Input readOnly bordered={false} size='small' />
                </Form.Item>

                <Form.Item className='fullWidth' name={[name, 'companyName']}>
                  <Input readOnly bordered={false} size='small' />
                </Form.Item>

                <Form.Item className='fullWidth' name={[name, 'warehouseName']}>
                  <Input readOnly bordered={false} size='small' />
                </Form.Item>

                <Form.Item {...restField} name={[name, 'quantity']} className='fullWidth'>
                  <InputNumber placeholder="0" readOnly={productEdit} />
                </Form.Item>
              </Space>
            ))}
          </>
          )}
        </Form.List>

        <Button type='primary' htmlType='submit' hidden={productEdit}>
          Guardar
        </Button>

        <Divider />

      </Form>
    </>
  )
}

export default SummaryProductEditForm
