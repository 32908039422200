import React from 'react'
import { ReportContainer } from './containers'

const ReportView = () => {
  return <>
    <ReportContainer />
  </>
}

export default ReportView
