import React from 'react'
import { ProductsMaintContainer } from './containers'

const ProductsMaintView = () => {
  return (
    <>
      <ProductsMaintContainer/>
    </>
  )
}

export default ProductsMaintView
