import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { ISkuResponse } from './sku-list.interface'
import { useCompanyStore } from '../company-create/company-create.service'

export const useSkuList = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading } = useAxios<ISkuResponse[]>()

  const getSkus = () => {
    execute({
      method: 'GET',
      url: '/sku',
      params: {
        companyId: companyGlobal ? companyGlobal.id : authUser.user.company.id,
      },
    })
  }

  return {
    getSkus,
    loading,
    skus: response ? response : []
  }
}
