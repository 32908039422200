import {
  ISummaryProductEditResponse
} from '../../services/summary-products/summary-products.interface'
import { IReturnResponse } from '../../services/return-list/return-list.interface'
import { ICreateReturnResponse } from '../../services/return-create/return-create.interface'

export const actionTypes = {
  SET_RETURN_SELECTED: 'SET_RETURN_SELECTED',
  SET_RETURN_UPDATED: 'SET_RETURN_UPDATED',
  SET_SUMMARY_PRODUCTS_EDITED: 'SET_SUMMARY_PRODUCTS_EDITED',
}

const setReturnSelected = (data: IReturnResponse | null) => ({
  type: actionTypes.SET_RETURN_SELECTED,
  payload: data,
})

const setReturnUpdated = (data: ICreateReturnResponse) => ({
  type: actionTypes.SET_RETURN_UPDATED,
  payload: data,
})

const setSummaryProductEdited = (data: ISummaryProductEditResponse[]) => ({
  type: actionTypes.SET_SUMMARY_PRODUCTS_EDITED,
  payload: data,
})

export const returnActions = {
  setReturnSelected,
  setReturnUpdated,
  setSummaryProductEdited,
}
