import React from 'react'
import { CoveragesContainer } from './containers'

const CoveragesView = () => {
  return (
    <>
      <CoveragesContainer/>
    </>
  )
}

export default CoveragesView


