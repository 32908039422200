export const LIQUIDATION_STATUS_LIST = {
  PENDING: 'Pendiente',
  DEPOSITED: 'Depositado',
  OBSERVED: 'Observado',
  VALIDATED: 'Validado'
}

export const LIQUIDATION_STATUS_LABEL: { [key: string]: { label: string, color: string } } = {
  PENDING: {
    label: 'Pendiente',
    color: '#FF9800',
  },
  DEPOSITED: {
    label: 'Depositado',
    color: '#1890FF',
  },
  OBSERVED: {
    label: 'Observado',
    color: '#FF0066',
  },
  VALIDATED: {
    label: 'Validado',
    color: '#009688',
  },
}
