import { Button } from 'antd'
import React, { useEffect } from 'react'
import { PageHeader } from '../../../../../components'
import { CompanyChangeContainer } from '../../../../../containers'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useCoverageStore } from '../../../../../services/coverage-create/coverage-create.store'
import { useUsersList } from '../../../../../services/user-list/user-list.service'
import { useUserStore } from '../../../../../services/user/user.service'
import { CoveragesListTable } from '../../components'

const CoveragesContainer = () => {
  const { openDrawer } = useDrawer()
  const { getUsers, users, loading } = useUsersList()
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { userUpdated, setUserSelected } = useUserStore()
  const { coverageUpdated } = useCoverageStore()

  const handleOpenDrawer = () => {
    setUserSelected(null)
    openDrawer('USER_EDIT', 'Agregar usuario')
  }

  useEffect(() => {
    if (userUpdated || coverageUpdated) {
      getUsers(companyGlobal ? `${companyGlobal.id}` : '')
    }
  }, [userUpdated, coverageUpdated])

  useEffect(() => {
    getUsers(companyGlobal ? `${companyGlobal.id}` : '')
  }, [companyGlobal])

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          {authUser.user.role?.permissions?.user_company_change ? (
            <CompanyChangeContainer type='PRINCIPAL' isAll={false} />
          ) : null}
          <Button type="primary" onClick={handleOpenDrawer}>
            Agregar motorizado
          </Button>
        </div>
      </PageHeader>
      <CoveragesListTable
        users={users?.filter((user) => user.role.name === 'MOTORIZED')}
        loading={loading}
      />
    </>
  )
}

export default CoveragesContainer
