import React, { FC } from 'react'
import { Form, Input, Radio, Select, Typography } from 'antd'
import { AGENCY_LIST, DOCUMENT_TYPE_LIST } from '../../../../../../config/data.params'
import { FormInstance } from 'antd/es/form/Form'
import { IPreOrderUpdateSchema } from '../../../../../../services/pre-order/pre-order.schema'

const { Title, Text } = Typography

interface IPreOrderAgencyPartProps {
  form: FormInstance<IPreOrderUpdateSchema>
}

const PreOrderAgencyPart: FC<IPreOrderAgencyPartProps> = ({ form }) => {
  const shippingByAgency = Form.useWatch('shippingByAgency', form)

  return <>
    <Title level={4}>Envío</Title>
    <Form.Item name="shippingByAgency" label="¿Envío por agencia?">
      <Radio.Group
        options={[
          { label: 'No', value: false },
          { label: 'Si', value: true },
        ]}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>
    {shippingByAgency ? (
      <>
        <Form.Item name={['shipping', 'agencyName']}
          label="Agencia"
          rules={[{ required: true, message: 'Obligatorio' }]}>
          <Select
            placeholder="Seleccione una opción"
            allowClear
            options={AGENCY_LIST}
          />
        </Form.Item>

        <Text style={{ marginBottom: '1rem', display: 'block' }}>
          Datos de la persona que recoge:
        </Text>

        <Form.Item label="Documento">
          <Form.Item
            name={['shipping', 'contactDocumenType']}
            rules={[{ required: true, message: 'Obligatorio' }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Select
              placeholder="Tipo de documento"
              allowClear
              options={DOCUMENT_TYPE_LIST}
            />
          </Form.Item>
          <Form.Item
            name={['shipping', 'contactDocumentNumber']}
            rules={[{ required: true, message: 'Obligatorio' }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
          >
            <Input
              placeholder="Nro de documento"
              min={0}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Nombre"
          name={['shipping', 'contactName']} rules={[{ required: true, message: 'Obligatorio' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Celular"
          name={['shipping', 'contactPhone']} rules={[{ required: true, message: 'Obligatorio' }]}>
          <Input />
        </Form.Item>
      </>
    ) : null}
  </>
}

export default PreOrderAgencyPart
