import React, { FC, useEffect, useState } from 'react'
import { Button, Form, Input, InputNumber, Select, Spin } from 'antd'
import { ISelect } from '../../../../../config/data.interface'
import { useProductFind } from '../../../../../services/product/product.service'
import { IProductResponse, ISkuItemResponse } from '../../../../../services/product/product.interface'
import { IProductSearchSchema } from '../../../../../services/product/product.schema'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useUserMasterAdmin } from '../../../../../services/order-create/order.edit'

interface IProductSearchFormProps {
  onSubmit: (data: IProductSearchSchema) => void;
}

const ProductSearchForm: FC<IProductSearchFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<IProductSearchSchema>()
  const { getProducts, products, loading } = useProductFind()
  const { companyGlobal } = useCompanyStore()
  const [productList, setProductList] = useState<ISelect[]>([])
  const [variantList, setVariantList] = useState<ISelect[]>([])
  const [productSelect, setProductSelect] = useState<IProductResponse>()
  const [skuSelect, setSkuSelect] = useState<ISkuItemResponse>()
  const { rolUserMaster } = useUserMasterAdmin()

  const optionsId = Form.useWatch('optionsId', form)
  const quantity = Form.useWatch('quantity', form)
  const subtotal = Form.useWatch('subtotal', form)
  const price = Form.useWatch('price', form)

  const handleChangeProduct = (productId: number) => {
    const product = products?.find((p) => p.id === productId)
    const variants = product?.variants.map((v) => ({
      label: v.name,
      value: v.variantId,
      options: v.options.map((o) => ({
        label: o.name,
        value: o.optionId
      }))
    }))

    setProductSelect(product)
    setVariantList(variants as ISelect[])
    if (variants?.length === 0) {
      const sku = product?.skus[0]
      
      form.setFieldValue('productName', `${productSelect?.name} (${sku?.company?.setting?.companyPrefix})`)
      form.setFieldValue('dropPrice', productSelect?.salePriceDrop)
      form.setFieldValue('sku', sku)
      form.setFieldValue('skuId', sku?.id)
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
      form.setFieldValue('subtotal', productSelect ? productSelect?.retailPriceSuggested * quantity : 0)
      form.setFieldValue('companyId', sku?.companyId)
      setSkuSelect(sku)
    }
  }

  useEffect(() => {
    form.setFieldValue('quantity', 1)
    if (rolUserMaster) {
      getProducts('', false)
    } else {
      getProducts(companyGlobal ? `${companyGlobal.id}` : '', true)
    }
    
  }, [])

  useEffect(() => {
    if (products) {
      setProductList(products.map((e: IProductResponse) => ({
        label: `${e.name} (${e.skus[0].company.setting.companyPrefix})`,
        value: e.id,
      })))
    }
  }, [products])

  useEffect(() => {
    if (optionsId) {
      const optionsIdArray: string[] = []

      for (const key in optionsId) {
        if (Object.prototype.hasOwnProperty.call(optionsId, key)) {
          const element = optionsId[key]

          element && optionsIdArray.push(optionsId[key])
        }
      }

      const skuFound = productSelect?.skus?.find((sku) => {
        const skuOptionsArray = sku.skuOptions.map(s => s.optionId)

        return optionsIdArray.sort().toString() == skuOptionsArray.sort().toString()
      })

      form.setFieldsValue({ warehouseId: undefined })
      form.setFieldsValue({ quantity: 1 })

      // Obtener nombre de options desde variantList
      const myOptions = variantList
        .map(e => ({
          variant: e.label,
          option: e.options.find((p: ISelect) => optionsIdArray.includes(p.value as string))?.label
        }))

      // const productName = `${productSelect?.name} / ${myOptions.map(e => `${e.variant} ${e.option}`).join(' / ')}`
      const productName = `${productSelect?.name} ${myOptions.map(e => `${e.option}`).join(' ')}`

      // Setear en fields
      form.setFieldValue('productName', `${productName} (${skuFound?.company?.setting?.companyPrefix})`)
      form.setFieldValue('dropPrice', productSelect?.salePriceDrop)
      form.setFieldValue('sku', skuFound)
      form.setFieldValue('skuId', skuFound?.id)
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
      form.setFieldValue('subtotal', productSelect?.retailPriceSuggested)
      form.setFieldValue('companyId', skuFound?.companyId)
      setSkuSelect(skuFound)
    }
  }, [optionsId])

  useEffect(() => {
    const optionsIdArray: string[] = []

    for (const key in optionsId) {
      if (Object.prototype.hasOwnProperty.call(optionsId, key)) {
        const element = optionsId[key]

        element && optionsIdArray.push(optionsId[key])
      }
    }
    const myOptions = variantList
      .map(e => ({
        variant: e.label,
        option: e.options.find((p: ISelect) => optionsIdArray.includes(p.value as string))?.label
      }))

    const productName = `${productSelect?.name} ${myOptions.map(e => `${e.option}`).join(' ')}`

    form.setFieldValue('productName', `${productName} (${skuSelect?.company?.setting?.companyPrefix})`)
    form.setFieldValue('dropPrice', productSelect?.salePriceDrop)
    if (subtotal > 0) {
      form.setFieldValue('price', (subtotal / quantity).toFixed(2))
    } else {
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
    }
    form.setFieldValue('companyId', skuSelect?.companyId)
  }, [quantity, subtotal])

  useEffect(() => {
    if (productSelect && productSelect.variants.length == 0) {
      form.setFieldsValue({ warehouseId: undefined })
      form.setFieldValue('quantity', 1)
      form.setFieldValue('price', productSelect?.retailPriceSuggested)
      form.setFieldValue('subtotal', productSelect?.retailPriceSuggested)
      form.setFieldValue('companyId', skuSelect?.companyId)
      form.setFieldValue('dropPrice', productSelect.salePriceDrop)
    }
  }, [productSelect])

  return <>
    <Spin spinning={loading}>
      <Form
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        name="productSearch"
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item name={['productId']}
          label="Producto"
          rules={[{ required: true, message: 'Obligatorio' }]}>
          <Select
            onChange={handleChangeProduct}
            showSearch
            placeholder="Search to Select"
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
            options={productList}
          />
        </Form.Item>

        {variantList?.map((variant) =>
          <Form.Item
            key={variant.value}
            name={['optionsId', variant.label]}
            label={variant.label}
            rules={[{ required: true, message: 'Obligatorio' }]}>
            <Select
              placeholder="Seleccione una opción"
              allowClear
              options={variant.options}
            />
          </Form.Item>
        )}


        {skuSelect ? <>            
          <Form.Item
            label="Cantidad"
            name={['quantity']}
            rules={[{ required: true, message: 'Obligatorio' }]}>
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item 
            label="Precio Unitario"
            name={['price']}>
            <div>{skuSelect?.currency.symbol} {price}</div>
          </Form.Item>
          <Form.Item 
            label="Subtotal"
            name={['subtotal']}
            rules={[{ required: true, message: 'Obligatorio' }]}>
            <InputNumber min={0} value={subtotal}/>
          </Form.Item>
        </> : null}

        <Form.Item name={['productName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['sku']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['dropPrice']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['skuId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['warehouseName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['warCompanyId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['companyId']} hidden>
          <Input />
        </Form.Item>

        <div style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" size="large" disabled={!skuSelect}>
            Guardar
          </Button>
        </div>
      </Form>
    </Spin>
  </>
}

export default ProductSearchForm
