
import React, { FC, useEffect, useState } from 'react'
import {
  Button,
  Form, FormInstance, Input, DatePicker, Select, Upload, message, InputNumber
} from 'antd'
import { IPaymentCreateSchema } from '../../../../../services/payment-create/payment-create.schema'
import {
  ENTITY_LABEL,
  PAYMENT_METHOD_LABEL,
} from '../../../../../services/payment-create/payment.params'
import { UploadOutlined } from '@ant-design/icons'
import { storage } from '../../../../../libs/firebase'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

const { Option } = Select

interface IPayAgencyFormProps {
  onSubmit: (data: IPaymentCreateSchema) => void
  orderId: number
  isOrderAgency: boolean
  amount: number
  form: FormInstance<IPaymentCreateSchema>
  currencySymbol: string
}

const PayAgencyForm: FC<IPayAgencyFormProps> = ({
  onSubmit, orderId, form, currencySymbol, isOrderAgency, amount
}) => {
  const urlPayment = Form.useWatch(['paymentDocument'], form)
  const [percent, setPercent] = useState(0)
  const [file, setFile] = useState<any>()
  const [url, setUrl] = useState('')

  function handleBeforeUpload(file: any) {
    setFile(file)
    if (!file) {
      message.success('Debe seleccionar un archivo')

      return
    }

    const storageRef = ref(storage, `/files_payment/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercent(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url)
        })
      }
    )

    return false
  }

  useEffect(() => {
    form.setFieldsValue({
      paymentDocument: '',
      paymentMethod: isOrderAgency ? 'T' : '',
      entity: '',
      paymentDate: '',
      amount: undefined,
      orderId: orderId,
      isPayMain: false,
      paymentType: ''
    })
  }, [])

  useEffect(() => {
    form.setFieldValue(['paymentDocument'], url)
  }, [url])

  return <>
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      form={form}
      name="payment"
      labelWrap
      onFinish={onSubmit}
    >
    
      <Form.Item hidden={true} name={['orderId']}>
        <Input />
      </Form.Item>
      
      <Form.Item>
        <Form.Item
          label='Método pago'
          name={['paymentMethod']}
          rules={[{ required: true, message: 'Seleccione un método de pago' }]}
          style={{ display: 'inline-block', width: 'calc(40% + 8px)' }}
        >
          <Select placeholder="Método de pago">
            {isOrderAgency ? <>
              <Option key={'T'} value={'T'} >{'Transferencia'}</Option>
            </> : <>
              {Object.keys(PAYMENT_METHOD_LABEL).map(key => (
                <Option key={key} value={key} >{PAYMENT_METHOD_LABEL[key].label}</Option>
              ))}
            </>}
          </Select>
        </Form.Item>
        <Form.Item
          label='Entidad'
          name={['entity']}
          rules={[{ required: true, message: 'Seleccione una entidad' }]}
          style={{ display: 'inline-block', width: 'calc(40%)' }}
        >
          <Select
            placeholder="Entidad">
            {Object.keys(ENTITY_LABEL).map(key => (
              <Option key={key} value={key} >{ENTITY_LABEL[key].label}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>
      <Form.Item>
        <Form.Item label="Fecha de pago"
          name={['paymentDate']}
          rules={[{ required: true, message: 'Seleccionar una fecha de pago' }]}
          style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}>
          <DatePicker format={'DD/MM/YYYY'} />
        </Form.Item>
        <Form.Item name={['paymentDocument']}
          label="Seleccione un archivo:"
          rules={[{ required: false, message: 'Adjuntar documento de pago' }]}
          style={{ display: 'inline-block', width: 'calc(40%)' }}
        >
          <Upload 
            name='file'
            beforeUpload={handleBeforeUpload}
            maxCount={1}
            fileList={[]}
          >
            <Button>
              <UploadOutlined /> {file ? file.name : ''} {percent} %
            </Button>
          </Upload>
          { urlPayment?.length !== 0 ? <>
            <a 
              href={url} 
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >Visualizar archivo adjunto</a>
          </> : null }
        </Form.Item>
        
      </Form.Item>

      <Form.Item>
        <Form.Item label={`Monto ${currencySymbol}`}
          name={['amount']}
          rules={[{ required: true, message: 'Ingresar monto menor o igual al total' }]}
          style={{ display: 'inline-block', width: 'calc(40%)' }}>
          <InputNumber min={0} max={amount}/>
        </Form.Item>
      </Form.Item>
        
      <Form.Item name={['isPayMain']} hidden={true}></Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="middle">
          Guardar Pago
        </Button>
      </Form.Item>
    </Form>
  </>
}

export default PayAgencyForm
