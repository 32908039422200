import React from 'react'
import { UsersMaintContainer } from './containers'

const UsersMaintView = () => {
  return (
    <>
      <UsersMaintContainer/>
    </>
  )
}

export default UsersMaintView
