import React from 'react'
import { useAuthStore } from '../../../services/auth/auth.service'

const AliNewsView = () => {
  const { authUser } = useAuthStore()

  return (
    <>
      { authUser.user.company.countryCode === 'PER' ? <>
        <iframe loading="lazy" width="100%" height="100%"
          src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFs-3tNQm0&#x2F;view?embed" allow="fullscreen">
        </iframe>
      </> : <>
        <iframe loading="lazy" width="100%" height="100%"
          src=
            "https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGL_nhEBI8&#x2F;-8Edg8Hw0BczVRAthEgm4A&#x2F;view?embed" 
          allow="fullscreen">
        </iframe>
      </> 
      }
      
    </>
  )
}

export default AliNewsView
