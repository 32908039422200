import { Button, Input } from 'antd'
import React, { useEffect } from 'react'
import { PageHeader } from '../../../../../components'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useCompanyList } from '../../../../../services/company-list/company-list.service'
import { StoresListTable } from '../../components'

const { Search } = Input

const StoresMaintContainer = () => {
  const { getCompanies, companies, loading } = useCompanyList()
  const { openDrawer } = useDrawer()
  const { companyUpdated, setCompanySelected } = useCompanyStore()

  const handleOpenDrawer = () => {
    setCompanySelected(null)
    openDrawer('COMPANY_EDIT', 'Agregar tienda')
  }

  useEffect(() => {
    if (companyUpdated) {
      getCompanies()
    }
  }, [companyUpdated])

  const onSearch = (value: string) => {

    if (value.length > 0) {

      getCompanies(value)

    } else {
      getCompanies(value)
    }
  }

  return (
    <>
      <PageHeader>
        <div>
          <Button type="primary" onClick={handleOpenDrawer}>
            Agregar tienda
          </Button>

          <Search 
            placeholder="Buscar tiendas" onSearch={onSearch}
            enterButton
            allowClear
            style={{ width: 200, marginLeft: '12px' }} />
        </div>
      </PageHeader>
      <StoresListTable
        companies={companies}
        loading={loading}
      />
    </>
  )
}

export default StoresMaintContainer
