import { useAxios } from '../../hooks'
import { IPaymentResponse } from './payment-list.interface'

export const usePaymentList = () => {
  const { execute, response, loading } = useAxios<IPaymentResponse[]>()

  const getPayments = (orderId: string) => {
    execute({
      method: 'GET',
      url: '/payment',
      params: {
        orderId: orderId,
      },
    })
  }

  return {
    getPayments,
    loading,
    payments: response ? response : []
  }
}
