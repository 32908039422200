import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IFilterLiquidation, ILiquidationResponse, ILiquidationTotal } from './liquidation-list.interface'

export const useLiquidationTotal = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ILiquidationTotal>()

  const getLiquidationTotal = (deliveryDate: string) => {
    execute({
      method: 'GET',
      url: '/liquidation/total-global',
      params: {
        startDate: deliveryDate,
        companyId: authUser.user.company.id,
      },
    })
  }

  return {
    getLiquidationTotal,
    loadingTotal: loading,
    summaryTotal: response,
  }

}

export const useLiquidationList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ILiquidationResponse[]>()

  const getLiquidations = (filter: IFilterLiquidation) => {
    execute({
      method: 'GET',
      url: '/liquidation',
      params: {
        deliveryDate: filter.deliveryDate,
        status: filter.status ? filter.status : '',
        userId: filter.userId ? filter.userId : '',
        companyId: authUser.user.company.id,
      },
    })
  }

  return {
    getLiquidations,
    loading,
    liquidations: response ? response : [],
  }
}
