import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Header } from 'antd/lib/layout/layout'
import './Navbar.css'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../routes/params'

interface INavbarProps {
  children: ReactNode;
}

const Navbar: FC<INavbarProps> = ({ children }) => {
  const [title, setTitle] = useState<string>('')
  const location = useLocation()
  const locationSplit = location.pathname
    .split('/')
    .filter(e => e)

  const path = locationSplit[0]
  const subPath = locationSplit[1]

  useEffect(() => {
    for (const module in ROUTES) {
      if (Object.prototype.hasOwnProperty.call(ROUTES, module)) {
        const element = ROUTES[module]

        if (`/${path}` === element.PATH) {
          // If there are no children
          if (!element.CHILDREN) {
            setTitle(element.TITLE as string)
            break
          }

          // Search in children
          for (const item in element.CHILDREN) {
            const child = element.CHILDREN[item]

            if (subPath === child.PATH) {
              setTitle(child.TITLE as string)
              break
            }
          }

          break
        }
      }
    }
  }, [])

  return (
    <Header className="Navbar__header">
      <div className="Navbar__logo_menu">
        <span>{children}</span>
        <span style={{ color: 'white', fontSize: '16px', fontWeight: 'bold' }}>Aliclik / {title}</span>
      </div>
      <div className="Navbar_usermenu">
        {/*<UserSwitchContainer/>*/}
        {/*<a target="_blank" rel="noreferrer" href='https://chat.whatsapp.com/ByrHexSIX2p6cnRS72pab9'
          style={{ fontSize: '16px' }}>
          <Tooltip placement="left" title="Aliclik Comunicados">
            <WhatsAppOutlined
              className='OrderActionIcons-green'
            />
          </Tooltip>
        </a>*/}
      </div>
    </Header>
  )
}

export default Navbar
