import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useDrawer } from '../../../../../context/DrawerContext'
import { DispatchGuideEditForm } from '../../forms'
import { useDispatchGuideCreate } from '../../../../../services/dispatch-guide-create/dispatch-guide-create.service'
import { ICreateDispatchGuideSchema } from '../../../../../services/dispatch-guide-create/dispatch-guide-create.schema'
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store'

const DispatchGuideEditContainer = () => {
  const { saveDispatchGuide, dispatchGuideCreated, loading } = useDispatchGuideCreate()
  const { closeDrawer } = useDrawer()
  const { setDispatchGuideUpdated } = useDispatchGuideStore()

  const handleSubmit = (data: ICreateDispatchGuideSchema) => {
    saveDispatchGuide(data)
  }

  useEffect(() => {
    if (dispatchGuideCreated) {
      setDispatchGuideUpdated(dispatchGuideCreated)
      closeDrawer()
    }
  }, [dispatchGuideCreated])

  return (
    <>
      <Spin spinning={loading}>
        <DispatchGuideEditForm
          onSubmit={handleSubmit}
        />
      </Spin>
    </>
  )
}

export default DispatchGuideEditContainer
