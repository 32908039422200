import React, { FC } from 'react'
import { Badge, Card, Divider, Tag, Typography } from 'antd'
import { IOrderDeliveryResponse } from '../../../../../services/order-motorized/order-motorized.interface'
import './DeliveryResumen.css'
import { MotocycleIcon } from '../../../../../components/Icons'
import { IMotorizedResponse } from '../../../../../services/user-motorized/user-motorized.interface'

const { Title } = Typography

interface IOrderResumenProps {
  orders: IOrderDeliveryResponse[]
  motorizeds: IMotorizedResponse[]
}

const DeliveryResumen: FC<IOrderResumenProps> = ({ orders, motorizeds }) => {
  return (
    <Card style={{ margin: '1rem 1.5rem' }}>
      <Title level={5}>Distribución de motorizados</Title>

      <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))' }}>
        {orders.sort((a, b) => b.totalOrder - a.totalOrder).map((orderByMoto) => (
          <div key={orderByMoto.motorized}>

            <div style={{ display: 'flex', gap: '0.35rem' }}>
              <Badge
                count={orderByMoto.totalOrder}
                style={{ backgroundColor: orderByMoto.motorized === 'Sin asignar' ? '#ff0066' : '#1890ff' }}
              />
              <span style={{ color: '#888' }}>
                <MotocycleIcon />
              </span>
              <span style={{ fontSize: '16px' }}>
                {orderByMoto.motorized}
                <Tag color={`${motorizeds.find(it => it.email === orderByMoto.motorized)?.markerIcon?.color}`}
                  style={{ width: '15px', height: '15px', marginLeft: 4 }}
                ></Tag>
              </span>
            </div>

            <Divider style={{ margin: '.35rem 0' }} />

            {/* <div style={{ fontSize: '12px' }}>
              <span><b>Por distrito:</b></span>
              {orderByMoto.orders?.map((orderByDistrict) => (
                <div
                  key={orderByDistrict.district}
                  style={{ display: 'flex', gap: '0.35rem' }}
                >
                  <Badge
                    count={orderByDistrict.totalOrder}
                    style={{ backgroundColor: '#888' }}
                  />
                  {orderByDistrict.district}
                </div>
              ))}
            </div> */}
          </div>
        ))}
      </div>

    </Card>
  )
}

export default DeliveryResumen
