import { useAuthStore } from '../auth/auth.service'

export const useUserSupSeller = () => {
  const { roleUser } = useAuthStore()
  let isUser = false

  if (roleUser?.name === 'ADMIN_STORE') {
    isUser = true
  }
  
  return { isUser }
}

export const useUserMaster = () => {
  let isMaster = false
  const { roleUser } = useAuthStore()
  
  if (roleUser?.name === 'MASTER') {
    isMaster = true
  }

  return { isMaster }
}

export const useUserAdmin = () => {
  let isAdmin = false
  const { roleUser } = useAuthStore()

  if (roleUser?.name === 'ADMIN') {
    isAdmin = true
  }

  return { isAdmin }
}

export const useUserMasterAdmin = () => {
  let rolUserMaster = false
  const { roleUser } = useAuthStore()
  
  if (roleUser?.name === 'ADMIN' || roleUser?.name === 'MASTER') {
    rolUserMaster = true
  }

  return { rolUserMaster }
}

export const useOrderConfirmedEdit = () => {
  const { roleUser } = useAuthStore()
  let companyUser = 0
  let isEditable = false

  if (roleUser?.name === 'ADMIN_STORE' || roleUser?.name === 'SELLER') {
    companyUser = 2
  } else if (roleUser?.name === 'ADMIN' || roleUser?.name === 'SUP_SELLER') {
    companyUser = 1
    isEditable = true
  } else if (roleUser?.name === 'SUP_MOTORIZED') {
    companyUser = 1
  } else if (roleUser?.name === 'MASTER') {
    companyUser = 3
  }

  return { companyUser, isEditable }
}

export const useOrderDeliveredEdit = () => {
  const { roleUser } = useAuthStore()
  let companyUser = 0

  if (roleUser?.name === 'ADMIN_STORE' || roleUser?.name === 'SELLER') {
    companyUser = 2
  } else if (roleUser?.name === 'ADMIN' || roleUser?.name === 'SUP_SELLER' ||
    roleUser?.name === 'SUP_MOTORIZED'
  ) {
    companyUser = 1
  } else if (roleUser?.name === 'MASTER') {
    companyUser = 3
  }

  return { companyUser }
}

export const useOrderDeliveryEdit = () => {
  const { roleUser } = useAuthStore()

  const disabledEdit = roleUser?.name === 'MOTORIZED'
    || roleUser?.name === 'LIQUIDATOR'

  return { disabledEdit }
}

export const useProductDeliveryEdit = () => {
  const { roleUser } = useAuthStore()

  const disabledEdit = roleUser?.name === 'ADMIN'
    || roleUser?.name === 'ADMIN_STORE' || roleUser?.name === 'SELLER'
    || roleUser?.name === 'SUP_SELLER'

  return { disabledEdit }
}
