import { useAxios } from '../../hooks'
import departments from '../../config/data/departments.json'
import provinces from '../../config/data/provinces.json'
import districts from '../../config/data/districts.json'
import { ILocation, ILocationGeo, IQueryLocationGeo } from './location.interface'
import { useAuthStore } from '../auth/auth.service'

const mapToProvinceList = (): ILocation[] => {
  const provincesTemp: { [key: string]: ILocation[] } = provinces
  const provinceList: ILocation[] = []

  for (const key in provincesTemp) {
    if (Object.prototype.hasOwnProperty.call(provincesTemp, key)) {
      const element = provincesTemp[key]

      provinceList.push(...element)
    }
  }

  return provinceList
}

const filterDistrict = (ubigeoId: string): ILocation[] => {
  const districtsTemp: { [key: string]: ILocation[] } = districts

  return districtsTemp[ubigeoId]
}

export const getProvinceList = () => {
  return mapToProvinceList()
}

export const getDeparmentList = () => {
  const { authUser } = useAuthStore()
  const parentId = authUser.user.company.countryCode === 'PER' ? '2533' : '2534'
  
  return departments.filter(d => d.is_available === '1' && d.id_padre_ubigeo === parentId)
}

export const getProvincesByDepartment = (ubigeoId: string) => {
  const provincesTemp: { [key: string]: ILocation[] } = provinces

  return provincesTemp[ubigeoId]
}

export const getDistrictsByProvince = (ubigeoId: string) => {
  return filterDistrict(ubigeoId)
}

export const getLocations = (provinceId: string, districtId: string) => {
  const province = getProvinceList().find(p => p.id_ubigeo === provinceId) as ILocation
  const department = departments.find(d => d.id_ubigeo === province?.id_padre_ubigeo) as ILocation
  const district = getDistrictsByProvince(provinceId).find(p => p.id_ubigeo === districtId) as ILocation

  return {
    department,
    province,
    district,
  }
}

export const useLocationLevel1 = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingGeo1 } = useAxios<ILocationGeo[]>()

  const getLocationsGeoLevel1 = () => {
    execute({
      method: 'GET',
      url: '/ubigeo',
      params: {
        countryCode: authUser.user.company.countryCode,
        nivel: 1,
        parentId: ''
      },
    })
  }

  return {
    getLocationsGeoLevel1,
    loadingGeo1,
    locationsGeolevel1: response ? response : []
  }
}

export const useLocationLevel2 = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingGeo2 } = useAxios<ILocationGeo[]>()

  const getLocationsGeoLevel2 = (filter: IQueryLocationGeo) => {
    execute({
      method: 'GET',
      url: '/ubigeo',
      params: {
        countryCode: authUser.user.company.countryCode,
        nivel: 2,
        parentId: filter.parentId,
      },
    })
  }

  return {
    getLocationsGeoLevel2,
    loadingGeo2,
    locationsGeolevel2: response ? response : []
  }
}

export const useLocationLevel3 = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingGeo3 } = useAxios<ILocationGeo[]>()

  const getLocationsGeoLevel3 = (filter: IQueryLocationGeo) => {
    execute({
      method: 'GET',
      url: '/ubigeo',
      params: {
        countryCode: authUser.user.company.countryCode,
        nivel: 3,
        parentId: filter.parentId,
      },
    })
  }

  return {
    getLocationsGeoLevel3,
    loadingGeo3,
    locationsGeolevel3: response ? response : []
  }
}
