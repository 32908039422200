import { useEffect, useState } from 'react'
import { useFirebase } from '../../hooks'
import { objectToArray } from '../../utils/object-to-array'
import { IChatSupport } from './chat-order.interface'
import { IChatMessageSchema } from './chat-order.schema'
import moment from 'moment'

export const useChatMessages = () => {
  const { getFromDatabase, response, loading, updateFromDatabase } = useFirebase()
  const [messageAll, setMessageAll] = useState<IChatSupport[]>([])
  
  useEffect(() => {
    const listAll: IChatSupport[] = objectToArray<IChatSupport>(response)

    setMessageAll(listAll)
  }, [response])
  
  const getChatMessage = (orderNumber: string) => {
    getFromDatabase(`chat_support/${orderNumber}`)
  }

  const updateChatMessage = (orderNumber: string, data: IChatMessageSchema) => {
    data.orderNumber = orderNumber
    data.currentMillis = `${Date.now()}`
    data.active = true
    data.createdAt = moment().utcOffset(-5).format('yyyy-MM-DD HH:mm:ss')
    updateFromDatabase(`chat_support/${orderNumber}/${data.currentMillis}`, data)
  }
  
  return {
    updateChatMessage,
    getChatMessage,
    messageAll,
    loading,
  }
}
