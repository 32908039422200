import React, {} from 'react'
import CustomerPhoneForm from '../../forms/CustomerPhoneForm/CustomerPhoneForm'
import { useOrderStore } from '../../../../../services/order-list/order.store'

const CustomerPhoneContainer = () => {

  const { orderSelected } = useOrderStore()

  return (
    <>
      <CustomerPhoneForm phone={orderSelected?.customerHistory?.phone}></CustomerPhoneForm>
    </>
  )
}

export default CustomerPhoneContainer
