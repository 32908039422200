import { IAuthResponse } from '../auth/auth.interface'
import { ICompanyItemResponse } from '../company-list/company-list.interface'
import { IProductCreateRequest, IWarehouseCreateRequest } from './product-create.interface'
import { IProductCreateSchema } from './product-create.schema'

export const mapToProductCreateRequest = (
  productCreateSchema: IProductCreateSchema,
  authUser: IAuthResponse,
  companyGlobal: ICompanyItemResponse | null,
): IProductCreateRequest => {
  return {
    id: productCreateSchema.id,
    name: productCreateSchema.name,
    shortDescription: productCreateSchema.shortDescription || '',
    largeDescription: productCreateSchema.largeDescription || '',
    urlImage: productCreateSchema.urlImage || '',
    isActive: productCreateSchema.isActive,
    isProductGlobal: productCreateSchema.isProductGlobal,
    isNovelty: productCreateSchema.isNovelty,
    isLargeVolume: productCreateSchema.isLargeVolume,
    categoryId: productCreateSchema.categoryId,
    catalogId: productCreateSchema.catalogId,
    salePriceDrop: parseFloat(String(productCreateSchema.salePriceDrop | 0)),
    urlReference: productCreateSchema.urlReference,
    retailPriceSuggested: parseFloat(String(productCreateSchema.retailPriceSuggested | 0)),
    priceDropCrate: parseFloat(String(productCreateSchema.priceDropCrate | 0)),
    priceDropDozen: parseFloat(String(productCreateSchema.priceDropDozen | 0)),
    unitsCrate: parseInt(String(productCreateSchema.unitsCrate | 0)),
    countryCode: authUser.user.company.countryCode,
    skus: productCreateSchema.skus?.filter(sku => sku.check)
      .map(sku => ({
        companyId: companyGlobal?.id || authUser.user.company.id,
        sku: sku.sku,
        currency: companyGlobal?.setting?.currency || authUser.user.company?.setting?.currency,
        purchasePrice: sku.purchasePrice,
        regularPrice: sku.regularPrice,
        salesPrice: 0,
        stock: sku.stock,
        stockVirtual: sku.stock,
        skuOptions: sku.skuOptions,
        height: parseFloat(String(sku.heightProd)),
        width: parseFloat(String(sku.widthProd)),
        length: parseFloat(String(sku.lengthProd)),
        volume: parseFloat(String(sku.heightProd * sku.widthProd * sku.lengthProd)),
        warehouseSkus: [
          {
            stock: sku.stock,
            stockVirtual: sku.stock,
            stockStore: sku.stock,
            warehouseId: productCreateSchema.warehouseId,
            companyId: companyGlobal?.id || authUser.user.company.id
          }
        ] as IWarehouseCreateRequest[],
      })),
    variants: productCreateSchema.variantsMap,
  }
}
