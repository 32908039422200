import React, { FC, ReactNode } from 'react'
import FloatingButton from '../FloatingButton/FloatingButton'
interface IPageHeaderProps {
  children?: ReactNode
}

const PageHeader: FC<IPageHeaderProps> = ({ children }) => {

  return (
    <>
      <div
        style={{
          padding: '.85rem 1.5rem', display: 'flex',
          gap: '1rem', alignItems: 'center',
        }}
      >
        {/*<Title level={5} style={{ marginBottom: '0' }}>{title}</Title>*/}
        <div style={{ width: '100%' }}>
          {children}
        </div>
        <FloatingButton />
      </div>
    </>
  )
}

export default PageHeader
