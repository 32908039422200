import { IPaymentCreateSchema } from './payment-create.schema'
import { IPaymentCreateRequest } from './payment-create.interface'
import { IAuthResponse } from '../auth/auth.interface'

const mapToPaymentCreateRequest = (
  payment: IPaymentCreateSchema,
  authUser: IAuthResponse,
): IPaymentCreateRequest => {

  return {
    createdBy: authUser.user.id,
    amount: parseFloat(String(payment.amount || 0)),
    orderId: payment.orderId,
    entity: payment.entity,
    paymentDate: payment.paymentDate,
    paymentMethod: payment.paymentMethod,
    paymentDocument: payment.paymentDocument,
    paymentType: payment.paymentType,
    isPayMain: payment.isPayMain,
  }
}

export {
  mapToPaymentCreateRequest
}
