import React, { useEffect } from 'react'
import { Button, Divider, Spin, Typography } from 'antd'
import { downloadExcel } from 'react-export-table-to-excel'
import { useProductsByCountry } from '../../../../services/product-report/product-report.service'

const { Title } = Typography

const ProductReport = () => {
  const { getProductsByCountry, products, loading } = useProductsByCountry()
  
  const headerOrder = [
    'PRODUCTO', 'VARIANTE', 'PRECIO DROP', 'TIENDA', 'CÓDIGO TIENDA', 'PRECIO VENTA'
  ]

  function handleDownloadExcelOrders() {

    getProductsByCountry()

  }

  useEffect(() => {
    if (products && products.length > 0) {
      downloadExcel({
        fileName: 'product-report',
        sheet: 'react-export-table-to-excel',
        tablePayload: {
          header: headerOrder,
          // accept two different data structures
          body: products.map(o => {
            return { 
              product: o.product,
              variant: o.variant,
              salePriceDrop: o.salePriceDrop,
              companyName: o.companyName,
              companyCode: o.companyCode,
              salePrice: o.salePrice,
            }
          })
        }
      })
    }
  }, [products])
  
  return <>
    <Spin spinning={loading}>

      <Divider />

      <div
        style={{
          padding: '.85rem 1.5rem', display: 'flex',
          gap: '1rem', alignItems: 'center',
        }}
      >

        <Title level={5}>
          Reporte de productos
        </Title>

        <Button type='primary' onClick={handleDownloadExcelOrders} loading={loading}>
            Descargar producto
        </Button>

      </div>

    </Spin>

  </>
}

export default ProductReport
