import React, { FC, useEffect, useState } from 'react'
import { Button, Form, Input, Space, Typography, Tag } from 'antd'
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store'
import {
  DISPATCH_STATUS_LIST
} from '../../../../../services/dispatch-guide-create/dispatch.params'
import {
  ISummaryProductCreateSchema
} from '../../../../../services/summary-products/summary-products.schema'

const { Text } = Typography

interface IAddOrderFormProps {
  onSubmitAddOrder: (data: ISummaryProductCreateSchema) => void
}

const AddOrderForm: FC<IAddOrderFormProps> = ({ onSubmitAddOrder }) => {
  const [form] = Form.useForm<ISummaryProductCreateSchema>()
  const { dispatchGuideSelected } = useDispatchGuideStore()
  const [ orderAdd, setOrderAdd ] = useState<boolean>(false)

  useEffect(() => {
    if (dispatchGuideSelected) {
      form.setFieldsValue({
        products: undefined,
        orderNumber: undefined,
        dispatchGuideId: dispatchGuideSelected.id
      })

      if (dispatchGuideSelected.dispatchStatus === DISPATCH_STATUS_LIST.REGISTERED) {
        setOrderAdd(true)
      } else if (dispatchGuideSelected.dispatchStatus === DISPATCH_STATUS_LIST.IN_AGENCY) {
        setOrderAdd(false)
      } else {
        setOrderAdd(false)
      }

    }
  }, [dispatchGuideSelected])

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='DispatchGuideForm'
        labelWrap
        onFinish={onSubmitAddOrder}
      >

        <Form.Item name="dispatchGuideId" hidden={true}>
          <Input />
        </Form.Item>
        
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

          <Text>Pedidos agregados: {dispatchGuideSelected?.ordersDispatched && dispatchGuideSelected.ordersDispatched ? 
            dispatchGuideSelected.ordersDispatched.map(o => {
              if (o.isNew) return (<><Tag color='#03A9F4'>{o.orderNumber}</Tag></>)

              if (o.isRescheduled) return (<><Tag color='#ff9800'>{o.orderNumber}</Tag></>)

              return (<><Tag>{o.orderNumber}</Tag></>)
            }) : '-'
          }</Text>

          <Form.Item name="orderNumber" label={'Agregar Pedido'} hidden={orderAdd}>
            <Input />
          </Form.Item>

        </Space>

        <Button type='primary' htmlType='submit' hidden={orderAdd}>
          Guardar
        </Button>

      </Form>
    </>
  )
}

export default AddOrderForm
