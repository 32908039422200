/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './catalog.actions'
import { ICatalogPrivate } from '../../services/catalog/catalog.interface'

export interface ICatalogState {
  catalogSelected: ICatalogPrivate | null;
  catalogUpdated: ICatalogPrivate | null;
}

const initialState: ICatalogState = {
  catalogSelected: null,
  catalogUpdated: null,
}

export const catalogReducer = (
  state: ICatalogState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_CATALOG_SELECTED:
    return {
      ...state,
      catalogSelected: action.payload,
    }
  case actionTypes.SET_CATALOG_UPDATED:
    return {
      ...state,
      catalogUpdated: action.payload,
    }
  default:
    return state
  }
}
