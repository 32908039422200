import React from 'react'
import { TicketSupportsContainer } from './containers'

const TicketSupportView = () => {
  return (
    <>
      <TicketSupportsContainer/>
    </>
  )
}

export default TicketSupportView
