import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IProductGlobalResponse } from './product-global.interface'

const useProductBestSellers = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IProductGlobalResponse[]>()
  const getProductsBestSellers = (warehouseId: string, catalogId: string) => {
    const params = {
      countryCode: authUser.user.company.countryCode,
      warehouseId: warehouseId,
      parentId: authUser.user.company.parentId,
      catalogId: catalogId === '0' ? '' : catalogId
    }
      
    execute({
      method: 'GET',
      url: '/product/best-sellers',
      params: params,
    })
  }
  
  return {
    getProductsBestSellers,
    loadingBest: loading,
    bestSellers: response ? response : [],
  }
  
}

const useProductsGlobal = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IProductGlobalResponse[]>()
  
  const getProductsGlobal = (
    value: string, query: { 
      priceMax: number, priceMin: number, orderBy: string, categoryId: string, warehouse: string, catalogId: string
    }
  ) => {
    const params = {
      countryCode: authUser.user.company.countryCode,
      search: value,
      order: query.orderBy,
      priceMin: query.priceMin,
      priceMax: query.priceMax,
      categoryId: query.categoryId === '0' ? '' : query.categoryId,
      warehouse: query.warehouse === 'ALL' ? '' : query.warehouse,
      parentId: authUser.user.company.parentId,
      catalogId: query.catalogId === '0' ? '' : query.catalogId
    }
      
    execute({
      method: 'GET',
      url: '/product/global',
      params: params,
    })
  }
  
  return {
    getProductsGlobal,
    loading,
    products: response ? response : [],
  }
}

export {
  useProductsGlobal,
  useProductBestSellers
}
