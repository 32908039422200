import React, { FC, useEffect } from 'react'
import { Button, Divider, Form, Input, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import {
  IMovementCreateSchema,
} from '../../../../../services/movement-create/movement.schema'
import { useMovementStore } from '../../../../../services/movement-create/movement.store'
import { useWarehouseList } from '../../../../../services/warehouse-list/warehouse-list.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useOperationList } from '../../../../../services/operation-list/operation-list.service'
import { useSkuList } from '../../../../../services/sku-list/sku-list.service'
import { useWarehouseSkuAllList } from '../../../../../services/warehouse-sku-all/warehouse-sku-all.service'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { IWarehouseResponse } from '../../../../../services/warehouse-list/warehouse-list.interface'
import { IWarehouseSkuAllResponse } from '../../../../../services/warehouse-sku-all/warehouse-sku-all.interface'
import { useUserMasterAdmin } from '../../../../../services/order-create/order.edit'

const { Option } = Select

interface IMovementEditFormProps {
  onSubmit: (data: IMovementCreateSchema) => void
}

const MovementEditForm: FC<IMovementEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<IMovementCreateSchema>()
  const { movementSelected } = useMovementStore()
  const { getWarehouses, loading, warehouses } = useWarehouseList()
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()
  const { getOperations, operations } = useOperationList()
  const { getSkus, skus } = useSkuList()
  const operation = Form.useWatch('operation', form)
  const skuId = Form.useWatch('skuId', form)
  const warehouseId = Form.useWatch('warehouseId', form)
  const { getWarehouseSkuAll, loading: loadingWhSkus, warehouseSkus } = useWarehouseSkuAllList()
  const { rolUserMaster } = useUserMasterAdmin()

  useEffect(() => {
    getSkus()
    getOperations(true)
  }, [movementSelected])

  useEffect(() => {
    if (operation === 'TRANSFER') {
      getWarehouses('-1')
    } else {
      if (rolUserMaster) {
        getWarehouses('-1')
      } else {
        getWarehouses(companyGlobal ? `${companyGlobal.id}` : `${authUser.user.company.id}`)
      }
    }
  }, [operation])

  useEffect(() => {
    if (skuId) getWarehouseSkuAll(skuId.toString())
  }, [skuId])
  

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='UserForm'
        labelWrap
        onFinish={onSubmit}
      >
        {!movementSelected ? (
          <Form.Item
            label='Producto'
            name={['skuId']}
            rules={[{ required: true, message: 'Obligatorio' }]}
          >
            <Select placeholder="Seleccione una opción" loading={loading}
              showSearch
              optionFilterProp="label"
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {skus?.map(r => (
                <Option key={r.id} value={r.id} label={r.product.name}>
                  {r.product.name} - {r.skuOptions.length > 0 ? r.skuOptions.map(o => o.option?.name).join('/') : ''}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : null }

        {!movementSelected ? (

          <Form.Item
            label='Tipo de operación'
            name={['operation']}
            rules={[{ required: true, message: 'Obligatorio' }]}
          >
            <Select placeholder="Seleccione una opción" loading={loading}>
              {operations?.filter(o => o.isMovement).map(r => (
                <Option key={r.id} value={r.code}>
                  {r.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : null }

        { (!movementSelected && operation === 'TRANSFER') ? (
          <>
            <Form.Item
              label='Almacén Origen'
              name={['warehouseId']}
              rules={[{ required: true, message: 'Obligatorio' }]}
            >
              <Select placeholder="Seleccione una opción" loading={loadingWhSkus}
              >
                {warehouseSkus?.map((r: IWarehouseSkuAllResponse) => (
                  <Option key={r.warehouse.id} value={r.warehouse.id}>
                    {r.warehouse.name} / Stock: {r.stock} - StockV: {r.stockVirtual}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='Almacén Destino'
              name={['warehouseDestinationId']}
              rules={[{ required: true, message: 'Obligatorio' }]}
              hidden={!warehouseId}
            >
              <Select placeholder="Seleccione una opción" loading={loading}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  (option?.label as string).toLowerCase().includes(input.toLowerCase())
                }
              >
                {warehouses?.map((r: IWarehouseResponse) => (
                  warehouseId !== r.id && 
                  <Option key={r.id} value={r.id} label={r.name}>
                    {r.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : !movementSelected ? (
          <Form.Item
            label='Almacén'
            name={['warehouseId']}
            rules={[{ required: true, message: 'Obligatorio' }]}
          >
            <Select placeholder="Seleccione una opción" loading={loading}
              showSearch
              optionFilterProp="label"
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {warehouses?.map((r: IWarehouseResponse) => (
                <Option key={r.id} value={r.id} label={r.name}>
                  {r.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : null
        }

        <Form.Item
          label="Cantidad"
          name={['quantity']}
          rules={[{ required: true, message: 'Obligatorio' }]}>
          <Input></Input>
        </Form.Item>

        <Form.Item label="Comentario" name="comment">
          <TextArea rows={1}/>
        </Form.Item>

        <Divider />

        <Button type='primary' htmlType='submit'>
          Guardar
        </Button>
      </Form>
    </>
  )
}

export default MovementEditForm
