import { useAxios } from '../../hooks'
import { ICompanyHasOrder } from './company-order.interface'

export const useCompanyHasOrder = () => {
  const { execute, response, loading } = useAxios<ICompanyHasOrder>()

  const getCompanyHasOrder = (companyId: number) => {
    execute({
      method: 'GET',
      url: `/order/hasOrderDelivery/${companyId}`,
    })
  }

  return {
    getCompanyHasOrder,
    loading,
    hasOrder: response
  }
}
