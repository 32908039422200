import React, { FC, useEffect, useState } from 'react'
import {
  Button, Divider,
  Form, Input, Select, Badge, Tag,
  Table, Upload, message, InputNumber, Image, DatePicker,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import TextArea from 'antd/lib/input/TextArea'
import {
  useLocationLevel1,
  useLocationLevel2,
} from '../../../../../services/location/location.service'
import { useMotoriedList } from '../../../../../services/user-motorized/user-motorized.service'
import { UploadOutlined } from '@ant-design/icons'
import { storage } from '../../../../../libs/firebase'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'
import {
  ICreateReturnSchema,
} from '../../../../../services/return-create/return-create.schema'
import { useReturnStore } from '../../../../../services/return-create/return.store'
import { RETURN_STATUS_LABEL, RETURN_STATUS_LIST } from '../../../../../services/return-create/return.params'
import { useSummaryProdOrderReturn } from '../../../../../services/order-return/order-return.service'

const { Option } = Select

interface IReturnEditFormProps {
  onSubmit: (data: ICreateReturnSchema) => void
}

const ReturnEditForm: FC<IReturnEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<ICreateReturnSchema>()
  const { returnSelected } = useReturnStore()
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } = useLocationLevel1()
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } = useLocationLevel2()
  const { getSummaryProdOrderReturn, summaryProducts } = useSummaryProdOrderReturn()
  const motoOriginId = Form.useWatch(['motorizedSenderId'], form)
  const motoDestinyId = Form.useWatch(['motorizedRecipientId'], form)
  //const departmentOriginName = Form.useWatch(['departmentOriginName'], form)
  const departmentOriginId = Form.useWatch(['departmentOriginCode'], form)
  const provinceOriginId = Form.useWatch(['provinceOriginCode'], form)
  const departmentId = Form.useWatch(['departmentCode'], form)
  const provinceId = Form.useWatch(['provinceCode'], form)
  const status = Form.useWatch(['returnStatus'], form)
  const { getMotorizeds, loadingMt, motorizeds } = useMotoriedList()
  const [ disabled, setDisabled ] = useState(false)
  const [ updateStatus, setUpdateStatus ] = useState(false)
  const [ registered, setRegistered ] = useState(false)
  const [ saved, setSaved ] = useState(false)
  const urlVoucher = Form.useWatch(['urlVoucher'], form)
  const photoMerchandiseOrigin = Form.useWatch(['photoMerchandiseOrigin'], form)
  const photoMerchandiseDestination = Form.useWatch(['photoMerchandiseDestination'], form)
  const photoReturnOrigin = Form.useWatch(['photoReturnOrigin'], form)
  const photoReturnDestination = Form.useWatch(['photoReturnDestination'], form)
  const [percent, setPercent] = useState(0)
  const [file, setFile] = useState<any>()
  const [url, setUrl] = useState('')
  const [percentGuide, setPercentGuide] = useState(0)
  const [fileGuide, setFileGuide] = useState<any>()
  const [urlGuide, setUrlGuide] = useState('')
  const [percentMerchandise, setPercentMerchandise] = useState(0)
  const [fileMerchandise, setFileMerchandise] = useState<any>()
  const [urlMerchandise, setUrlMerchandise] = useState('')
  const [percentMerchandiseD, setPercentMerchandiseD] = useState(0)
  const [fileMerchandiseD, setFileMerchandiseD] = useState<any>()
  const [urlMerchandiseD, setUrlMerchandiseD] = useState('')
  const [percentDest, setPercentDest] = useState(0)
  const [fileDest, setFileDest] = useState<any>()
  const [urlMerchandiseDest, setUrlMerchandiseDest] = useState('')

  useEffect(() => {
    if (url.length > 0) {
      form.setFieldValue(['urlVoucher'], url)
    }
  }, [url])

  useEffect(() => {
    if (urlGuide.length > 0) {
      form.setFieldValue(['photoReturnOrigin'], urlGuide)
    }
  }, [urlGuide])

  useEffect(() => {
    if (urlMerchandise.length > 0) {
      form.setFieldValue(['photoMerchandiseOrigin'], urlMerchandise)
    }
  }, [urlMerchandise])

  useEffect(() => {
    if (urlMerchandiseDest.length > 0) {
      form.setFieldValue(['photoReturnDestination'], urlMerchandiseDest)
    }
  }, [urlMerchandiseDest])

  useEffect(() => {
    if (urlMerchandiseD.length > 0) {
      form.setFieldValue(['photoMerchandiseDestination'], urlMerchandiseD)
    }
  }, [urlMerchandiseD])

  function handleBeforeUpload(file: any) {
    setFile(file)
    if (!file) {
      message.success('Debe seleccionar un archivo')

      return
    }

    const storageRef = ref(storage, `/files/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercent(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url)
          //console.log('url image', url)
        })
      }
    )

    return false
  }

  function handleBeforeUploadMerchandiseOrigin(file: any) {
    setFileMerchandise(file)
    if (!file) {
      message.success('Debe seleccionar una imagen')

      return
    }

    const storageRef = ref(storage, `/files/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercentMerchandise(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlMerchandise(url)
        })
      }
    )

    return false
  }

  function handleBeforeUploadGuide(fileGuide: any) {
    setFileGuide(fileGuide)
    if (!fileGuide) {
      message.success('Debe seleccionar un archivo')

      return
    }

    const storageRef = ref(storage, `/files/${fileGuide?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, fileGuide)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercentGuide(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlGuide(url)
          //console.log('url image', url)
        })
      }
    )

    return false
  }

  function handleBeforeUploadMerchandiseDestination(file: any) {
    setFileMerchandiseD(file)
    if (!file) {
      message.success('Debe seleccionar una imagen')

      return
    }

    const storageRef = ref(storage, `/files/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercentMerchandiseD(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlMerchandiseD(url)
        })
      }
    )

    return false
  }

  function handleBeforeUploadGuideDest(fileGuide: any) {
    setFileDest(fileGuide)
    if (!fileGuide) {
      message.success('Debe seleccionar un image')

      return
    }

    const storageRef = ref(storage, `/files/${fileGuide?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, fileGuide)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercentDest(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlMerchandiseDest(url)
          //console.log('url image', url)
        })
      }
    )

    return false
  }

  const handleChangeDepartment = (ubigeoId: number) => {
    getLocationsGeoLevel2({
      parentId: ubigeoId
    })
  }

  const handleChangeDepartmentDestination = (ubigeoId: number) => {
    getLocationsGeoLevel2({
      parentId: ubigeoId
    })
  }

  useEffect(() => {
    const dptos = locationsGeolevel1 ? locationsGeolevel1.filter(f => f.flagContraentrega) : []

    if (departmentOriginId && dptos.length > 0) {
      const dpto = dptos.find(d => `${d.id}` === `${departmentOriginId}`)

      if (dpto) {
        form.setFieldValue(['departmentOriginName'], dpto?.name)
        handleChangeDepartment(dpto.id)
      }
    }
  }, [departmentOriginId, locationsGeolevel1])

  useEffect(() => {
    const provinces = locationsGeolevel2 ? locationsGeolevel2.filter(f => f.flagContraentrega) : []

    if (provinceOriginId && provinces.length > 0) {
      const prov = provinces.find(d => `${d.id}` === `${provinceOriginId}`)

      if (prov) {
        form.setFieldValue(['provinceOriginName'], prov.name)
      }
    }
  }, [provinceOriginId, locationsGeolevel2])

  useEffect(() => {
    const dptos = locationsGeolevel1 ? locationsGeolevel1.filter(f => f.flagContraentrega) : []

    if (departmentId && dptos.length > 0) {
      const dpto = dptos.find(d => `${d.id}` === `${departmentId}`)

      if (dpto) {
        form.setFieldValue(['departmentName'], dpto?.name)
        handleChangeDepartment(dpto.id)
      }
    }
  }, [departmentId, locationsGeolevel1])

  useEffect(() => {
    const provinces = locationsGeolevel2 ? locationsGeolevel2.filter(f => f.flagContraentrega) : []

    if (provinceId && provinces.length > 0) {
      const prov = provinces.find(d => `${d.id}` === `${provinceId}`)

      if (prov) {
        form.setFieldValue(['provinceName'], prov.name)
      }
    }
  }, [provinceId, locationsGeolevel2])

  useEffect(() => {
    getLocationsGeoLevel1()
    getMotorizeds()
  }, [])

  useEffect(() => {
    if (motoOriginId) {
      const moto = motorizeds.find(m => m.id === motoOriginId)

      form.setFieldValue(['motorizedSender'], {
        id: moto?.id,
        name: moto?.email
      })

      if (moto) {
        getSummaryProdOrderReturn(moto?.email)
      }

    }
  }, [motoOriginId, motorizeds])

  useEffect(() => {
    if (summaryProducts && summaryProducts.length > 0) {
      form.setFieldValue(['products'], summaryProducts)
      const ordersId = summaryProducts.map(o => {
        return {
          id: o.id,
          orderNumber: o.orderNumber,
        }
      })
      const ordersUnique = ordersId.filter((v, i, array) => {
        return array.indexOf(v) === i
      })

      form.setFieldValue(['ordersId'], ordersUnique)
    }
  }, [summaryProducts])

  useEffect(() => {
    if (motoDestinyId) {
      const moto = motorizeds.find(m => m.id === motoDestinyId)

      form.setFieldValue(['motorizedRecipient'], {
        id: moto?.id,
        name: moto?.email
      })
    }
  }, [motoDestinyId, motorizeds])

  useEffect(() => {
    if (returnSelected) {

      if (returnSelected.returnStatus === RETURN_STATUS_LIST.DELIVERED) {
        setDisabled(true)
        setUpdateStatus(true)
        setRegistered(true)
        setSaved(false)
      } else if (returnSelected.returnStatus === RETURN_STATUS_LIST.IN_AGENCY) {
        setDisabled(false)
        setUpdateStatus(true)
        setRegistered(true)
        setSaved(false)
      } else if (returnSelected.returnStatus === RETURN_STATUS_LIST.REGISTERED) {
        setDisabled(false)
        setUpdateStatus(true)
        setRegistered(true)
        setSaved(false)
      }

      form.setFieldsValue({
        id: returnSelected.id,
        shippingCost: returnSelected.shippingCost,
        returnNumber: returnSelected.returnNumber,
        departmentOriginCode: parseInt(returnSelected.departmentOriginCode),
        departmentOriginName: returnSelected.departmentOriginName,
        provinceOriginCode: parseInt(returnSelected.provinceOriginCode),
        provinceOriginName: returnSelected.provinceOriginName,
        departmentCode: parseInt(returnSelected.departmentCode),
        departmentName: returnSelected.departmentName,
        provinceCode: parseInt(returnSelected.provinceCode),
        provinceName: returnSelected.provinceName,
        motorizedRecipientId: returnSelected.motorizedRecipientId,
        motorizedSenderId: returnSelected.motorizedSenderId,
        returnStatus: returnSelected.returnStatus,
        guideCode: returnSelected.guideCode,
        ordersId: returnSelected.ordersReturned,
        comment: returnSelected.comment,
        urlVoucher: returnSelected.urlVoucher,
        commentDestination: returnSelected.commentDestination,
        photoMerchandiseDestination: returnSelected.photoMerchandiseDestination,
        photoMerchandiseOrigin: returnSelected.photoMerchandiseOrigin,
        photoReturnDestination: returnSelected.photoReturnDestination,
        photoReturnOrigin: returnSelected.photoReturnOrigin,
        originCost: returnSelected.originCost,
        destinationCost: returnSelected.destinationCost,
        returnDate: returnSelected.returnDate ? 
          moment(new Date(returnSelected.returnDate), 'DD/MM/YYYY') : '',
      })
    } else {
      
      form.setFieldValue(['ordersId'], [])
      form.setFieldValue(['products'], [])
      form.setFieldValue(['id'], null)
      form.setFieldValue(['returnStatus'], RETURN_STATUS_LIST.REGISTERED)
      setUpdateStatus(true)
    }
  }, [returnSelected])

  const columnsSummary: ColumnsType<any> = [
    {
      title: '#Order',
      dataIndex: 'orderNumber',
      align: 'center',
      render: (_, p) => <>
        {p.orderNumber}
      </>,
    },
    {
      title: 'EAN',
      dataIndex: 'ean',
      align: 'center',
      render: (_, p) => <>
        {p.ean}
      </>,
    },
    {
      title: 'Productos',
      dataIndex: 'productName',
      align: 'left',
      render: (_, p) => <>
        {p.productName} - {p.companyName}
      </>,
    },
    {
      title: 'Almacén',
      dataIndex: 'warehouseName',
      align: 'center',
      render: (_, p) => <>
        {p.warehouseName}
      </>,
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      align: 'center',
      render: (_, p) => <>
        {p.quantity}
      </>,
    },
  ]

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current < moment().startOf('day')
  }

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='ReturnForm'
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item name={['id']} hidden>
          <Input />
        </Form.Item>
        
        { returnSelected ? <>
          <Form.Item
            label='#Devolución'
            name={['returnNumber']}
            rules={[{ required: false, message: 'Obligatorio' }]}
          >
            <Input disabled/>
          </Form.Item>
        </> : null }

        <Form.Item name={['returnStatus']} label="Estado de devolución"
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select placeholder="Seleccione una opción" disabled={updateStatus}>
            { returnSelected ? <>
              { returnSelected.returnStatus === RETURN_STATUS_LIST.REGISTERED ? <>
                <Option
                  key={RETURN_STATUS_LIST.IN_AGENCY}
                  value={RETURN_STATUS_LIST.IN_AGENCY}
                >
                  <Badge style={{ marginRight: '.5rem' }} text="" />
                  {RETURN_STATUS_LABEL[RETURN_STATUS_LIST.IN_AGENCY].label}
                </Option>
              </> : returnSelected.returnStatus === RETURN_STATUS_LIST.IN_AGENCY ? <>
                <Option
                  key={RETURN_STATUS_LIST.DELIVERED}
                  value={RETURN_STATUS_LIST.DELIVERED}
                >
                  <Badge style={{ marginRight: '.5rem' }} text="" />
                  {RETURN_STATUS_LABEL[RETURN_STATUS_LIST.DELIVERED].label}
                </Option>
              </> : null}
            </> : <>
              {Object.keys(RETURN_STATUS_LIST).map(key => (
                <Option
                  key={key}
                  value={key}
                >
                  <Badge style={{ marginRight: '.5rem' }} text="" />
                  {RETURN_STATUS_LABEL[key].label}
                </Option>
              ))}
            </>}
          </Select>
        </Form.Item>

        <Form.Item
          label="Departamento Origen"
          name={['departmentOriginCode']}
          rules={[{ required: true, message: '' }]}>
          <Select
            onChange={handleChangeDepartment}
            disabled={registered}
            showSearch
            loading={loadingGeo1}
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
            options={locationsGeolevel1?.filter(f => f.flagContraentrega).map(e => ({
              label: e.name,
              value: e.id,
            }))}
          />
        </Form.Item>

        <Form.Item name={['departmentOriginName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Provincia Origen"
          name={['provinceOriginCode']}
          rules={[{ required: true, message: '' }]}>
          <Select
            disabled={registered}
            showSearch
            loading={loadingGeo2}
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
            options={locationsGeolevel2?.filter(f => f.flagContraentrega).map(e => ({
              label: e.name,
              value: e.id,
            }))}
          />
        </Form.Item>

        <Form.Item name={['provinceOriginName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label='Motorizado Remitente'
          name={['motorizedSenderId']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select placeholder="Seleccione una opción" loading={loadingMt} disabled={registered}
            showSearch optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
            options={motorizeds?.map(e => ({
              label: e.email,
              value: e.id,
            }))}
          />
        </Form.Item>

        <Form.Item name={['motorizedSender']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Departamento Destino"
          name={['departmentCode']}
          rules={[{ required: true, message: '' }]}>
          <Select
            onChange={handleChangeDepartmentDestination}
            disabled={registered}
            showSearch
            loading={loadingGeo1}
            optionFilterProp="label"
            options={locationsGeolevel1?.filter(f => f.flagContraentrega).map(e => ({
              label: e.name,
              value: e.id,
            }))}
          />
        </Form.Item>

        <Form.Item name={['departmentName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Provincia Destino"
          name={['provinceCode']}
          rules={[{ required: true, message: '' }]}>
          <Select
            disabled={registered}
            showSearch
            loading={loadingGeo2}
            optionFilterProp="label"
            options={locationsGeolevel2?.filter(f => f.flagContraentrega).map(e => ({
              label: e.name,
              value: e.id,
            }))}
          />
        </Form.Item> 

        <Form.Item name={['provinceName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label='Motorizado Destino'
          name={['motorizedRecipientId']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select placeholder="Seleccione una opción" loading={loadingMt} disabled={registered} showSearch 
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
            options={motorizeds?.map(e => ({
              label: e.email,
              value: e.id,
            }))}
          />
        </Form.Item>

        <Form.Item name={['motorizedRecipient']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['ordersId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['products']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label="Comentario" name={['comment']}>
          <TextArea rows={3} disabled={disabled}/>
        </Form.Item>

        { returnSelected && (status === RETURN_STATUS_LIST.IN_AGENCY || 
          status === RETURN_STATUS_LIST.DELIVERED) ? <>

            <Form.Item
              label='Número de guía'
              name={['guideCode']}
              rules={[{ required: false, message: '' }]}
            >
              <Input disabled={disabled}/>
            </Form.Item>

            <Form.Item
              label='Costo de guía'
              name={['shippingCost']}
              rules={[{ required: false, message: '' }]}
            >
              <InputNumber disabled={disabled}/>
            </Form.Item>

            <Form.Item name={['urlVoucher']}
              label="Documento guía">
              <Upload 
                name='file'
                beforeUpload={handleBeforeUpload}
                maxCount={1}
                disabled={disabled}
                fileList={[]}
              >
                <Button>
                  <UploadOutlined /> {file ? file.name : ''} {percent} %
                </Button>
              </Upload>
              { urlVoucher && urlVoucher.length > 0 ? <>
                <a 
                  href={urlVoucher} 
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >Ver voucher adjunto</a>
              </> : null }
            </Form.Item>

            <Form.Item name={['photoMerchandiseOrigin']}
              label="Foto mercadería inicial"
            >
              <Upload 
                name='file'
                beforeUpload={handleBeforeUploadMerchandiseOrigin}
                maxCount={1}
                disabled={disabled}
                fileList={[]}
              >
                <Button>
                  <UploadOutlined /> {fileMerchandise ? fileMerchandise.name : ''} {percentMerchandise} %
                </Button>
              </Upload>
              { photoMerchandiseOrigin && photoMerchandiseOrigin.length > 0 ? <>
                <Image
                  width={100}
                  src={photoMerchandiseOrigin}
                />
              </> : null }
            </Form.Item>

            <Form.Item label="Fecha de retorno"
              name={['returnDate']}
              rules={[{ required: true, message: 'Seleccionar una fecha de retorno' }]}
            >
              <DatePicker
                disabled={disabled}
                inputReadOnly={true}
                showToday={false}
                disabledDate={disabledDate}
                format={'DD/MM/YYYY'} style={{ width: '100%' }} />
            </Form.Item>

          </> : null }

        { returnSelected ? <>
        
          <Form.Item
            label='Costo de origen'
            name={['originCost']}
            rules={[{ required: false, message: '' }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label='Costo de destino'
            name={['destinationCost']}
            rules={[{ required: false, message: '' }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item name={['photoReturnOrigin']}
            label="Devolución origen"
          >
            <Upload 
              name='fileReturnOrigin'
              beforeUpload={handleBeforeUploadGuide}
              maxCount={1}
              disabled={disabled}
              fileList={[]}
            >
              <Button>
                <UploadOutlined /> {fileGuide ? fileGuide.name : ''} {percentGuide} %
              </Button>
            </Upload>
            { photoReturnOrigin && photoReturnOrigin.length > 0 ? <>
              <Image
                width={100}
                src={photoReturnOrigin}
              />
            </> : null }
          </Form.Item>

          <Form.Item name={['photoReturnDestination']}
            label="Devolución destino"
          >
            <Upload 
              name='fileReturnDestination'
              beforeUpload={handleBeforeUploadGuideDest}
              maxCount={1}
              disabled={disabled}
              fileList={[]}
            >
              <Button>
                <UploadOutlined /> {fileDest ? fileDest.name : ''} {percentDest} %
              </Button>
            </Upload>
            { photoReturnDestination && photoReturnDestination.length > 0 ? <>
              <Image
                width={100}
                src={photoReturnDestination}
              />
            </> : null }
          </Form.Item>

        </> : null }

        { status === RETURN_STATUS_LIST.DELIVERED ? <>

          <Form.Item name={['photoMerchandiseDestination']}
            label="Foto mercadería destino"
          >
            <Upload 
              name='file'
              beforeUpload={handleBeforeUploadMerchandiseDestination}
              maxCount={1}
              disabled={disabled}
              fileList={[]}
            >
              <Button>
                <UploadOutlined /> {fileMerchandiseD ? fileMerchandiseD.name : ''} {percentMerchandiseD} %
              </Button>
            </Upload>
            { photoMerchandiseDestination && photoMerchandiseDestination.length > 0 ? <>
              <Image
                width={100}
                src={photoMerchandiseDestination}
              />
            </> : null }
          </Form.Item>

          <Form.Item label="Comentario Destino" name={['commentDestination']}>
            <TextArea rows={3} disabled={disabled}/>
          </Form.Item>

        </> : null}

        { !returnSelected ? <>

          <Table
            rowKey="sku"
            columns={columnsSummary}
            dataSource={summaryProducts}
            pagination={false}
            size="small"
          />

        </> : null }

        { returnSelected && returnSelected.ordersReturned && returnSelected.ordersReturned.length > 0 ? <>

          <Form.Item label='Resumen de pedidos'>
            {returnSelected.ordersReturned.map(o => {
              return (<Tag key={o.id}>
                {o.orderNumber}
              </Tag>)
            })}
          </Form.Item>

          <Table
            rowKey="sku"
            columns={columnsSummary}
            dataSource={returnSelected.summaryReturnProduct}
            pagination={false}
            size="small"
          />
        
        </> : null}

        <Divider />

        <Button type='primary' htmlType='submit' hidden={saved}>
          Guardar
        </Button>
      </Form>
    </>
  )
}

export default ReturnEditForm
