/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IOrderUpdateResponse } from './../order-update/order-update.interface'
import { datadogLogs } from '../../hooks/use-datadog'
import { useEffect } from 'react'

export const useOrderStoreCentral = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IOrderUpdateResponse>()

  const createOrderStoreCentral = (orderId: string) => {

    datadogLogs.logger.info('ORDER_STORE_CENTRAL', { action: 'ORDER_STORE_CENTRAL', request: { orderId: orderId },
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
      orderId: orderId
    })

    execute({
      method: 'POST',
      url: `/order/resend/store-central/${orderId}`,
    })
  }

  useEffect(() => {
    if (response) {

      datadogLogs.logger.info('ORDER_STORE_CENTRAL', { 
        action: 'ORDER_STORE_CENTRAL',
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        orderResponse: response,
        orderId: response?.id
      })

    }
  }, [response])

  return {
    createOrderStoreCentral,
    orderStoreCentralUpdated: response,
    loadingOrder: loading,
  }
}
