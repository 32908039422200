export const PAYMENT_METHOD = {
  E: 'E',
  T: 'T',
  P: 'P',
}

export const PAYMENT_TYPE = {
  C: 'C',
  P: 'P',
  S: 'S'
}

export const ENTITY = {
  BCP: 'BCP',
  IBK: 'IBK',
  BBVA: 'BBVA',
  YAPE: 'YAPE',
  PLIN: 'PLIN',
  SCOT: 'SCOT'
}

export const ENTITY_LABEL: { [key: string]: { label: string, value: string } } = {
  BCP: { value: ENTITY.BCP, label: 'BCP' },
  IBK: { value: ENTITY.IBK, label: 'INTERBANK' },
  BBVA: { value: ENTITY.BBVA, label: 'BBVA' },
  YAPE: { value: ENTITY.YAPE, label: 'YAPE' },
  PLIN: { value: ENTITY.PLIN, label: 'PLIN' },
  SCOT: { value: ENTITY.SCOT, label: 'SCOTIABANK' }
}

export const PAYMENT_METHOD_LABEL: { [key: string]: { label: string, value: string } } = {
  E: { value: PAYMENT_METHOD.E, label: 'Efectivo' },
  T: { value: PAYMENT_METHOD.T, label: 'Transferencia' },
  P: { value: PAYMENT_METHOD.P, label: 'POS' },
}

export const PAYMENT_TYPE_LABEL: { [key: string]: { label: string, value: string } } = {
  C: { value: PAYMENT_TYPE.C, label: 'Completo' },
  P: { value: PAYMENT_TYPE.P, label: 'Parcial' },
  S: { value: PAYMENT_TYPE.S, label: 'Sin adelanto' }
}
