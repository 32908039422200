/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './pre-order.actions'
import { IPreOrderItemResponse } from '../../services/pre-order/pre-order.interface'

export interface IPreOrderState {
  preOrderSelected: IPreOrderItemResponse | null;
}

const initialState: IPreOrderState = {
  preOrderSelected: null,
}

export const preOrderReducer = (
  state: IPreOrderState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_PRE_ORDER_SELECTED:
    return {
      ...state,
      preOrderSelected: action.payload,
    }
  default:
    return state
  }
}
