import { useAxios } from '../../hooks'
import { ICategory } from './category.interface'

export const useCategories = () => {
  const { execute, response, loading: loadingCategory } = useAxios<ICategory[]>()

  const getCategories = () => {
    execute({
      method: 'GET',
      url: '/category',
    })
  }
  
  return {
    getCategories,
    loadingCategory,
    categories: response ? response : []
  }
}
