/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { IPaymentCreateSchema } from './payment-create.schema'
import { IPaymentCreateResponse } from './payment-create.interface'
import { mapToPaymentCreateRequest } from './payment-create.mapper'
import { useAuthStore } from '../auth/auth.service'
import { datadogLogs } from '../../hooks/use-datadog'
import { useEffect } from 'react'

export const usePaymentCreate = () => {
  const { authUser } = useAuthStore()
  const { execute, response: paymentCreated, loading } = useAxios<IPaymentCreateResponse>()

  const paymentCreate = (payment: IPaymentCreateSchema) => {
    
    const request = mapToPaymentCreateRequest(payment, authUser)

    datadogLogs.logger.info('PAY_CREATE', { action: 'PAY_CREATE', pay: request,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      }
    })

    execute({
      method: 'POST',
      url: '/payment',
      data: request,
    })
  }

  useEffect(() => {
    if (paymentCreated) {
      datadogLogs.logger.info('PAY_CREATE:POST', { action: 'PAY_CREATE:POST',
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        payResponse: paymentCreated,
      })
    }
  }, [paymentCreated])

  return {
    paymentCreate,
    paymentCreated,
    loading,
  }
}
