import { message } from 'antd'
import { useAxios } from '../../hooks'
import { IAuthUserResponse } from '../auth/auth.interface'
import { useAuthStore } from '../auth/auth.service'
import { useUserStore } from '../user/user.service'
import { ICoverageCreateRequest, ICoverageItemResponse } from './coverage-create.interface'
import { mapToCoverageCreateRequest } from './coverage-create.mapper'
import { ICoverageCreateSchema } from './coverage-create.schema'

export const useCoverageCreate = () => {
  const { userSelected } = useUserStore()
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ICoverageItemResponse>()

  const saveCoverage = (data: ICoverageCreateSchema) => {
    if (!authUser.user.company?.setting?.country?.code) {
      message.warning('La compañia no cuenta con la configuración de país.')
    }

    const dataRequest: ICoverageCreateRequest[] = mapToCoverageCreateRequest(
      authUser,
      data,
      userSelected as IAuthUserResponse,
    )

    execute({
      method: 'POST',
      url: '/maintenance/coverage',
      data: dataRequest,
    })
  }

  return {
    saveCoverage,
    loading,
    coverageUpdated: response
  }
}
