import { useEffect, useState } from 'react'

const measurepoints = {
  0: 'xs',
  325: 'xss',
  365: 'ss',
  380: 's',
  480: 'sm',
  600: 'm',
  960: 'md',
  1280: 'lg',
  1920: 'xl',
}
const useMeasureY = () => {
  const [measurepoint, setMeasurePoint] = useState('')
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    if (0 < windowSize.width && windowSize.width < 325) {
      setMeasurePoint(measurepoints[0])
    }
    if (325 < windowSize.width && windowSize.width < 365) {
      setMeasurePoint(measurepoints[325])
    }
    if (365 < windowSize.width && windowSize.width < 380) {
      setMeasurePoint(measurepoints[365])
    }
    if (380 < windowSize.width && windowSize.width < 480) {
      setMeasurePoint(measurepoints[380])
    }
    if (480 < windowSize.width && windowSize.width < 600) {
      setMeasurePoint(measurepoints[480])
    }
    if (600 < windowSize.width && windowSize.width < 960) {
      setMeasurePoint(measurepoints[600])
    }
    if (960 < windowSize.width && windowSize.width < 1280) {
      setMeasurePoint(measurepoints[960])
    }
    if (1280 < windowSize.width && windowSize.width < 1920) {
      setMeasurePoint(measurepoints[1280])
    }
    if (windowSize.width >= 1920) {
      setMeasurePoint(measurepoints[1920])
    }

    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize.width])

  return {
    measurepoint,
    windowSize,
  }
}

export default useMeasureY
