/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { ISignUpResponse } from './sign-up.interface'
import { mapToSignUpRequest } from './sign-up.mapper'
import { ISignUpSchema } from './sign-up.schema'

export const useSignUpCreate = () => {
  const { execute, response: signUpCreated, loading } = useAxios<ISignUpResponse>()

  const signUpCreate = (signInSchema: ISignUpSchema) => {
    
    const request = mapToSignUpRequest(
      signInSchema
    )

    execute({
      method: 'POST',
      url: '/auth/register',
      data: request,
    })
  }

  return {
    signUpCreate,
    signUpCreated,
    loading,
  }
}
