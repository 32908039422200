import React from 'react'
import { SmileOutlined } from '@ant-design/icons'
import { Result } from 'antd'

const DashboardWelcome = () => {
  return (
    <>

      <Result
        icon={<SmileOutlined />}
        title="Bienvenido a Aliclik"
      />

    </>
  )
}

export default DashboardWelcome
