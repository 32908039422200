import React, { FC } from 'react'
import { Button, Table, TableColumnsType, Tooltip } from 'antd'
import { IProductResponse, ISkuItemResponse } from '../../../../../../services/product/product.interface'
import WarehouseSkusTable from './WarehouseSkusTable'
import { useProductStore } from '../../../../../../services/product/product.store'
import { useDrawer } from '../../../../../../context/DrawerContext'
import { EditOutlined } from '@ant-design/icons'
import { useAuthStore } from '../../../../../../services/auth/auth.service'

interface IProductSkusTableProps {
  record: IProductResponse
}

const ProductSkusTable: FC<IProductSkusTableProps> = ({ record }) => {
  const { openDrawer } = useDrawer()
  const { setSkuSelected } = useProductStore()
  const { authUser } = useAuthStore()

  const handleSelected = (data: ISkuItemResponse) => {
    setSkuSelected(data)
    openDrawer('SKU_EDIT', 'Editar dimensiones')
  }

  const columnsLevel3: TableColumnsType<ISkuItemResponse> = [
    {
      title: 'Sku',
      dataIndex: 'sku',
      width: '15%', align: 'center',
    },
    {
      title: 'EAN',
      dataIndex: 'ean',
      width: '15%', align: 'center',
    },
    {
      title: 'Variantes',
      dataIndex: 'skuOptions',
      render: (_, sku) => <>
        {sku.skuOptions?.map(opt => (
          <div key={opt.id}>{opt.option?.variant?.name}: <b>{opt.option?.name}</b></div>
        ))}
      </>,
    },
    {
      title: '', dataIndex: 'id', align: 'center', width: '10%',
      render: (_, sku) => <>{
        authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' || 
        authUser.user.role.name === 'STORE' ? <>

            <Tooltip title="Editar sku">
              <Button 
                shape="circle" 
                icon={<EditOutlined />} 
                onClick={() => handleSelected(sku)}
              />
            </Tooltip>
        
          </> : <></>
      }
      </>
    }
  ]

  return <>
    <Table
      rowKey="id"
      columns={columnsLevel3}
      dataSource={record.skus}
      pagination={false}
      expandable={{
        expandedRowRender: (record) => <WarehouseSkusTable record={record.warehouseSkus} />,
        rowExpandable: (record) => !!record.warehouseSkus.length,
      }}
      size="small"
    />
  </>
}

export default ProductSkusTable
