/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './warehouse.actions'
import { IWarehouseResponse } from '../../services/warehouse-list/warehouse-list.interface'
import { IWarehouseCreateRequest } from '../../services/warehouse-create/warehouse-create.interface'

export interface IWarehouseState {
  warehouseSelected: IWarehouseResponse | null;
  warehouseUpdated: IWarehouseCreateRequest | null;
}

const initialState: IWarehouseState = {
  warehouseSelected: null,
  warehouseUpdated: null,
}

export const warehouseReducer = (
  state: IWarehouseState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_WAREHOUSE_SELECTED:
    return {
      ...state,
      warehouseSelected: action.payload,
    }
  case actionTypes.SET_WAREHOUSE_UPDATED:
    return {
      ...state,
      warehouseUpdated: action.payload,
    }
  default:
    return state
  }
}
