import { datadogLogs } from '@datadog/browser-logs'
import { useAxios } from '../../hooks'
import { IUpdateEvidenceResponse } from './evidence-update.interface'
import { IUpdateEvidenceSchema } from './evidence-update.schema'
import { useAuthStore } from '../auth/auth.service'
import { useEffect } from 'react'

export const useEvidenceUpdate = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IUpdateEvidenceResponse>()
  
  const updateEvidence = (data: IUpdateEvidenceSchema, orderId: number) => {
  
    datadogLogs.logger.info('EVIDENCE_ORDER_UPDATE', { action: 'EVIDENCE_ORDER_UPDATE',
      request: data,
      orderId: orderId,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      }
    })
    
    execute({
      method: 'PATCH',
      url: `/order/update/evidence/${orderId}`,
      data: data,
    })
      
  }
  
  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('EVIDENCE_ORDER_UPDATE:POST', { action: 'EVIDENCE_ORDER_UPDATE:POST',
        response: response,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
    }
  }, [response])
  
  return {
    updateEvidence,
    loading,
    evidenceUpdated: response
  }
}
