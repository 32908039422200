import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useDrawer } from '../../../../../context/DrawerContext'
import { AddVoucherForm } from '../../forms'
import { useLiquidationStore } from '../../../../../services/liquidation-edit/liquidation-edit.store'
import { IVoucherCreateSchema } from '../../../../../services/voucher-create/voucher-create.schema'
import { useVoucherCreate } from '../../../../../services/voucher-create/voucher-create.service'

const VoucherContainer = () => {
  const { voucherCreate, response, loading } = useVoucherCreate()
  const { closeDrawer } = useDrawer()
  const { setLiquidationUpdated } = useLiquidationStore()

  const handleSubmit = (data: IVoucherCreateSchema) => {
    voucherCreate(data)
  }

  useEffect(() => {
    if (response) {
      setLiquidationUpdated({ id: 1 })
      closeDrawer()
    }
  }, [response])

  return (
    <>
      <Spin spinning={loading}>
        <AddVoucherForm
          onSubmit={handleSubmit}
        />
      </Spin>
    </>
  )
}

export default VoucherContainer
