import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Drawer, Modal } from 'antd'
import { useDrawer } from '../../context/DrawerContext'
import { useNavigate } from 'react-router-dom'
import { PreOrderEditContainer } from '../../pages/OrderModule/MyPreOrdersView/containers'
import useBreakpoint from '../../hooks/use-breakpoint'
import { OrderEditContainer } from '../../pages/OrderModule/OrdersView/containers'
import { StoresEditContainer } from '../../pages/MaintenanceModule/StoresMaintView/containers'
import { 
  UserEditContainer,
  UserEditPasswordContainer,
  UserLogoutContainer } from '../../pages/MaintenanceModule/UsersMaintView/containers'
import { ProductEditContainer, SkuEditContainer } from '../../pages/MaintenanceModule/ProductsMaintView/containers'
import { MovementsEditContainer } from '../../pages/MaintenanceModule/MovementsMainView/containers'
import {
  CustomerPhoneContainer,
  OrderChatContainer,
  OrderDeliveryEditContainer,
  OrderEvidenceContainer
} from '../../pages/OrderModule/DeliveriesView/containers'
import { WarehouseEditContainer } from '../../pages/MaintenanceModule/WarehousesMainView/containers'
import { CoverageEditContainer } from '../../pages/RoutesModule/CoveragesView/containers'
import { DeliveriesContainer, DeliveryMotorizedContainer } from '../../pages/RoutesModule/RoutesView/containers'
import { useModal } from '../../context/ModalContext'
import { OrderDeliveryContainer, OrderPaymentContainer } from '../../pages/OrderModule/DeliveriesView/containers'
import { OrderAgencyEditContainer, OrderAgencyPayContainer } from '../../pages/OrderModule/OrderAgencyView/containers'
import { PassContainer, SignUpContainer } from '../../pages/SignIn/containers'
import {
  AddOrderContainer,
  DispatchGuideEditContainer,
  DispatchGuideSummaryContainer,
  SummaryProductAccumulatedContainer,
  SummaryProductEditContainer
} from '../../pages/DispatchModule/DispatchGuideMainView/containers'
import { AssignDistpachContainer } from '../../pages/DispatchModule/OrderAssignmentMainView/containers'
import { DepositEditContainer, VoucherContainer } from '../../pages/LiquidationModule/ValidationDepositView/containers'
import { ReturnEditContainer } from '../../pages/DispatchModule/ReturnMainView/containers'
import { 
  OrderShopifyAgencyContainer,
  OrderShopifyNewContainer
} from '../../pages/OrderModule/OrderShopifyView/containers'
import { TicketSupportEditContainer } from '../../pages/Support/TicketSupportView/containers'
import { CatalogEditContainer } from '../../pages/Catalog/ManageCatalogView/containers'

interface IWrapperProps {
  children: ReactNode
}

const Wrapper: FC<IWrapperProps> = ({ children }) => {
  const navigate = useNavigate()
  const { displayDrawer, closeDrawer, drawerView, drawerTitle } = useDrawer()
  const { displayModal, closeModal, modalView, modalTitle } = useModal()
  const breakpoint = useBreakpoint()
  const [widthDrawer, setWidthDrawer] = useState<string>('560px')

  useEffect(() => {
    if (breakpoint === 'xs') {
      setWidthDrawer('100%')
    } else {
      setWidthDrawer('560px')
    }

  }, [breakpoint])

  useEffect(() => {
    closeDrawer()
  }, [navigate])

  return (
    <>
      {children}

      <Drawer
        title={drawerTitle}
        placement="right"
        onClose={closeDrawer}
        open={displayDrawer}
        destroyOnClose
        contentWrapperStyle={{
          width: widthDrawer
        }}
      >
        {drawerView === 'CUSTOMER_PHONE_INFO' && <CustomerPhoneContainer />}
        {drawerView === 'PRE_ORDER_EDIT' && <PreOrderEditContainer />}
        {drawerView === 'ORDER_EDIT' && <OrderEditContainer />}
        {drawerView === 'COMPANY_EDIT' && <StoresEditContainer />}
        {drawerView === 'USER_EDIT' && <UserEditContainer />}
        {drawerView === 'PRODUCT_EDIT' && <ProductEditContainer />}
        {drawerView === 'SKU_EDIT' && <SkuEditContainer />}
        {drawerView === 'COVERAGE_EDIT' && <CoverageEditContainer />}
        {drawerView === 'DELIVERY_MOTORIZED_EDIT' && <DeliveryMotorizedContainer />}
        {drawerView === 'MOVEMENT_EDIT' && <MovementsEditContainer />}
        {drawerView === 'WAREHOUSE_EDIT' && <WarehouseEditContainer />}
        {drawerView === 'ORDER_DELIVERY_EDIT' && <OrderDeliveryEditContainer />}
        {drawerView === 'ORDER_AGENCY_EDIT' && <OrderAgencyEditContainer />}
        {drawerView === 'ORDER_SHOPIFY_AGENCY_EDIT' && <OrderShopifyAgencyContainer /> }
        {drawerView === 'REGISTER_USER' && <SignUpContainer />}
        {drawerView === 'DISPATCH_GUIDE_EDIT' && <DispatchGuideEditContainer />}
        {drawerView === 'RETURN_EDIT' && <ReturnEditContainer />}
        {drawerView === 'ASSIGN_DISPATCH_EDIT' && <AssignDistpachContainer />}
        {drawerView === 'USER_EDIT_PASSWORD' && <UserEditPasswordContainer />}
        {drawerView === 'ADD_ORDER_TO_DISPATCH' && <AddOrderContainer />}
        {drawerView === 'LIQUIDATION_EDIT' && <DepositEditContainer />}
        {drawerView === 'ORDER_EVIDENCE_EDIT' && <OrderEvidenceContainer />}
        {drawerView === 'ORDER_CHAT' && <OrderChatContainer />}
        {drawerView === 'ORDER_SHOPIFY_CREATE' && <OrderShopifyNewContainer />}
        {drawerView === 'ADD_VOUCHER' && <VoucherContainer />}
        {drawerView === 'TK_CREATE' && <TicketSupportEditContainer />}
        {drawerView === 'RESET_PASS' && <PassContainer />}
        {drawerView === 'CATALOG_CREATE' && <CatalogEditContainer />}
      </Drawer>

      <Modal
        title={modalTitle}
        open={displayModal}
        onCancel={closeModal}
        destroyOnClose
        width={1000}
        footer={[]}
        onOk={closeModal}
      >
        {modalView === 'ORDER_DELIVERIES' && <OrderDeliveryContainer />}
        {modalView === 'ORDER_PAYMENTS' && <OrderPaymentContainer />}
        {modalView === 'ORDER_AGENCY_PAYMENTS' && <OrderAgencyPayContainer />}
        {modalView === 'ROUTE_ORDER_DELIVERIES' && <DeliveriesContainer />}
        {modalView === 'USER_LOGOUT' && <UserLogoutContainer />}
        {modalView === 'DISPATCH_SUMMARY' && <DispatchGuideSummaryContainer />}
        {modalView === 'PRODUCTS_ACCUMULATED' && <SummaryProductAccumulatedContainer />}
        {modalView === 'SUMMARY_PRODUCTS_EDIT' && <SummaryProductEditContainer />}
      </Modal>

    </>
  )
}

export default Wrapper
