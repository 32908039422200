/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Button, Select, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useGuideAvailablesList } from '../../../../../services/dispatch-guide-availables/dispatch-guide-availables.service'
import { IGuideResponse } from '../../../../../services/dispatch-guide-availables/dispatch-guide-availables.interface'
import { useGuideAssign } from '../../../../../services/dispatch-guide-assign/dispatch-guide-assign.service'
import { IGuideAssignRequest, IGuideUnassignRequest } from '../../../../../services/dispatch-guide-assign/dispatch-guide-assign.interface'
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store'
import { useGuideUnassign } from '../../../../../services/dispatch-guide-assign/dispatch-guide-unassign.service'
import { DISPATCH_STATUS_LIST } from '../../../../../services/dispatch-guide-create/dispatch.params'
import { useAuthStore } from '../../../../../services/auth/auth.service'

const AssignDistpachContainer = () => {
  const { orderDeliveryChecked } = useOrderStore()
  const { setGuideAssignUpdated } = useDispatchGuideStore()
  const { getGuides, guides, loading } = useGuideAvailablesList()
  const { guideAssign, guideAssigned, loading: loadingAssign } = useGuideAssign()
  const { guideUnassign, guideUnassigned, loading: loadingUnassign } = useGuideUnassign()
  const [dataRequest, setDataRequest] = useState<IGuideAssignRequest>()
  const { closeDrawer } = useDrawer()
  const { authUser } = useAuthStore()

  const handleGuideChange = (value: number, option: any) => {
    const data: IGuideAssignRequest = {
      orderIds: orderDeliveryChecked.map(o => o.id),
      dispatchGuideId: value
    }

    setDataRequest(data)
  }

  const handleGuideUnassignment = () => {

    const orderFirst = orderDeliveryChecked[0]

    if (orderFirst.dispatchGuide) {
      const data: IGuideUnassignRequest = {
        orderIds: orderDeliveryChecked.map(order => order.id),
        dispatchGuideId: orderFirst.dispatchGuide.id
      }
          
      guideUnassign(data)
    } else {
      message.warning('Pedido seleccionado no tiene asignado guía de despacho.')
    }

  }

  const handleMotorizedReassigment = () => {
    if (dataRequest) {
      guideAssign(dataRequest)
    }
  }

  useEffect(() => {
    getGuides({
      companyId: authUser.user.company.id,
      dispatchStatus: DISPATCH_STATUS_LIST.REGISTERED
    })
  }, [])

  useEffect(() => {
    if (guideAssigned) {
      setGuideAssignUpdated(guideAssigned)
      closeDrawer()
    }
  }, [guideAssigned])

  useEffect(() => {
    if (guideUnassigned) {
      setGuideAssignUpdated(guideUnassigned)
      closeDrawer()
    }
  }, [guideUnassigned])

  return (
    <>
      <Spin spinning={loadingAssign || loadingUnassign}>

        <Alert
          style={{ marginBottom: '1rem' }}
          message="Asignación de órdenes"
          description={
            `Se ha seleccionado ${orderDeliveryChecked?.length} órdenes para asignación manual. Puede quitar la asignación o asignar a una nueva guía.`
          }
          type="info"
          showIcon
          action={
            <Button
              onClick={handleGuideUnassignment}
              size="small" danger type="ghost"
            >
              Quitar asignación
            </Button>
          }
        />

        <p>Reasignar a guía:</p>
        <Select
          loading={loading}
          style={{ width: '100%', marginBottom: '1rem' }}
          showSearch
          optionFilterProp="label"
          placeholder='Seleccionar guía'
          options={guides?.map((e: IGuideResponse) => ({
            label: e.label,
            value: e.id,
          }))}
          onChange={handleGuideChange}
        />

        <Button
          onClick={handleMotorizedReassigment}
          type="primary" htmlType="submit" size="large"
        >
          Guardar
        </Button>
      </Spin>
    </>
  )
}

export default AssignDistpachContainer
