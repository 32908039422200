import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Select, Typography } from 'antd'
import moment from 'moment'
import { downloadExcel } from 'react-export-table-to-excel'
import { useAuthStore } from '../../../../services/auth/auth.service'
import { useMovementsReport } from '../../../../services/order-report-created/order-report-created.service'
import { useWarehouseByCountry } from '../../../../services/warehouse-list/warehouse-list.service'
import { formatDateUTC } from '../../../../utils/date'

const { Title } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const MovementsReport = () => {
  const { authUser } = useAuthStore()
  const dateFormat = 'YYYY-MM-DD'
  const { getMovementsReport, loadingMovements, movements } = useMovementsReport()
  const { getWarehouses, loadingWarehouses, warehouses } = useWarehouseByCountry()
  const [ecomSelected, setEcomSelected] = useState<string>('')
  const [hiddenCombox, setHiddenCombox] = useState(false)
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    moment(new Date(), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])
  
  const headerOrder = [
    'FECHA CREACIÓN', 'TIENDA', 'ALMACÉN', 'OPERACIÓN', 'TIPO', 'ESTADO', 'COMENTARIO', 'CANTIDAD', 
    'PRODUCTO', 'VARIANTE',
  ]

  function handleDownloadExcelMovements() {

    getMovementsReport(
      selectedDatesOrder[0].format('yyyy-MM-DD'),
      selectedDatesOrder[1].format('yyyy-MM-DD'),
      parseInt(ecomSelected),
      authUser.user.company.countryCode
    )

  }

  useEffect(() => {
    if (movements && movements.length > 0) {
      downloadExcel({
        fileName: 
          `movements-${selectedDatesOrder[0].format('yyyy-MM-DD')}-to-${selectedDatesOrder[1].format('yyyy-MM-DD')}`,
        sheet: 'react-export-table-to-excel',
        tablePayload: {
          header: headerOrder,
          // accept two different data structures
          body: movements.map(m => {
            return { 
              createdAt: formatDateUTC(m.createdAt),
              company: m.company,
              warehouse: m.warehouse,
              operation: m.operation,
              type: m.type,
              status: m.status,
              comment: m.comment,
              quantity: m.quantity,
              product: m.product,
              variant: m.variant,
            }
          })
        }
      })
    }
  }, [movements])

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {
    setSelectedDatesOrder(dates)
  }

  const handleChangeEcom = (ecomId: string) => {
    setEcomSelected(ecomId)
  }

  useEffect(() => {

    if (authUser.user.role.name === 'ADMIN') {
      setHiddenCombox(false)
      getWarehouses()
    } else if (authUser.user.role.name === 'STORE') {

      if (authUser.user.warehouse) {
        setEcomSelected(`${authUser.user.warehouse.id}`)
        setHiddenCombox(true)
      }
      
    }
    
  }, [])

  useEffect(() => {
    if (warehouses && warehouses.length > 0) {
      setEcomSelected(`${warehouses[0].id}`)
    }
  }, [warehouses])
  

  return <>
    <div>
      
      <Divider />

      <Title level={5}
        style={{ marginBottom: '0', alignContent: 'center', padding: '.0rem 1.5rem' }}>
        Reporte de movimientos
      </Title>

      <div
        style={{
          padding: '.85rem 1.5rem', display: 'flex',
          gap: '1rem', alignItems: 'center',
        }}
      >

        <Title level={5} style={{ marginBottom: '0' }}>
        Seleccionar Fechas
        </Title>

        <RangePicker format={dateFormat} 
          defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
          onCalendarChange={handleCalendarChangeOrder} clearIcon={false}/>

        { !hiddenCombox ? <>

          <Title level={5} style={{ marginBottom: '0' }}>
            Almacén
          </Title>

          <Select placeholder='Almacén' value={ecomSelected} onChange={handleChangeEcom} 
            loading={loadingWarehouses} style={{ display: 'flex', width: '200px' }}
            showSearch
            optionFilterProp='label'
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option key={-1} value={-1} label={'TODOS'}>TODOS</Option>
            {warehouses.map(c => (
              <Option key={c.id} value={`${c.id}`} label={c.name}>{c.name}</Option>
            ))}
          </Select>
        
        </> : null }

        <Button type='primary' onClick={handleDownloadExcelMovements} 
          loading={loadingMovements}>
            Descargar reporte
        </Button>

      </div>
      
    </div>

  </>
}

export default MovementsReport
