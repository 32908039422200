//import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip, Select, Badge } from 'antd'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
//import { MotocycleIcon } from '../../../../../components/Icons'
import { CompanyChangeContainer } from '../../../../../containers'
//import { useDrawer } from '../../../../../context/DrawerContext'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
//import { useMotorizeAssigment } from '../../../../../services/motorized-assigment/motorized-assigment.service'
import { IOrderFilter } from '../../../../../services/order-list/order.interface'
import { useOrderList } from '../../../../../services/order-list/order.service'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { OrderResumen, OrderSearch, OrdersTable } from '../../components'
import { OrderFilterForm } from '../../forms'
//import { useOrderConfirmedEdit } from '../../../../../services/order-create/order.edit'
import {
  ORDER_STATUS_LIST_CONFIRMED,
  ORDER_STATUS_LIST_NOT_AVAILABLE,
} from '../../../../../services/pre-order/pre-order.params'
import { ORDER_STATUS_LABEL } from '../../../../../services/order-create/order.params'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useDrawer } from '../../../../../context/DrawerContext'

const { Option, OptGroup } = Select

const OrdersAgencyListContainer = () => {
  const { orders, resumen, loading, orderFilter, getOrders, provinceList } = useOrderList()
  const { orderUpdated, setOrderSelected } = useOrderStore()
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()
  //const { motorizeAssigmentExecute, loading: loadingMotorized, assigmentResponse } = useMotorizeAssigment()
  //const { openDrawer } = useDrawer()
  //const { companyUser } = useOrderConfirmedEdit()
  const { openDrawer } = useDrawer()
  const [defaultSelected, setDefaultSelected] = useState<string>('ALL')
  const [defaultStatus, setDefaultStatus] = useState<string>('ALL')

  const handleSearch = (filter: { [key: string]: string } | null) => {
    orderFilter(filter)
  }

  const handleSubmit = (filter: IOrderFilter) => {
    getOrders({
      ...filter,
      companyId: companyGlobal?.id,
      isOrderAgency: 1,
    })
  }

  /*const handleMotorizeAssigment = () => {
    motorizeAssigmentExecute()
  }*/

  useEffect(() => {
    if (orderUpdated) {
      getOrders({
        companyId: companyGlobal?.id,
        callStatus: defaultSelected,
        isOrderAgency: 1,
        status: defaultStatus,
      })
    }
  }, [orderUpdated])

  useEffect(() => {
    setDefaultSelected('ALL')
    setDefaultStatus('ALL')
    getOrders({
      companyId: companyGlobal?.id,
      callStatus: defaultSelected,
      isOrderAgency: 1,
      status: defaultStatus,
    })
  }, [companyGlobal])

  const handleChange = (callStatus: string) => {
    getOrders({
      companyId: companyGlobal?.id,
      callStatus: callStatus,
      isOrderAgency: 1,
      status: defaultStatus,
    })
    setDefaultSelected(callStatus)
  }

  const handleOrderCreate = () => {
    setOrderSelected(null)
    openDrawer('ORDER_AGENCY_EDIT', 'Agregar orden')
  }

  const handleChangeStatus = (status: string) => {
    getOrders({
      companyId: companyGlobal?.id,
      callStatus: defaultSelected,
      isOrderAgency: 1,
      status: status,
    })
    setDefaultStatus(status)
  }

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex', justifyContent: 'space-between', gap: '.5rem', alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
            {authUser.user.role?.permissions?.order_company_change ? (
              <CompanyChangeContainer isAll={true}/>
            ) : null}

            <OrderSearch
              onSearch={handleSearch}
              provinceList={provinceList}
            />
            <div>|</div>
            {resumen ? <OrderResumen resumen={resumen} /> : null}
            
            <Select
              placeholder="Estado llamada"
              style={{ minWidth: '130px' }}
              onChange={handleChange}
              value={defaultSelected}
            >
              <Option key='ALL' value='ALL'>TODOS</Option>
              {ORDER_STATUS_LIST_CONFIRMED.map(status => (
                <Option key={status.value} value={status.value}>{status.label}</Option>
              ))}
              <OptGroup label="ORDEN">
                {ORDER_STATUS_LIST_NOT_AVAILABLE.map(status => (
                  <Option key={status.value} value={status.value}>{status.label}</Option>
                ))}
              </OptGroup>
            </Select>

            <Select
              placeholder="Estado entrega"
              style={{ minWidth: '130px' }}
              onChange={handleChangeStatus}
              value={defaultStatus}
            >
              <Option key='ALL' value='ALL'>
                <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                  TODOS
                </Badge>
              </Option>
              {Object.keys(ORDER_STATUS_LABEL).map(key => (
                <Option
                  key={key}
                  value={key}
                >
                  <Badge style={{ marginRight: '.5rem' }} color={ORDER_STATUS_LABEL[key].color} text="" />
                  {ORDER_STATUS_LABEL[key].label}
                </Option>
              ))}
            </Select>
      
          </div>

          <div style={{ display: 'flex', gap: '.5rem' }}>
            { /*authUser.user.role?.permissions?.order_motorized_assign ? (
              <Tooltip placement="bottom" title="Asignar motorizado">
                <Button type="primary" onClick={handleMotorizeAssigment}
                  loading={loadingMotorized}
                  hidden={companyUser == 2}
                >
                  <MotocycleIcon />
                </Button>
              </Tooltip>
            ) : null*/}

            { authUser.user.role?.permissions?.order_create ? (
              <Tooltip placement="bottom" title="Agregar orden">
                <Button type="primary" onClick={handleOrderCreate}>
                  <PlusCircleOutlined />
                </Button>
              </Tooltip>
            ) : null } 

            <OrderFilterForm onChange={handleSubmit} />
          </div>

        </div>
      </PageHeader>

      <OrdersTable
        data={orders}
        loading={loading}
      />
    </>
  )
}

export default OrdersAgencyListContainer
