import React, { FC, useEffect } from 'react'
import { Button, Divider, Form, Input, InputNumber, Select } from 'antd'
import { ISignUpSchema } from '../../../../services/sign-up/sign-up.schema'

const { Option } = Select

interface ISignUpFormProps {
  loading: boolean
  onSubmit: (data: ISignUpSchema) => void
}

const SignUpForm: FC<ISignUpFormProps> = ({ onSubmit, loading }) => {
  const [form] = Form.useForm<ISignUpSchema>()
  const codeLink = localStorage.getItem('codeReference')

  useEffect(() => {
    if (codeLink && codeLink.length > 0) {
      form.setFieldValue(['referenceCode'], codeLink)
    } else {
      form.setFieldValue(['referenceCode'], null)
    }
  }, [codeLink])
  
  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='RegisterForm'
        labelWrap
        onFinish={onSubmit}
      >

        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <h2 style={{ color: '#ff9800', fontWeight: 'bold', justifyContent: 'center', display: 'flex' }}>
            Regístrate!
          </h2>
          <h4 style={{ fontWeight: 'bold', justifyContent: 'center', display: 'flex', marginBottom: '18px' }}>
            Completa tus datos correctamente para crear tu cuenta</h4>
        </div>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
            País
          </span>}
          name={['country']}
        >
          <Select defaultValue={'Perú'}>
            <Option>Perú</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
            Nombre Ecommerce
          </span>}
          name={['companyName']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input style={{ borderRadius: '8px', borderColor: '#ff9800' }} />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
            Usuario
          </span>}
          name={['email']}
          rules={[{ required: true, message: 'Obligatorio' }]}>
          <Input placeholder='A-JHONNATAN-WBX' style={{ borderRadius: '8px', borderColor: '#ff9800' }}/>
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
          Contraseña
          </span>}
          name={['password']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input type='password' style={{ borderRadius: '8px', borderColor: '#ff9800' }}/>
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
          Correo Electrónico
          </span>}
          name={['userMail']}
          rules={[
            {
              type: 'email',
              message: 'No es un correo electrónico válido',
            },
            { required: true, message: 'Obligatorio' }
          ]}
        >
          <Input style={{ borderRadius: '8px', borderColor: '#ff9800' }}/>
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
          Nombres y Apellidos
          </span>}
          name={['fullname']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input style={{ borderRadius: '8px', borderColor: '#ff9800' }}/>
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
          Teléfono
          </span>}
          name={['phone']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <InputNumber
            controls={false}
            maxLength={11}
            style={{ display: 'inline-block', width: '100%', borderRadius: '8px', borderColor: '#ff9800' }}
          />
        </Form.Item>

        <Form.Item
          label='Código de su referente'
          name={['referenceCode']}
          hidden
          rules={[{ required: false, message: '' }]}
        >
          <Input style={{ borderRadius: '10px', borderColor: '#ff9800' }}/>
        </Form.Item>

        <Divider />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type='primary' htmlType='submit' loading={loading} shape='round'
            style={{ backgroundColor: '#ff9800', borderColor: '#ff9800', fontWeight: 'bold' }}
          >
            Crear cuenta
          </Button>
        </div>
      </Form>
    </>
  )
}

export default SignUpForm
