import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Radio, Select, Typography } from 'antd'
import moment from 'moment'
import { downloadExcel } from 'react-export-table-to-excel'
import { useAuthStore } from '../../../../services/auth/auth.service'
import { 
  useOrderDeliveryReport,
  useOrderReportCreated
} from '../../../../services/order-report-created/order-report-created.service'
import { useCompanyNewList } from '../../../../services/company-list/company-list.service'

const { Title } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const OrderReport = () => {
  const { authUser } = useAuthStore()
  const dateFormat = 'YYYY-MM-DD'
  const { getOrderReportCreated, loadingOrders, ordersCreated } = useOrderReportCreated()
  const { getOrderDeliveryHistory, loadingOrderDelivery, orderDeliveries } = useOrderDeliveryReport()
  const { getCompanies, loading, companies } = useCompanyNewList()
  const [ecomSelected, setEcomSelected] = useState<string>('-1')
  const [hiddenCombox, setHiddenCombox] = useState(false)
  const [filter, setFilter] = useState<string>('create')
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    moment(new Date(), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])
  
  const headerOrder = [
    'NRO. PEDIDO', 'FECHA CREACIÓN SHOPIFY', 'FECHA CREACIÓN ALICLIK', 'TIENDA', 'TOTAL', 'VENDEDOR', 'FECHA ENTREGA', 
    'NRO. VISITAS', 'MOTORIZADO', 'ÚLTIMO ESTADO ENTREGA', 'DEPARTAMENTO', 'PROVINCIA', 'DISTRITO', 'FECHA DESPACHO', 
    'ESTADO LLAMADA', 'ESTADO ENTREGA', 'ESTADO DESPACHO', 'ALMACÉN', 'NOMBRE COMPLETO', 'TELÉFONO', 'COSTO ENTREGA', 
    'DROPSHALOM', 'CANAL', 'PRODUCTO', 'VARIANTE', 'PRECIO DROP TOTAL', 'CANTIDAD', 'PRECIO DROP',
  ]

  const headerOrderAdmin = [
    'NRO. PEDIDO', 'FECHA CREACIÓN SHOPIFY', 'FECHA CREACIÓN ALICLIK', 'TIENDA', 'TOTAL', 'VENDEDOR', 'FECHA ENTREGA', 
    'NRO. VISITAS', 'MOTORIZADO', 'ÚLTIMO ESTADO ENTREGA', 'DEPARTAMENTO', 'PROVINCIA', 'DISTRITO', 'FECHA DESPACHO', 
    'ESTADO LLAMADA', 'ESTADO ENTREGA', 'ESTADO DESPACHO', 'ALMACÉN', 'NOMBRE COMPLETO', 'TELÉFONO', 'COSTO ENTREGA', 
    'COSTO ENTREGA ADICIONAL', 'DROPSHALOM', 'CANAL', 'PRODUCTO', 'VARIANTE', 'PRECIO DROP TOTAL', 'CANTIDAD', 
    'PRECIO DROP',
  ]

  const headerOrderDelivery = [
    'NRO. PEDIDO', 'FECHA ENTREGA', 'MOTORIZADO', 'ESTADO ENTREGA', 'COMENTARIO', 'ÚLTIMO ESTADO ANTES DE REPRO'
  ]

  function handleDownloadExcelOrders() {

    getOrderReportCreated(
      selectedDatesOrder[0].format('yyyy-MM-DD'),
      selectedDatesOrder[1].format('yyyy-MM-DD'),
      parseInt(ecomSelected),
      filter,
      authUser.user.company.countryCode
    )

    if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER') {
      getOrderDeliveryHistory(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        parseInt(ecomSelected),
        filter,
        authUser.user.company.countryCode
      )
    }

  }

  const downloadOrderDeliveriesReport = () => {
    if (orderDeliveries && orderDeliveries.length > 0) {
      downloadExcel({
        fileName: 
          `order_delivery_${selectedDatesOrder[0].format('yyyy-MM-DD')}_${selectedDatesOrder[1].format('yyyy-MM-DD')}`,
        sheet: 'orders-delivery',
        tablePayload: {
          header: headerOrderDelivery,
          // accept two different data structures
          body: orderDeliveries.map(o => {
            return { 
              orderNumber: o.orderNumber,
              deliveryDate: o.deliveryDate,
              motorized: o.motorized,
              status: o.deliveryStatus,
              comment: o.comment,
              lastRescheduledStatus: o.lastRescheduledStatus,
            }
          })
        }
      })
    }
  }

  useEffect(() => {
    if (ordersCreated && ordersCreated.length > 0) {
      downloadExcel({
        fileName: 
          `orders-${selectedDatesOrder[0].format('yyyy-MM-DD')}-to-${selectedDatesOrder[1].format('yyyy-MM-DD')}`,
        sheet: 'orders',
        tablePayload: {
          header: authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' ? headerOrderAdmin 
            : headerOrder,
          // accept two different data structures
          body: authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' ? ordersCreated.map(o => {
            return { 
              orderNumber: o.orderNumber,
              createdAtShopify: o.createdAtShopify,
              createdAt: o.createdAt,
              company: o.company,
              total: o.total,
              seller: o.seller,
              deliveryDate: o.deliveryDate,
              numberVisits: o.numberVisits,
              motorized: o.motorized,
              lastRescheduledStatus: o.lastRescheduledStatus,
              department: o.department,
              province: o.province,
              district: o.district,
              dispatchDate: o.dispatchDate,
              callStatus: o.callStatus,
              status: o.status,
              dispatchStatus: o.dispatchStatus,
              warehouse: o.warehouse,
              fullName: o.fullName,
              phone: o.phone,
              shippingCost: o.shippingCost,
              additionalDeliveryCost: o.additionalDeliveryCost,
              dropShalom: o.dropShalom,
              channel: o.channel,
              product: o.product,
              variant: o.variant,
              dropPriceTotal: o.dropPriceTotal,
              quantity: o.quantity,
              dropPrice: o.dropPrice,
            }
          }) : ordersCreated.map(o => {
            return { 
              orderNumber: o.orderNumber,
              createdAtShopify: o.createdAtShopify,
              createdAt: o.createdAt,
              company: o.company,
              total: o.total,
              seller: o.seller,
              deliveryDate: o.deliveryDate,
              numberVisits: o.numberVisits,
              motorized: o.motorized,
              lastRescheduledStatus: o.lastRescheduledStatus,
              department: o.department,
              province: o.province,
              district: o.district,
              dispatchDate: o.dispatchDate,
              callStatus: o.callStatus,
              status: o.status,
              dispatchStatus: o.dispatchStatus,
              warehouse: o.warehouse,
              fullName: o.fullName,
              phone: o.phone,
              shippingCost: o.shippingCost,
              dropShalom: o.dropShalom,
              channel: o.channel,
              product: o.product,
              variant: o.variant,
              dropPriceTotal: o.dropPriceTotal,
              quantity: o.quantity,
              dropPrice: o.dropPrice,
            }
          })
        }
      })
    }
  }, [ordersCreated])

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {
    setSelectedDatesOrder(dates)
  }

  /*useEffect(() => {
    if (selectedDatesOrder.length === 2 && selectedDatesOrder[0] && selectedDatesOrder[1] 
      && ecomSelected.length > 0) {

      getOrderReportCreated(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        parseInt(ecomSelected),
        filter,
        authUser.user.company.countryCode
      )

    }
  }, [selectedDatesOrder])*/

  const handleChangeEcom = (ecomId: string) => {

    /*if (ecomId.length > 0) {
      getOrderReportCreated(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        parseInt(ecomId),
        filter,
        authUser.user.company.countryCode
      )
    }*/
    
    setEcomSelected(ecomId)
  }

  const handleFilter = (e: any) => {
    /*getOrderReportCreated(
      selectedDatesOrder[0].format('yyyy-MM-DD'),
      selectedDatesOrder[1].format('yyyy-MM-DD'),
      parseInt(ecomSelected),
      e.target.value,
      authUser.user.company.countryCode
    )*/
    setFilter(e.target.value)
  }

  useEffect(() => {

    if (authUser.user.role.name === 'ADMIN') {
      setHiddenCombox(false)
      getCompanies()
    } else if (authUser.user.role.name === 'ADMIN_STORE') {
      setEcomSelected(`${authUser.user.company.id}`)
      setHiddenCombox(true)
    }
    
  }, [])

  useEffect(() => {
    if (companies && companies.length > 0) {

      if (authUser.user.company.countryCode === 'PER') {
        const companyFilters = companies.filter(c => c.type === 'NORMAL')

        if (companyFilters && companyFilters.length > 0) {
          setEcomSelected(`${companies.filter(c => c.type === 'NORMAL')[0].id}`)
        }

      } else if (authUser.user.company.countryCode === 'BOL') {
        setEcomSelected(`${authUser.user.company.id}`)
      }
      
    }
  }, [companies])
  

  return <>
    <div>
      
      <Divider />

      <Title level={5}
        style={{ marginBottom: '0', alignContent: 'center', padding: '.0rem 1.5rem' }}>
        Reporte de pedidos
      </Title>

      <div
        style={{
          padding: '.85rem 1.5rem', display: 'flex',
          gap: '1rem', alignItems: 'center',
        }}
      >

        <Title level={5} style={{ marginBottom: '0' }}>
          Filtrar por fecha
        </Title>

        <Radio.Group
          options={[
            { label: 'Agendamiento', value: 'create' },
            { label: 'Entrega', value: 'delivery' },
            { label: 'Despacho', value: 'dispatch' },
          ]}
          onChange={handleFilter}
          defaultValue={filter}
        />

        <Title level={5} style={{ marginBottom: '0' }}>
        Seleccionar
        </Title>

        <RangePicker format={dateFormat} 
          defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
          onCalendarChange={handleCalendarChangeOrder} clearIcon={false}/>

        { !hiddenCombox ? <>

          <Title level={5} style={{ marginBottom: '0' }}>
            Ecommerce
          </Title>

          <Select placeholder='Ecommerce' value={ecomSelected} onChange={handleChangeEcom} 
            loading={loading} style={{ display: 'flex', width: '200px' }}
            showSearch
            optionFilterProp='label'
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            { authUser.user.company.countryCode === 'PER' ? <>
              <Option key={'-1'} value={'-1'} label={'TODOS'}>TODOS</Option>
              {companies.filter(c => c.type === 'NORMAL').map(c => (
                <Option key={c.id} value={`${c.id}`} label={c.name}>{c.name}</Option>
              ))}
            </> : <>{ authUser.user.company.countryCode === 'BOL' ? <>
              
              <Option key={'-1'} value={'-1'} label={'TODOS'}>TODOS</Option>
              {companies.map(c => (
                <Option key={c.id} value={`${c.id}`} label={c.name}>{c.name}</Option>
              ))}
              
            </> : null } </>}
            
          </Select>
        
        </> : null }

        <Button type='primary' onClick={handleDownloadExcelOrders} 
          loading={loadingOrders}>
            Descargar reporte
        </Button>

        { authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' ? <>
           
          { orderDeliveries && orderDeliveries.length > 0 ? <>

            <Button type='primary' onClick={downloadOrderDeliveriesReport} 
              loading={loadingOrderDelivery}>
                Descargar historial de pedidos
            </Button>

          </> : null }
        </> : null }

      </div>
      
    </div>

  </>
}

export default OrderReport
