import React, { useEffect, useState } from 'react'
import { Button, Divider, Select, Typography } from 'antd'
import { downloadExcel } from 'react-export-table-to-excel'
import { useAuthStore } from '../../../../services/auth/auth.service'
import { useCurrentStockReport } from '../../../../services/order-report-created/order-report-created.service'
import { useWarehouseByCountry } from '../../../../services/warehouse-list/warehouse-list.service'

const { Title } = Typography
const { Option } = Select

const CurrentStockReport = () => {
  const { authUser } = useAuthStore()
  const { getCurrentStockReport, loadingStocks, currentStocks } = useCurrentStockReport()
  const { getWarehouses, loadingWarehouses, warehouses } = useWarehouseByCountry()
  const [ecomSelected, setEcomSelected] = useState<string>('')
  const [warName, setWarName] = useState<string>('')
  const [hiddenCombox, setHiddenCombox] = useState(false)
  
  const headerOrder = [
    'PRODUCTO', 'VARIANTE', 'CANTIDAD', 'ALMACÉN', 'TIENDA',
  ]

  function handleDownloadExcelCurrentStock() {

    getCurrentStockReport(
      parseInt(ecomSelected)
    )

  }

  useEffect(() => {
    if (currentStocks && currentStocks.length > 0) {
      downloadExcel({
        fileName: 
          `stock-${warName}`,
        sheet: 'react-export-table-to-excel',
        tablePayload: {
          header: headerOrder,
          // accept two different data structures
          body: currentStocks.map(m => {
            return {
              product: m.product,
              variant: m.variant,
              quantity: m.quantity,
              warehouse: m.warehouse,
              company: m.company,
            }
          })
        }
      })
    }
  }, [currentStocks])

  const handleChangeEcom = (ecomId: string) => {
    setEcomSelected(ecomId)
  }

  useEffect(() => {

    if (authUser.user.role.name === 'ADMIN') {
      setHiddenCombox(false)
      getWarehouses()
    } else if (authUser.user.role.name === 'STORE') {
      if (authUser.user.warehouse) {
        setEcomSelected(`${authUser.user.warehouse.id}`)
        setHiddenCombox(true)
      }
    }
    
  }, [])

  useEffect(() => {
    if (warehouses && warehouses.length > 0) {
      setEcomSelected(`${warehouses[0].id}`)
    }
  }, [warehouses])
  
  useEffect(() => {
    if (ecomSelected && ecomSelected.length > 0 && warehouses.length > 0) {
      const war = warehouses.find(it => `${it.id}` === `${ecomSelected}`)

      if (war) {
        setWarName(war.name)
      }
    } 
  }, [ecomSelected])

  return <>
    <div>
      
      <Divider />

      <Title level={5}
        style={{ marginBottom: '0', alignContent: 'center', padding: '.0rem 1.5rem' }}>
        Reporte de stock actual
      </Title>

      <div
        style={{
          padding: '.85rem 1.5rem', display: 'flex',
          gap: '1rem', alignItems: 'center',
        }}
      >

        { !hiddenCombox ? <>

          <Title level={5} style={{ marginBottom: '0' }}>
            Almacén
          </Title>

          <Select placeholder='Almacén' value={ecomSelected} onChange={handleChangeEcom} 
            loading={loadingWarehouses} style={{ display: 'flex', width: '200px' }}
            showSearch
            optionFilterProp='label'
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            {warehouses.map(c => (
              <Option key={c.id} value={`${c.id}`} label={c.name}>{c.name}</Option>
            ))}
          </Select>
        
        </> : null }

        <Button type='primary' onClick={handleDownloadExcelCurrentStock} 
          loading={loadingStocks}>
            Descargar reporte
        </Button>

      </div>
      
    </div>

  </>
}

export default CurrentStockReport
