import React, { FC, useEffect, useState } from 'react'
import { useCustomerPhoneInfo } from '../../../../../services/customer-phone/customer-phone.service'
import { Spin, Tag, Typography } from 'antd'

const { Title } = Typography

interface ICustomerPhoneFormProps {
  phone?: string
}
  
const CustomerPhoneForm: FC<ICustomerPhoneFormProps> = ({
  phone
}) => {

  // red #FF5252 // orange #FF9800 //green #4CAF50
  const { getCustomerPhoneInfo, response, loading } = useCustomerPhoneInfo()
  const [colorTag, setColorTag] = useState<string>()

  useEffect(() => {
    getCustomerPhoneInfo(phone)
  }, [phone])

  useEffect(() => {
    if (response) {

      if (response.totalDelivered > 0 && response.totalNoDelivered > 0) {
        setColorTag('#FF9800')
      } else if (response.totalDelivered > 0 && response.totalNoDelivered === 0) {
        setColorTag('#4CAF50')
      } else if (response.totalNoDelivered > 0 && response.totalDelivered === 0) {
        setColorTag('#FF5252')
      } else {
        setColorTag('#2196F3')
      }

    }
  }, [response])
  
  return <>
    <Spin spinning={loading}>
      <Tag color={colorTag} style={{ height: 30, width: 150 }}></Tag>
      <Title level={5}>Pedidos Entregados: {response?.totalDelivered}</Title>
      <Title level={5}>Pedidos No Entregados: {response?.totalNoDelivered}</Title>
    </Spin>
  </>
}
  
export default CustomerPhoneForm
