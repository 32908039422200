import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, Form, Select } from 'antd'
import { ILocation } from '../../../../../services/location/location.interface'
import { getDistrictsByProvince, getProvinceList } from '../../../../../services/location/location.service'
import { ICoverageCreateSchema } from '../../../../../services/coverage-create/coverage-create.schema'

interface ICoverageEditFormProps {
  onSubmit: (data: ICoverageCreateSchema) => void;
}

const CoverageEditForm: FC<ICoverageEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<ICoverageCreateSchema>()

  const [districtList, setDistrictList] = useState<ILocation[]>()
  const provinceList = getProvinceList()
  const province = Form.useWatch(['shipping', 'province'], form)
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const handleChangeProvince = (ubigeoId: string) => {
    const districts = getDistrictsByProvince(ubigeoId)

    setDistrictList(districts)
    form.setFieldsValue({
      district: undefined,
    })
  }

  const handleSubmit = (data: ICoverageCreateSchema) => {
    const district = form.getFieldValue('district')

    if (!district?.length) {
      data.district = districtList?.map(d => d.id_ubigeo)
    }

    onSubmit(data)
  }

  useEffect(() => {
    if (province) {
      handleChangeProvince(province)
    }
  }, [province])

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      form={form}
      name="order"
      labelWrap
      onFinish={handleSubmit}
    >
      <p>Para agregar todo los distritos de la provincia seleccionada deje vacío el campo distrito.</p>

      <Form.Item
        label="Provincia"
        name={['province']}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          onChange={handleChangeProvince}
          showSearch
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={provinceList?.map(e => ({
            label: e.nombre_ubigeo,
            value: e.id_ubigeo,
          }))}
        />
      </Form.Item>

      <Form.Item
        label="Distrito"
        name={['district']}>
        <Select
          showSearch
          allowClear
          mode="multiple"
          value={selectedItems}
          onChange={setSelectedItems}
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={districtList?.map(e => ({
            label: e.nombre_ubigeo,
            value: e.id_ubigeo,
          }))}
        />
      </Form.Item>

      <Divider />

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CoverageEditForm
