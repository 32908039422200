import React from 'react'
import { Affix, Button, Tooltip } from 'antd'
import { BulbOutlined } from '@ant-design/icons'

const FloatingButton = () => (
  <>
    <Affix style={{ position: 'fixed', bottom: 50, right: 30, zIndex: 100 }}>
      <Tooltip title='Centro de recursos' placement='left'>
        <Button type='primary' shape='circle' size='large' style={{ width: 50, height: 50 }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={'https://aliclik.app/faq'}>
            <BulbOutlined />
          </a>
        </Button>
      </Tooltip>
    </Affix>
  </>
)

export default FloatingButton
