/* eslint-disable max-len */
export const TEMPLATE_1 = `
{name}, 👋 le saludamos del area de seguimiento de la empresa *{businessName}*, estamos intentando comunicarnos pero no tenemos respuesta alguna por lo que agradeceremos nos confirme la programación del pedido ✅ o procederemos a anularlo ❌
%0a
%0a
*Codigo:* {orderNumber}
%0a
*Productos:* {product}
%0a
*Dirección:* {fullAddress}
%0a
%0a
- *Mil gracias por su atención y que tenga un maravilloso día!!!*`

export const TEMPLATE_NEW_1 = `
Hola 👋 {name} te saludamos de parte de la empresa {businessName} su pedido con código {orderNumber} del producto {product} está siendo preparado📦 para ser enviado a la dirección {fullAddress} 🗺 para ello es importantísimo que envíe la ubicación actual 📍en la que recibirá el pedidos, gracias 🤗por la solicitud de pedido porfavor confirmar su ubicación actual`

export const TEMPLATE_0 = `
Hola {name} 😁,  buen día le saludamos la empresa *{businessName}*, gracias por realizar su pedido estamos preparandolo para su pronta entrega. Por favor indicarnos su *DISTRITO* y compartirnos su *ubicación* por Whatsapp 🛵 para llegar lo mas rápido posible. *Que tenga un excelente día!!!`

export const TEMPLATE_2 = `
{name}, 👋 le saludamos de la empresa *{businessName}* el día *{scheduleDate}* se estará trasladando su pedido a su domicilio en el Rango de 10:00 a.m a 5:00 p.m.
%0a
%0a
*Codigo:* {orderNumber}
%0a
*Productos:* {product}
%0a
*Monto:* {total}
%0a
*Dirección:* {fullAddress}
%0a
*Ejecutiv@ de ventas:* {seller}
%0a
%0a
El motorizado le llamara anticipadamente *favor de responderle*{location}
%0a
*Que tenga un maravilloso día!!!*
`
export const TEMPLATE_3 = `
Hola *{seller}*, su pedido *{orderNumber}* tienda *{businessName}*...`

export const TEMPLATE_4 = `
Estimado cliente, Su pedido está disponible para recoger en nuestra agencia por un máximo de 5 días hábiles. Recuerde que su responsabilidad es recogerlo dentro de este plazo. Por favor, hágalo a tiempo para evitar problemas. Ya que es un tercero quien lo gestiona es decir la empresa por la que se le envio. Atentamente, {ecom}.
`

export const TEMPLATE_5 = `
Estimado cliente, Su pedido está disponible para recoger en nuestra agencia por un máximo de 5 días hábiles. Recuerde que su responsabilidad es recogerlo dentro de este plazo. Por favor, hágalo a tiempo para evitar problemas. Ya que es un tercero quien lo gestiona es decir la empresa por la que se le envio. Es importante que envie el saldo pendiente por favor. Atentamente, {ecom}.
`

export const TEMPLATE_6 = `
Estimado/a {customer}, Nos complace informarle que su pedido con CODIGO {orderNumber} del {products} esta {status} por favor su apoyo en completar el SALDO {amount}, para que pueda recogerlo con normalidad. Gracias por su compra Atentamente, {ecommerce}.\nDirección de recojo: {address}.
`

export const TEMPLATE_7 = `
Estimado/a {customer}, Nos complace informarle que su pedido con CODIGO {orderNumber} del {products} esta en {status} por favor su apoyo en completar el SALDO {amount}, para que pueda recogerlo con normalidad. Por favor recordar que el producto llega en 3 a 5 días de acuerdo a cada departamento. Gracias por su compra Atentamente, {ecommerce}.\nDirección de recojo: {address}.
`

export const TEMPLATE_8 = `
Estimado/a {customer}, Nos complace informarle que su pedido con CODIGO {orderNumber} del {products} esta en {status} por favor su apoyo en completar el SALDO {amount} , para que pueda recogerlo con normalidad. Por favor recordar que el producto llega en 3 a 5 días de acuerdo a cada departamento. Su apoyo en ir a recogerlo a la agencia destino Gracias por su compra Atentamente, {ecommerce}.\nDirección de recojo: {address}
`
