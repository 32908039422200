import React, { useEffect } from 'react'
import { useDrawer } from '../../../../context/DrawerContext'
import { useSignUpCreate } from '../../../../services/sign-up/sign-up.service'
import { ISignUpSchema } from '../../../../services/sign-up/sign-up.schema'
import { SignUpForm } from '../../forms'

const SignUpContainer = () => {
  const { closeDrawer } = useDrawer()
  const { signUpCreate, signUpCreated, loading } = useSignUpCreate()

  const handleSubmit = (data: ISignUpSchema) => {
    signUpCreate(data)
  }

  useEffect(() => {
    if (signUpCreated) {
      localStorage.removeItem('referenceCode')
      closeDrawer()
    }
  }, [signUpCreated])

  return (
    <>
      <SignUpForm
        onSubmit={handleSubmit}
        loading={loading}
      />
    </>
  )
}

export default SignUpContainer
