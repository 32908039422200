import { IMovementResponse } from '../../services/movement-list/movement-list.interface'
import { IMovementCreateResponse } from '../../services/movement-create/movement.interface'

export const actionTypes = {
  SET_MOVEMENT_SELECTED: 'SET_MOVEMENT_SELECTED',
  SET_MOVEMENT_UPDATED: 'SET_MOVEMENT_UPDATED',
}

const setMovementSelected = (data: IMovementResponse | null) => ({
  type: actionTypes.SET_MOVEMENT_SELECTED,
  payload: data,
})

const setMovementUpdated = (data: IMovementCreateResponse) => ({
  type: actionTypes.SET_MOVEMENT_UPDATED,
  payload: data,
})

export const movementActions = {
  setMovementSelected,
  setMovementUpdated
}
