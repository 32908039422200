
import React, { FC } from 'react'
import { DatePicker, Form, InputNumber, Radio, Typography } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { IPreOrderUpdateSchema } from '../../../../../../services/pre-order/pre-order.schema'
import { UploadImage } from '../../../components'

const { Title } = Typography

interface IPreOrderPaymentPartProps {
  form: FormInstance<IPreOrderUpdateSchema>
  disabled: boolean
}

const PreOrderPaymentPart: FC<IPreOrderPaymentPartProps> = ({ form, disabled }) => {
  const prePayment = Form.useWatch('prePayment', form)

  return <>
    <Title level={4}>Pagos</Title>
    <Form.Item name="prePayment" label="¿Pagó adelantado?">
      <Radio.Group
        options={[
          { label: 'No', value: false },
          { label: 'Si', value: true },
        ]}
        optionType="button"
        buttonStyle="solid"
        disabled={disabled}
      />
    </Form.Item>

    {prePayment ? (<>
      <Form.Item
        label="Monto de pago"
        name={['payment', 'amount']} rules={[{ required: true, message: 'Obligatorio' }]}>
        <InputNumber min={0} style={{ width: '100%' }} disabled={disabled}/>
      </Form.Item>

      <Form.Item label="Fecha de pago"
        name={['payment', 'paymentDate']}
      >
        <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} disabled={disabled}/>
      </Form.Item>
      <Form.Item
        label="Cargar Imagen"
        name={['payment', 'paymentDocument']} rules={[{ required: true, message: 'Obligatorio' }]}
        hidden={disabled}>
        <UploadImage />
      </Form.Item>
    </>) : null}
  </>
}

export default PreOrderPaymentPart
