import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { coverageActions } from '../../state/coverage/coverage.actions'
import { ICoverageState } from '../../state/coverage/coverage.reducer'
import { ICoverageItemResponse } from './coverage-create.interface'

export const useCoverageStore = () => {
  const dispatch = useDispatch()
  const { coverageUpdated } = useSelector((state: IRootState) => state.coverage as ICoverageState)

  const setCoverageUpdated = (data: ICoverageItemResponse) => {
    dispatch(coverageActions.setCoverageUpdated(data))
  }

  return {
    setCoverageUpdated,
    coverageUpdated,
  }
}
