import React, { FC, useEffect, useState } from 'react'
import { Form, FormInstance, Input, Select } from 'antd'
import { getDistrictsByProvince, getProvinceList } from '../../../../../../services/location/location.service'
import { ILocation } from '../../../../../../services/location/location.interface'
import { IWarehouseCreateSchema } from '../../../../../../services/warehouse-create/warehouse-create.schema'

interface IWarehouseLocationPartProps {
  form: FormInstance<IWarehouseCreateSchema>
}

const WarehouseLocationPart: FC<IWarehouseLocationPartProps> = ({ form }) => {
  const [districtList, setDistrictList] = useState<ILocation[]>()
  const provinceList = getProvinceList()
  const province = Form.useWatch(['province'], form)

  const handleChangeProvince = (ubigeoId: string) => {
    const districts = getDistrictsByProvince(ubigeoId)

    setDistrictList(districts)
  }

  useEffect(() => {
    if (province) {
      handleChangeProvince(province)
    }
  }, [province])

  return <>

    <Form.Item
      label={<span style={{ fontSize: '13px' }}>
      Dirección
      </span>}
      name={['address']} rules={[{ required: false, message: '' }]}>
      <Input style={{ display: 'inline-block', width: '100%', borderRadius: '8px' }}/>
    </Form.Item>

    <Form.Item
      label={<span style={{ fontSize: '13px' }}>
      GPS
      </span>}
      name={['gps']}
      rules={[{
        message: '',
        pattern: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/
      }]}>
      <Input
        placeholder='Latitud,Longitud'
        style={{ display: 'inline-block', width: '100%', borderRadius: '8px' }}
      />
    </Form.Item>

    <Form.Item
      label={<span style={{ fontSize: '13px' }}>
      Provincia
      </span>}
      name={['province']}>
      <Select
        onChange={handleChangeProvince}
        showSearch
        optionFilterProp="label"
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={provinceList?.map(e => ({
          label: e.nombre_ubigeo,
          value: e.id_ubigeo,
        }))}
      />
    </Form.Item>

    <Form.Item
      label={<span style={{ fontSize: '13px' }}>
      Distrito
      </span>}
      name={['district']}>
      <Select
        showSearch
        optionFilterProp="label"
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={districtList?.map(e => ({
          label: e.nombre_ubigeo,
          value: e.id_ubigeo,
        }))}
      />
    </Form.Item>

  </>
}

export default WarehouseLocationPart
