/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { IOrderDeliveryCreateRequest, IOrderDeliveryCreateResponse } from './order-delivery-create.interface'
import { datadogLogs } from '../../hooks/use-datadog'
import { useAuthStore } from '../auth/auth.service'
import { useEffect } from 'react'


export const useOrderDeliveryCreate = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IOrderDeliveryCreateResponse>()

  const saveOrderDelivery = (orders: IOrderDeliveryCreateRequest[]) => {
    execute({
      method: 'POST',
      url: '/maintenance/order-delivery',
      data: orders,
    })
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('ORDERS_ROUTE:POST', { action: 'ORDERS_ROUTE:POST',
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        ordersResponse: response,
      })
    }
  }, [response])

  return {
    saveOrderDelivery,
    loading,
    response,
  }
}
