import React, { useEffect } from 'react'
import { PassVerifyForm } from '../../forms'
import { IResetPasswordSchema } from '../../../../services/auth/auth.interface'
import { useResetPassword } from '../../../../services/auth/auth.service'
import { useDrawer } from '../../../../context/DrawerContext'
import { message } from 'antd'

const PassContainer = () => {
  const { closeDrawer } = useDrawer()
  const { resetPassword, responsePass, loadingPass } = useResetPassword()

  const handleResetPassword = (request: IResetPasswordSchema) => {
    resetPassword(request)
  }

  useEffect(() => {
    if (responsePass) {
      message.success('Contraseña actualizada')
      closeDrawer()
    }
  }, [responsePass])

  return (
    <>
      <PassVerifyForm
        resetPassword={handleResetPassword}
        loadingChangePassword={loadingPass}
      />
    </>
  )
}

export default PassContainer
