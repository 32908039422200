import { useAxios } from '../../hooks'
import { IGuideAssignResponse, IGuideUnassignRequest } from './dispatch-guide-assign.interface'
import { datadogLogs } from '../../hooks/use-datadog'
import { useAuthStore } from '../auth/auth.service'
import { useEffect } from 'react'

export const useGuideUnassign = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IGuideAssignResponse[]>()

  const guideUnassign = (request: IGuideUnassignRequest) => {

    datadogLogs.logger.info('DISPATCH_GUIDE_UNASSIGN', { action: 'DISPATCH_GUIDE_UNASSIGN',
      guideUnAssign: request,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
      guideId: request.dispatchGuideId
    })
    
    execute({
      method: 'PATCH',
      url: '/dispatch-guide/unassign-order',
      data: request,
    })
    
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('DISPATCH_GUIDE_UNASSIGN:POST', { action: 'DISPATCH_GUIDE_UNASSIGN:POST',
        guideUnAssign: response,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
    }
  }, [response])

  return {
    guideUnassign,
    loading,
    guideUnassigned: response
  }
}
