import React, { } from 'react'
import { Table, Tag, Tooltip, Modal } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import useMeasureY from '../../../../../hooks/use-measurey'
import { IOrderShopify } from '../../../../../services/order-shopify/order-shopify.interface'
import { useOrderShopifyStore } from '../../../../../services/order-shopify/order-shopify.store'
import { useDrawer } from '../../../../../context/DrawerContext'
import { EditFilled, MessageOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { formatDate } from '../../../../../utils/date'
import { CALL_STATUS_LABEL } from '../../../../../services/pre-order/pre-order.params'
import { useOrderShopify } from '../../../../../services/pre-order/pre-order.edit'
import { useTemplateMsg } from '../../../../../services/templateMsg/template.service'

const confirm = Modal.confirm

interface IOrderShopifyTableProps {
  orders: IOrderShopify[]
  loading: boolean;
}

const OrderShopifyTable: React.FC<IOrderShopifyTableProps> = (
  { orders, loading }
) => {
  const { windowSize } = useMeasureY()
  const { setOrderShopifySelected } = useOrderShopifyStore()
  const { openDrawer } = useDrawer()
  const isSeller = useOrderShopify()
  const { getOrderShopifyFollow } = useTemplateMsg()

  const showConfirm = (data: IOrderShopify) => {
    confirm({
      title: 'Seleccione el tipo de agendamiento para el pedido',
      content: '',
      okText: 'Contraentrega',
      cancelText: 'DropShalom',
      onOk() {
        setOrderShopifySelected(data)
        openDrawer('ORDER_SHOPIFY_CREATE', 'Crear pedido')
      },
      onCancel() {
        setOrderShopifySelected(data)
        openDrawer('ORDER_SHOPIFY_AGENCY_EDIT', 'Crear pedido') 
      },
    })
  }

  const columns: ColumnsType<IOrderShopify> = [
    {
      title: '#Pedido',
      dataIndex: 'orderNumber',
      align: 'center',
      render: (_, order) => <>
        <>{order.orderNumber}</><br/>
        <>{order.companyName} - {order.companyCode}</><br/>
        <Tag color={CALL_STATUS_LABEL[order.status].color}>
          {CALL_STATUS_LABEL[order.status].label}
        </Tag>
      </>
    },
    {
      title: '', dataIndex: 'comunications', align: 'center', width: 80,
      render: (_, order) => <>
        <div className="PreOrderActionIcons">
          <a target="_blank" rel="noreferrer" 
            href={`https://api.whatsapp.com/send?phone=${order.shipping.phone.split('+')[1]}`}>
            <Tooltip placement="bottom" title="WhatsApp">
              <WhatsAppOutlined style={{ color: '#009688' }}/>
            </Tooltip>
          </a>
          <a target="_blank" rel="noreferrer"
            href={`https://api.whatsapp.com/send?phone=${order.shipping.phone.split('+')[1]}}
            &text=${getOrderShopifyFollow(order)}`}>
            <Tooltip placement="bottom" title="Mensaje">
              <MessageOutlined
                className='PreOrderActionIcons-blue'
              />
            </Tooltip>
          </a>
          <a href={`tel:${order.shipping.phone}`}>
            <Tooltip placement="bottom" title="Llamar">
              <PhoneOutlined
                className='PreOrderActionIcons-gray'
              />
            </Tooltip>
          </a>
        </div>
      </>
    },
    {
      title: 'Cliente',
      dataIndex: 'customer',
      align: 'center',
      render: (_, order) => <>
        <>{order.customer.firstName} {order.customer.lastName}</><br/>
        <>{order.shipping.phone}</>
      </>,
    },
    {
      title: 'Fecha',
      dataIndex: 'createdAt', align: 'center',
      render: (_, order) => <>
        <>{formatDate(order.createdAt)}</>
      </>
    },
    {
      title: 'Productos',
      dataIndex: 'items',
      align: 'center',
      render: (_, order) => <>
        <>{order.items.map(i => <span key={i.productShopifyId}>{i.quantity} {i.name}<br/></span>)}</>
      </>,
    },
    {
      title: 'Dirección',
      dataIndex: 'shipping',
      align: 'center',
      render: (_, order) => <>
        <>{order.shipping.address}</><br/>
        <>{order.shipping.zip} - {order.shipping.city}</>
      </>
    },
    {
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
      render: (_, order) => <>
        <>{order.currency} {order.total}</>
      </>
    },
    {
      title: 'Nota',
      dataIndex: 'note', align: 'center',
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center', width: '80px',
      render: (_, order) => <>
        { isSeller ? <>
          <a onClick={() => showConfirm(order)}>
            <Tooltip placement="top" title="Crear pedido">
              <EditFilled style={{ fontSize: '1.1rem', margin: '0 .25rem', cursor: 'pointer' }} />
            </Tooltip>
          </a>

        </> : null}
        
      </>
    }
  ]

  return (
    <Table
      rowKey="id"
      dataSource={orders}
      columns={columns}
      loading={loading}
      scroll={{
        y: windowSize.height - 200,
      }}
      size="small"
    />
  )
}

export default OrderShopifyTable
