import React from 'react'
import { OrderShopifyListContainer } from './containers'

const OrderShopifyView = () => {
  return (
    <>
      <OrderShopifyListContainer/>
    </>
  )
}

export default OrderShopifyView
