import React, { FC } from 'react'
import { Table, TableColumnsType } from 'antd'
import {
  IWarehouseSkuAllResponse 
} from '../../../../../../services/warehouse-sku-all/warehouse-sku-all.interface'

interface IWarehouseSkusTableProps {
  record: IWarehouseSkuAllResponse[]
}

const WarehouseSkusTable: FC<IWarehouseSkusTableProps> = ({ record }) => {

  const columnsLevel3: TableColumnsType<IWarehouseSkuAllResponse> = [
    {
      title: '', width: '2.5%',
    },
    {
      title: 'Almacén',
      dataIndex: 'name', width: '20%',
      render: (_, w) => <>
        {w.warehouse.name}
      </>,
    },
    {
      title: 'Stock Bodega', width: '15%',
      dataIndex: 'stockStore', align: 'center',
      render: (_, w) => <>
        {w.stockStore}
      </>
    },
    {
      title: 'Stock Disponible', width: '15%',
      dataIndex: 'stockVirtual', align: 'center',
      render: (_, w) => <>
        {w.stockVirtual}
      </>,
    },
  ]

  return <>
    <Table
      rowKey="id"
      columns={columnsLevel3}
      dataSource={record}
      pagination={false}
      size="small"
    />
  </>
}

export default WarehouseSkusTable
