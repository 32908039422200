/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './liquidation.actions'
import { ILiquidationResponse } from '../../services/liquidation-list/liquidation-list.interface'
import { ILiquidationEditResponse } from '../../services/liquidation-edit/liquidation-edit.interface'
import {
  ILiquidationRecalculateResponse
} from '../../services/liquidation-recalculate/liquidation-recalculate.interface'

export interface ILiquidationState {
  liquidationSelected: ILiquidationResponse | null;
  liquidationUpdated: ILiquidationEditResponse | null;
  liquidationCalculated: ILiquidationRecalculateResponse | null
}

const initialState: ILiquidationState = {
  liquidationSelected: null,
  liquidationUpdated: null,
  liquidationCalculated: null,
}

export const liquidationReducer = (
  state: ILiquidationState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_LIQUIDATION_SELECTED:
    return {
      ...state,
      liquidationSelected: action.payload,
    }
  case actionTypes.SET_LIQUIDATION_UPDATED:
    return {
      ...state,
      liquidationUpdated: action.payload,
    }
  case actionTypes.SET_LIQUIDATION_CALCULATED:
    return {
      ...state,
      liquidationCalculated: action.payload
    }
  default:
    return state
  }
}
