import React, { useEffect } from 'react'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useTicketStore } from '../../../../../services/ticket-support-create/ticket-support.store'
import { useTicketCreate } from '../../../../../services/ticket-support-create/ticket-support-create.service'
import { ITicketSchema } from '../../../../../services/ticket-support-list/ticket-support.schema'
import { Spin } from 'antd'
import { TicketSupportEditForm } from '../../forms'

const TicketSupportEditContainer = () => {
  const { closeDrawer } = useDrawer()
  const { setTkUpdated } = useTicketStore()
  const { createNewTicket, ticketCreated, loading } = useTicketCreate()

  const handleSubmit = (data: ITicketSchema) => {
    createNewTicket(data)
  }

  useEffect(() => {
    if (ticketCreated) {
      setTkUpdated(ticketCreated)
      closeDrawer()
    }
  }, [ticketCreated])

  return (
    <>
      <Spin spinning={loading}>
        <TicketSupportEditForm
          onSubmit={handleSubmit}
        />
      </Spin>
    </>
  )
}

export default TicketSupportEditContainer
