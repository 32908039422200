export const MOVEMENT_OPERATION_LIST = {
  PURCHASE: 'PURCHASE',
  RETIRE: 'RETIRE',
  TRANSFER: 'TRANSFER',
  SALE: 'SALE',
  PRE_SALES: 'PRE_SALES',
  RETURN: 'RETURN',
}

export const MOVEMENT_OPERATION_LABEL_LIST: { [key: string]: { label: string } } = {
  PURCHASE: {
    label: 'COMPRA'
  },
  RETIRE: {
    label: 'RETIRO'
  },
  TRANSFER: {
    label: 'TRANSFERENCIA'
  },
  SALE: {
    label: 'VENTA',
  },
  PRE_SALES: {
    label: 'PRE_VENTA',
  },
  RETURN: {
    label: 'DEVUELTO'
  },
  REGULARIZE: {
    label: 'REGULARIZADO'
  }
}
