import React from 'react'
import { Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { 
  IOrderToReturn,
} from '../../../../../services/order-return/order-return.interface'
import { 
  ORDER_DISPATCH_STATUS_LABEL,
  ORDER_STATUS_LABEL,
} from '../../../../../services/order-create/order.params'

interface IOrdersTableProps {
  orders: IOrderToReturn[]
  loading: boolean
}

const OrdersTable: React.FC<IOrdersTableProps> = (
  { orders, loading }
) => {

  const columns: ColumnsType<IOrderToReturn> = [
    {
      title: '#PEDIDO',
      dataIndex: 'orderNumber',
      align: 'center',
      render: (_, order) => <>{order.orderNumber}</>
    },
    {
      title: 'FECHA ENTREGA',
      dataIndex: 'deliveryDate', align: 'center',
      render: (_, order) => <>{order.deliveryDate}<br />{order.motorized}</>
    },
    {
      title: 'PRODUCTO(s)',
      dataIndex: 'productName', align: 'center',
      render: (_, order) => <>{order.productName}</>
    },
    {
      title: 'ESTADOS',
      dataIndex: 'status', align: 'center',
      render: (_, order) => <>
        <Tag color={ORDER_STATUS_LABEL[order.status]?.color}>
          {ORDER_STATUS_LABEL[order.status]?.label}
        </Tag>
        <Tag color='#757575'>
          {ORDER_DISPATCH_STATUS_LABEL[order.dispatchStatus].label}
        </Tag>
      </>
    },
    {
      title: 'ALMACÉN',
      dataIndex: 'warehouse', align: 'center',
      render: (_, order) => <>{order.warehouse}</>
    },
    {
      title: 'DEPARTAMENTO',
      dataIndex: 'department', align: 'center',
      render: (_, order) => <>{order.department}</>
    },
  ]

  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={orders}
      pagination={false}
      size='small'
      loading={loading}
    />
  )
}

export default OrdersTable
