import React, { FC } from 'react'
import moment from 'moment'
import { DatePicker, DatePickerProps } from 'antd'
import { IOrderFilter } from '../../../../../services/order-list/order.interface'

moment.locale('es-PE')

interface IOrderFilterFormProps {
  onChange: (filter: IOrderFilter) => void;
}

const OrderFilterForm: FC<IOrderFilterFormProps> = ({ onChange }) => {

  const handleChange: DatePickerProps['onChange'] = (date) => {
    onChange({
      createdAtFrom: date?.endOf('day').toJSON(),
      createdAtTo: date?.startOf('day').toJSON(),
    })
  }

  return <>
    <DatePicker
      placeholder='Día entrega'
      onChange={handleChange}
      defaultValue={moment()}
    />
  </>
}

export default OrderFilterForm
