import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { ILiquidationState } from '../../state/liquidation/liquidation.reducer'
import { ILiquidationResponse } from '../liquidation-list/liquidation-list.interface'
import { liquidationActions } from '../../state/liquidation/liquidation.actions'
import { ILiquidationEditResponse } from './liquidation-edit.interface'
import { ILiquidationRecalculateResponse } from '../liquidation-recalculate/liquidation-recalculate.interface'

export const useLiquidationStore = () => {
  const dispatch = useDispatch()
  const {
    liquidationSelected, liquidationUpdated, liquidationCalculated
  } = useSelector((state: IRootState) => state.liquidation as ILiquidationState)

  const setLiquidationSelected = (data: ILiquidationResponse | null) => {
    dispatch(liquidationActions.setLiquidationSelected(data))
  }

  const setLiquidationUpdated = (data: ILiquidationEditResponse) => {
    dispatch(liquidationActions.setLiquidationUpdated(data))
  }

  const setLiquidationCalculated = (data: ILiquidationRecalculateResponse) => {
    dispatch(liquidationActions.setLiquidationCalculated(data))
  }

  return {
    setLiquidationSelected,
    setLiquidationUpdated,
    setLiquidationCalculated,
    liquidationSelected,
    liquidationUpdated,
    liquidationCalculated,
  }
}
