import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Drawer, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Navbar, SidebarMenu } from '..'
import { MenuUnfoldOutlined } from '@ant-design/icons'
import './Layout.css'

const LayoutApp = () => {
  const [open, setOpen] = useState(false)

  const handleCloseDrawer = () => {
    setOpen(false)
  }

  return (
    <Layout style={{ height: '100vh', display: 'flex', overflow: 'auto', flexDirection: 'row' }}>
      <Drawer
        title="Menu"
        placement="left"
        onClose={handleCloseDrawer}
        open={open}
        destroyOnClose
        contentWrapperStyle={{
          width: '280px',
        }}
        bodyStyle={{
          padding: 0,
          marginTop: '1rem',
        }}
      >
        <SidebarMenu onSelect={() => setOpen(false)} />
      </Drawer>

      <Layout style={{ width: `calc(100% - ${240}px)`, background: '#fff' }}>
        <Navbar>
          {React.createElement(MenuUnfoldOutlined, {
            className: 'LayoutApp__collapsible',
            onClick: () => setOpen(!open),
          })}
        </Navbar>

        <Content
          style={{
            minHeight: 280,
            boxSizing: 'border-box',
            position: 'relative',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default LayoutApp
