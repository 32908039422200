import React, { useEffect } from 'react'
import { Table } from 'antd'
import { ICompanyItemResponse } from '../../../../../services/company-list/company-list.interface'
import { ColumnsType } from 'antd/lib/table'
import { useCompanyDelete, useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useDrawer } from '../../../../../context/DrawerContext'
import useMeasureY from '../../../../../hooks/use-measurey'
import { useAuthStore } from '../../../../../services/auth/auth.service'

interface IStoresListTableProps {
  companies: ICompanyItemResponse[];
  loading: boolean;
}

const StoresListTable: React.FC<IStoresListTableProps> = ({ companies, loading }) => {
  const { windowSize } = useMeasureY()
  const { openDrawer } = useDrawer()
  const { authUser } = useAuthStore()
  const { deleteCompany, companyDeleted } = useCompanyDelete()
  const { setCompanySelected, setCompanyUpdated } = useCompanyStore()

  const handleSelected = (data: ICompanyItemResponse) => {
    setCompanySelected(data)
    openDrawer('COMPANY_EDIT', 'Editar tienda')
  }

  const handleDeleted = (data: ICompanyItemResponse) => {
    deleteCompany(data.id)
  }

  useEffect(() => {
    if (companyDeleted) {
      setCompanyUpdated(companyDeleted)
    }
  }, [companyDeleted])

  const columns: ColumnsType<ICompanyItemResponse> = [
    /*{
      title: 'Id',
      dataIndex: 'id',
      align: 'center',
      width: 60,
    },*/
    {
      title: 'Empresa',
      dataIndex: 'name', align: 'center', width: '35%',
      render: (_, company) => <>
        {company.name} - {company.setting ? <>
          <b>{company.setting.companyPrefix}</b> <br/>({company.type})
        </> : null }
      </>
    },
    {
      title: 'Cuenta bancaria', align: 'center',
      render: (_, company) => company.setting && company.setting ? <>
        # {company.setting.nameBank ? company.setting.nameBank : '-'}<br/>
        - Nro. cuenta: {company.setting.accountNumber ? company.setting.accountNumber : '-'} - CCI
        : {company.setting.cci ? company.setting.cci : '-'}
        <br/>
        Nombre titular: {company.setting.holderName ? company.setting.holderName : '-'}
      </> : <>-</>
    },
    {
      title: 'Acciones', dataIndex: 'id', width: '10%', align: 'center',
      render: (_, company) => <>
        <a onClick={() => handleSelected(company)}>Editar</a>
        { authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' ? <>
          <a style={{ marginLeft: '12px', color: 'red' }} onClick={() => handleDeleted(company)}>Eliminar</a>
        </> : null}
      </>,
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={companies}
      columns={columns}
      loading={loading}
      pagination={{ pageSize: 20 }}
      scroll={{
        y: windowSize.height - 200,
      }}
      size="small"
    />
  )
}

export default StoresListTable
