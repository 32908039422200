import React, { FC, useEffect, useState } from 'react'
import { Badge, Button, Divider, Form, Select, Input, Radio } from 'antd'
import {
  OrderCustomerPart,
  OrderProductPart,
  OrderShippingPart,
} from './parts'
import { useProductStore } from '../../../../../services/product/product.store'
import TextArea from 'antd/lib/input/TextArea'
import { ORDER_STATUS_LABEL, ORDER_STATUS_LIST } from '../../../../../services/order-create/order.params'
import { IOrderUpdateSchema } from '../../../../../services/order-update/order-update.schema'
import moment from 'moment'
import { IOrderItemResponse } from '../../../../../services/order-list/order.interface'
import { useUserMaster, useUserMasterAdmin, useUserSupSeller } from '../../../../../services/order-create/order.edit'
import { useSellerList } from '../../../../../services/user-seller-list/user-seller-list.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { differenceDays } from '../../../../../utils/date'

const { Option } = Select

interface IOrderEditFormProps {
  initialValues: IOrderItemResponse | null
  onSubmit: (data: IOrderUpdateSchema) => void
  disabled: boolean
  isField: boolean
  companyUser: number
}

const OrderEditForm: FC<IOrderEditFormProps> = ({ 
  initialValues, onSubmit, disabled, isField, companyUser 
}) => {
  const [form] = Form.useForm<IOrderUpdateSchema>()
  const { setProductEmpty } = useProductStore()
  const status = Form.useWatch('status', form)
  const [showStatus, setShowStatus] = useState(true)
  const [field, setField] = useState<boolean>(isField)
  const [isDate, setIsDate] = useState<boolean>(true)
  const [isEnabled, setIsEnabled] = useState<boolean>(disabled)
  const [selectStatus, setSelectStatus] = useState<boolean>(false)
  const [schedule, setSchedule] = useState<boolean>(false)
  const { isUser } = useUserSupSeller()
  const { isMaster } = useUserMaster()
  const { rolUserMaster } = useUserMasterAdmin()
  const { getSellers, sellers } = useSellerList()
  const { companyGlobal } = useCompanyStore()
  const seller = Form.useWatch(['user', 'fullname'], form)
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form)
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form)
  const districtId = Form.useWatch(['shipping', 'districtId'], form)

  useEffect(() => {
    if (seller && sellers.length > 0) {
      const s = sellers.find(i => i.fullname == seller)

      form.setFieldValue(['user', 'id'], s?.id)
    }
  }, [seller, sellers])

  useEffect(() => {

    if (initialValues !== null) {

      if (isUser) {
        getSellers(companyGlobal ? `${companyGlobal.id}` : '')
      }

    }

    if (initialValues) {
      setProductEmpty()

      const shipping = initialValues.shipping

      form.setFieldsValue({
        status: initialValues.status,
        note: initialValues.note,
        channel: initialValues.channel,
        comment: initialValues.comment,
        shippingCost: initialValues.shippingCost,
        returnCost: initialValues.returnCost,
        additionalDeliveryCost: initialValues.additionalDeliveryCost,
        callStatus: initialValues.callStatus,
        isProductRemnant: initialValues.isProductRemnant,
        user: {
          id: initialValues.user.id,
          fullname: initialValues.user.fullname,
        },
        customer: {
          name: initialValues.customerHistory?.name,
          lastName: initialValues.customerHistory?.lastName,
          phone: initialValues.customerHistory?.phone,
          companyId: initialValues.customerHistory?.companyId,
        },
        shipping: {
          id: shipping.id,
          address1: shipping?.address1,
          address2: shipping?.address2,
          reference: shipping?.reference,
          gps: (shipping?.lat.length > 0 && shipping?.lng.length > 0) ? `${shipping?.lat},${shipping?.lng}` : '',
          department: shipping?.departmentName,
          departmentId: Number.parseInt(shipping?.departmentCode),
          province: shipping?.provinceName,
          provinceId: Number.parseInt(shipping?.provinceCode),
          district: shipping?.districtName,
          districtId: Number.parseInt(shipping?.districtCode),
          dispatchDate: shipping?.dispatchDate ? moment(new Date(shipping.dispatchDate), 'DD/MM/YYYY') : '',
          scheduleDate: shipping?.scheduleDate ? moment(new Date(shipping.scheduleDate), 'DD/MM/YYYY') : '',
        }
      })
    }
  }, [initialValues])

  useEffect(() => {
    if (companyUser === 2) {
      if (initialValues?.status === ORDER_STATUS_LIST.DELIVERED || 
        initialValues?.status === ORDER_STATUS_LIST.NOT_DISPATCH) {
        setField(true)
        setIsDate(true)
        setIsEnabled(true)
        setSelectStatus(true)
      } else {
        setSelectStatus(true)
        setIsDate(false)
        if (initialValues?.isWarehouseMain === false) {
          setShowStatus(true)
          if (status === ORDER_STATUS_LIST.DELIVERED || status === ORDER_STATUS_LIST.NOT_DISPATCH) {
            setField(false)
            setIsEnabled(false)
          } else if (status === ORDER_STATUS_LIST.PENDING_DELIVERY) {
            if (initialValues?.status === ORDER_STATUS_LIST.PENDING_DELIVERY &&
              differenceDays(initialValues.shipping.scheduleDate) == 0) {
              setField(false)
              setIsDate(true)
              setIsEnabled(true)
            } else if (initialValues.status === ORDER_STATUS_LIST.PENDING_DELIVERY &&
              differenceDays(initialValues.shipping.scheduleDate) > 0) {
              setIsDate(false)
              setIsEnabled(false)
              setField(false)
            } else {
              setField(false)
              setIsDate(true)
              setIsEnabled(true)
            }
          } else if (status === ORDER_STATUS_LIST.RESCHEDULED) {
            setField(true)
            setIsEnabled(true)
          } else {
            setField(false)
            setIsEnabled(false)
          }
        } else {
          setShowStatus(false)
          //form.setFieldValue('status', ORDER_STATUS_LIST.RESCHEDULED)
          if (status === ORDER_STATUS_LIST.RESCHEDULED) {
            setField(true)
            setIsEnabled(true)
          } else if (status === ORDER_STATUS_LIST.PENDING_DELIVERY) {
            setField(false)
            setIsDate(true)
            setIsEnabled(true)
          } else {
            setField(true)
            setIsEnabled(true)
            setIsDate(true)
          }
        }
      }
    } else if (companyUser === 1) {
      if (initialValues?.status === ORDER_STATUS_LIST.DELIVERED || 
        initialValues?.status === ORDER_STATUS_LIST.NOT_DISPATCH) {
        setField(true)
        setIsDate(true)
        setIsEnabled(true)
        setSelectStatus(true)
      } else {
        setField(false)
        setIsDate(false)
      }
    } else if (companyUser === 3) {
      setField(false)
      setIsDate(false)
      setIsEnabled(false)
      setSelectStatus(false)
    }
  }, [status])

  useEffect(() => {
    if (status === ORDER_STATUS_LIST.RESCHEDULED) {
      if (companyUser === 2) {
        setSchedule(true)
      }
    }
  }, [status])

  return <>
    <Form
      layout="horizontal"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      form={form}
      name="order"
      labelWrap
      onFinish={onSubmit}
    >

      <Form.Item name="callStatus" hidden={true}>
        <Input />
      </Form.Item>

      <Form.Item name="status" label="Estado" rules={[{ required: true, message: '' }]}>
        <Select placeholder="Seleccione una opción" disabled={selectStatus}>
          { showStatus ? <>
            {Object.keys(ORDER_STATUS_LABEL).map(key => (
              <Option
                key={key}
                value={key}
              >
                <Badge style={{ marginRight: '.5rem' }} color={ORDER_STATUS_LABEL[key].color} text="" />
                {ORDER_STATUS_LABEL[key].label}
              </Option>
            ))}
          </> : <>
            <Option
              key={ORDER_STATUS_LIST.PENDING_DELIVERY}
              value={ORDER_STATUS_LIST.PENDING_DELIVERY}
            >
              <Badge style={{ marginRight: '.5rem' }} 
                color={ORDER_STATUS_LABEL[ORDER_STATUS_LIST.PENDING_DELIVERY].color} text="" />
              {ORDER_STATUS_LABEL[ORDER_STATUS_LIST.PENDING_DELIVERY].label}
            </Option>
            <Option
              key={ORDER_STATUS_LIST.RESCHEDULED}
              value={ORDER_STATUS_LIST.RESCHEDULED}
            >
              <Badge style={{ marginRight: '.5rem' }} 
                color={ORDER_STATUS_LABEL[ORDER_STATUS_LIST.RESCHEDULED].color} text="" />
              {ORDER_STATUS_LABEL[ORDER_STATUS_LIST.RESCHEDULED].label}
            </Option>
          </> }
          
        </Select>
      </Form.Item>

      <Form.Item label="Nota" name="note">
        <TextArea rows={1} disabled={isEnabled}/>
      </Form.Item>

      <Form.Item label="Costo de entrega" name="shippingCost">
        <Input disabled={disabled}/>
      </Form.Item>

      <Form.Item label="Costo de devolución" name="returnCost">
        <Input disabled={disabled}/>
      </Form.Item>

      { rolUserMaster ? <>
        <Form.Item label="Costo adicional" name="additionalDeliveryCost">
          <Input disabled={disabled}/>
        </Form.Item>
      </> : <>
        <Form.Item label="Costo adicional" name="additionalDeliveryCost" hidden>
          <Input disabled={disabled}/>
        </Form.Item>
      </>}

      <Form.Item name="channel" label="Canal" rules={[{ required: true, message: '' }]}>
        <Select
          disabled={!!initialValues}
          placeholder="Seleccione una opción">
          <Option value="Shopify">Shopify</Option>
          <Option value="Whatsapp">Whatsapp</Option>
          <Option value="Llamada">Llamada</Option>
          <Option value="Redes sociales">Redes sociales</Option>
        </Select>
      </Form.Item>

      <Form.Item name={['user', 'id']} hidden={true}>
        <Input />
      </Form.Item> 

      { initialValues && isUser ? <>
        <Form.Item name={['user', 'fullname']} label="Vendedor">
          <Select placeholder="Seleccione un vendedor" disabled={field}>
            { sellers.map(s => (
              <Option key={s.id} value={s.fullname}>
                { s.fullname }
              </Option>
            ))}
          </Select>
        </Form.Item>
      </> : null }

      <Divider />

      <OrderCustomerPart
        disabled={isEnabled}
        companyUser={companyUser}
        isField={field}
      />

      <Divider />

      <OrderShippingPart 
        form={form}
        companyUser={companyUser}
        disabled={isEnabled}
        isField={field}
        isDate={isDate}
        warName={initialValues?.warehouseName || ''}
        deliveryDate={initialValues?.shipping?.scheduleDate || undefined}
        dispatchDateCurrent={initialValues?.shipping?.dispatchDate || undefined}
      />

      <Form.Item label="Comentario" name="comment" hidden={isDate}>
        <TextArea rows={2}/>
      </Form.Item>

      { departmentId && provinceId && districtId ? <>

        <Divider />

        { initialValues?.status !== 'DELIVERED' && isMaster === true ? <>

          <Form.Item name="isProductRemnant" label="¿Tiene producto sobrantes?">
            <Radio.Group
              options={[
                { label: 'No', value: false },
                { label: 'Si', value: true },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        
        </> : <>
          <Form.Item name="isProductRemnant" hidden>
            <Input />
          </Form.Item>
        </>

        }
      
        { initialValues?.orderDetails ? (
          
          <OrderProductPart
            form={form}
            orderDetails={initialValues?.orderDetails}
            disabled={isEnabled}
            flagSchedule={schedule}
            warehouseName={initialValues?.warehouseName || ''}
            warCompanyId={initialValues?.warehouseHistory?.companyId || -1}
          />

        ) : null}
      
      </> : null }

      <Divider />

      {/* <PreOrderAgencyPart form={form} /> */}

      <Form.Item 
        hidden={companyUser == 2 ? (isDate ? (field ? isEnabled : field) : isDate) : (field ? isEnabled : field)}>
        <Button type="primary" htmlType="submit" size="large">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  </>
}

export default OrderEditForm
