import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { catalogActions } from '../../state/catalog/catalog.actions'
import { ICatalogState } from '../../state/catalog/catalog.reducer'
import { ICatalogPrivate } from './catalog.interface'

export const useCatalogStore = () => {
  const dispatch = useDispatch()
  const {
    catalogSelected, catalogUpdated,
  } = useSelector((state: IRootState) => state.catalog as ICatalogState)

  const setCatalogSelected = (data: ICatalogPrivate | null) => {
    dispatch(catalogActions.setCatalogSelected(data))
  }

  const setCatalogUpdated = (data: ICatalogPrivate) => {
    dispatch(catalogActions.setCatalogUpdated(data))
  }

  return {
    setCatalogSelected,
    setCatalogUpdated,
    catalogSelected,
    catalogUpdated,
  }
}
