import React, { FC, useEffect, useState } from 'react'
import {
  Button,
  Form,
  Input,
  Select,
  Radio,
  RadioChangeEvent,
  InputNumber,
  Upload,
  message,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useTicketStore } from '../../../../../services/ticket-support-create/ticket-support.store'
import { ITicketSchema } from '../../../../../services/ticket-support-list/ticket-support.schema'
import { TICKET_STATUS_LABEL, TICKET_STATUS_LIST } from '../../../../../services/order-create/order.params'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { TYPE_MANAGEMENT_TOPIC } from '../../../../../services/ticket-support-list/ticket-support-list.interface'
import { useTopics } from '../../../../../services/ticket-support-list/ticket-support-list.service'
import { LinkOutlined } from '@ant-design/icons'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../../libs/firebase'

interface ITicketEditFormProps {
  onSubmit: (data: ITicketSchema) => void
}

const { Option } = Select
const RadioGroup = Radio.Group
const RadioButton = Radio.Button

const TicketSupportEditForm: FC<ITicketEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<ITicketSchema>()
  const { tkSelected } = useTicketStore()
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { getTopics, loadingTopics, topics } = useTopics()
  const [disable, setDisable] = useState(false)
  const [blockEdit, setBlockEdit] = useState(false)
  const [blockTopic, setBlockTopic] = useState(false)
  const [typeSelected, setTypeSelected] = useState(TYPE_MANAGEMENT_TOPIC.PLATFORM)
  const topic = Form.useWatch(['topic'], form)
  const topicTk = Form.useWatch(['topicTk'], form)
  const type = Form.useWatch(['type'], form)

  const [percent, setPercent] = useState(0)
  const [file, setFile] = useState<any>()
  const [url, setUrl] = useState('')
  const urlImage = Form.useWatch(['urlImage1'], form)

  const [percent2, setPercent2] = useState(0)
  const [file2, setFile2] = useState<any>()
  const [url2, setUrl2] = useState('')
  const urlImage2 = Form.useWatch(['urlImage2'], form)

  const [percent3, setPercent3] = useState(0)
  const [file3, setFile3] = useState<any>()
  const [url3, setUrl3] = useState('')
  const urlImage3 = Form.useWatch(['urlImage3'], form)

  const [resolved, setResolved] = useState(true)

  useEffect(() => {
    form.setFieldValue(['urlImage1'], url)
  }, [url])

  useEffect(() => {
    form.setFieldValue(['urlImage2'], url2)
  }, [url2])

  useEffect(() => {
    form.setFieldValue(['urlImage3'], url3)
  }, [url3])

  function handleBeforeUpload(file: any) {
    setFile(file)
    if (!file) {
      message.success('Debe seleccionar una evidencia')

      return
    }

    const storageRef = ref(storage, `/files_ticket_support/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercent(percent)
      }, (error) => {
        console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url)
        })
      }
    )

    return false
  }

  function handleBeforeUpload2(file: any) {
    setFile2(file)
    if (!file) {
      message.success('Debe seleccionar una evidencia')

      return
    }

    const storageRef = ref(storage, `/files_ticket_support/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercent2(percent)
      }, (error) => {
        console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl2(url)
        })
      }
    )

    return false
  }

  function handleBeforeUpload3(file: any) {
    setFile3(file)
    if (!file) {
      message.success('Debe seleccionar una evidencia')

      return
    }

    const storageRef = ref(storage, `/files_ticket_support/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercent3(percent)
      }, (error) => {
        console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl3(url)
        })
      }
    )

    return false
  }

  useEffect(() => {
    if (tkSelected) {

      getTopics(tkSelected.ticketTopic?.typeManagementTopic || TYPE_MANAGEMENT_TOPIC.PLATFORM)

      form.setFieldsValue({
        id: tkSelected.id,
        title: tkSelected.title,
        reason: tkSelected.reason,
        phone: tkSelected.phone,
        status: tkSelected.status,
        comment: tkSelected.comment,
        companyId: tkSelected.companyId,
        createdBy: tkSelected.user.id,
        orderNumber: tkSelected.orderNumber,
        urlImage1: tkSelected.urlEvidence01,
        urlImage2: tkSelected.urlEvidence02,
        urlImage3: tkSelected.urlEvidence03,
        type: tkSelected.ticketTopic?.typeManagementTopic,
        topic: `${tkSelected.ticketTopic?.id}`,
      })

      setResolved(false)

      form.setFieldValue('servedBy', authUser.user.id)
      form.setFieldValue('userServed', { id: authUser.user.id, email: authUser.user.email })

      if (authUser.user.role.name === 'ADMIN') {
        setDisable(true)
      } else {
        setDisable(false)
      }

      if (tkSelected.status === TICKET_STATUS_LIST.ATTENDED) {
        setDisable(true)
        setBlockEdit(true)
        setBlockTopic(true)
      } else if ((authUser.user.role.name === 'ADMIN_STORE' || authUser.user.role.name === 'SELLER')
        && tkSelected.status === TICKET_STATUS_LIST.OPEN) {
        setBlockEdit(false)
        setBlockTopic(true)
      } else {
        setBlockEdit(false)
        setBlockTopic(true)
      }

    } else {

      getTopics(TYPE_MANAGEMENT_TOPIC.PLATFORM)

      form.setFieldValue('id', null)
      form.setFieldValue('type', TYPE_MANAGEMENT_TOPIC.PLATFORM)
      form.setFieldValue('status', TICKET_STATUS_LIST.OPEN)
      form.setFieldValue('company', companyGlobal ? companyGlobal.id === 0 ? 
        authUser.user.company.id : companyGlobal.id : authUser.user.company.id,)
      form.setFieldValue('createdBy', authUser.user.id)
      setBlockEdit(false)
      setResolved(true)
      
    }
  }, [tkSelected])

  const onChangeTypeManagement = (e: RadioChangeEvent) => {
    getTopics(e.target.value)
    setTypeSelected(e.target.value)
    setResolved(true)
    form.setFieldValue(['topic'], undefined)
    form.setFieldValue(['topicTk'], undefined)
  }

  useEffect(() => {
    if (topic && topics.length > 0) {

      const tt = topics.find(t => `${t.id}` === `${topic}`)

      if (tt) {

        if (tt.ticketTopicLinks.length === 0) {
          setResolved(false)

          form.setFieldValue('topicTk', {
            links: [],
            phone: tt.reponsiblePhone,
          })
        } else {

          if (tkSelected) {
            setResolved(false)

          } else {
            setResolved(true)

          }

          form.setFieldValue('topicTk', {
            links: tt.ticketTopicLinks,
            phone: tt.reponsiblePhone,
          })
          
        }

        form.setFieldValue('topic', tt.id)
      }

    }
  }, [topic, topics])

  const handleResolvedChange = (e: any) => {
    setResolved(e.target.value)
  }

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='TkForm'
        labelWrap
        onFinish={onSubmit}>

        <Form.Item name={['id']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['companyId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['createdBy']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['servedBy']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['userServed']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['topicTk']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['type']} label='Tipo gestión'
          rules={[{ required: true, message: '' }]}>
          <Radio.Group onChange={onChangeTypeManagement} value={typeSelected} disabled={blockTopic}>
            <Radio.Button value={TYPE_MANAGEMENT_TOPIC.PLATFORM} checked>PLATAFORMA</Radio.Button>
            <Radio.Button value={TYPE_MANAGEMENT_TOPIC.COURIER}>COURIER</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label='Asunto'
          name={['topic']}
          rules={[{ required: true, message: '' }]}>
          <Select
            showSearch
            disabled={blockTopic}
            optionFilterProp='label'
            loading={loadingTopics}
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
            options={topics.map(e => ({
              label: e.topic,
              value: e.id,
            }))}
          />
        </Form.Item>

        { topicTk && topicTk.links.length > 0 ? <>
          
          <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column',
            justifyContent: 'space-between', marginBottom: '1rem' }}>
            <p style={{ fontSize: 12, fontStyle: 'italic', textAlign: 'end' }}>
              Recuerda que nos esforzamos en atenderte lo más pronto posible, puedes revisar el 
              siguiente recurso mientras revisamos tu solicitud:
            </p>
            { topicTk.links.map((l: any) => {
              return (<>
                <a 
                  target="_blank"
                  rel="noreferrer" href={l.url}
                  style={{ fontWeight: 'bold' }}
                ><LinkOutlined /> {l.name}
                </a>
              </>)
            })}
            
          </div>

          { !tkSelected ? <>
            
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <h4>¿El material mostrado le fue útil para resolver su duda?</h4>
              <RadioGroup 
                style={{ marginLeft: '12px', marginRight: '12px', marginBottom: '12px' }}
                onChange={handleResolvedChange}
                defaultValue={resolved}>
                <RadioButton value={true}>SI</RadioButton>
                <RadioButton value={false}>NO</RadioButton>
              </RadioGroup>
            </div>
            
          </> : null }
        
        </> : <>
        
        </> }

        { resolved === false ? <>

          <Form.Item name={['title']} label='Título'
            rules={[{ required: true, message: '' }]}>
            <Input disabled={disable}/>
          </Form.Item>

          { type && type === TYPE_MANAGEMENT_TOPIC.COURIER ? <>
            <Form.Item name={['orderNumber']} label='#Pedido(s)'
              rules={[{ required: true, message: '' }]}>
              <Input disabled={disable}/>
            </Form.Item>
          </> : null }

          <Form.Item
            label='Detalle'
            name={['reason']}
            rules={[{ required: true, message: '' }]}
          >
            <TextArea disabled={disable} placeholder='Cuentanos más acerca de tu solicitud.'/>
          </Form.Item>

          <Form.Item
            label='Teléfono'
            name={['phone']}
            rules={[{ required: true, message: 'Obligatorio' }]}
          >
            <InputNumber
              controls={false}
              maxLength={11}
              disabled={disable}
              style={{ display: 'inline-block', width: '100%' }}
            />
          </Form.Item>      

          { tkSelected ? <>
            <Form.Item
              label='Respuesta'
              name={['comment']}
              rules={[{ required: !(blockEdit ? blockEdit : !disable), message: 'Obligatorio' }]}
            >
              <TextArea disabled={blockEdit ? blockEdit : !disable}/>
            </Form.Item>

            <Form.Item
              label='Estado'
              name={['status']}
              rules={[{ required: !(blockEdit ? blockEdit : !disable), message: 'Obligatorio' }]}
            >
              <Select placeholder="Seleccione estado del ticket" disabled={blockEdit ? blockEdit : !disable}>
                <Option key={TICKET_STATUS_LIST.OPEN} value={TICKET_STATUS_LIST.OPEN}>
                  {TICKET_STATUS_LABEL[TICKET_STATUS_LIST.OPEN].label}
                </Option>
                <Option key={TICKET_STATUS_LIST.IN_PROCESS} value={TICKET_STATUS_LIST.IN_PROCESS}>
                  {TICKET_STATUS_LABEL[TICKET_STATUS_LIST.IN_PROCESS].label}
                </Option>
                <Option key={TICKET_STATUS_LIST.ATTENDED} value={TICKET_STATUS_LIST.ATTENDED}>
                  {TICKET_STATUS_LABEL[TICKET_STATUS_LIST.ATTENDED].label}
                </Option>
              </Select>
            </Form.Item>
          </> : null }

          <Form.Item name={['urlImage1']} label="Subir evidencia 1:"
            rules={[{ required: true, message: 'Obligatorio' }]}
          >
            <Upload
              name='file'
              beforeUpload={handleBeforeUpload}
              maxCount={1}
              fileList={[]}
              disabled={disable}
            >
              <Button disabled={disable}>
                {file ? file.name : ''} {percent} %
              </Button>
            </Upload>
            { urlImage?.length !== 0 ? <>
              <a 
                href={urlImage} 
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >Ver evidencia 1</a>
            </> : null }
          </Form.Item>

          <Form.Item name={['urlImage2']} label="Subir evidencia 2:">
            <Upload
              name='file'
              beforeUpload={handleBeforeUpload2}
              maxCount={1}
              fileList={[]}
              disabled={disable}
            >
              <Button disabled={disable}>
                {file2 ? file2.name : ''} {percent2} %
              </Button>
            </Upload>
            { urlImage2?.length !== 0 ? <>
              <a 
                href={urlImage2} 
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >Ver evidencia 2</a>
            </> : null }
          </Form.Item>

          <Form.Item name={['urlImage3']} label="Subir evidencia 3:">
            <Upload
              name='file'
              beforeUpload={handleBeforeUpload3}
              maxCount={1}
              fileList={[]}
              disabled={disable}
            >
              <Button disabled={disable}>
                {file3 ? file3.name : ''} {percent3} %
              </Button>
            </Upload>
            { urlImage3?.length !== 0 ? <>
              <a 
                href={urlImage3} 
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >Ver evidencia 3</a>
            </> : null }
          </Form.Item>

        

          <Button type='primary' htmlType='submit' hidden={blockEdit}>
            Guardar
          </Button>

        </> : null }

      </Form>
    </>
  )
}

export default TicketSupportEditForm
