export interface ITicketSupport {
  id: number
  title: string
  requestedAt: string
  orderNumber?: string
  user: {
    id: number
    email: string
  }
  reason: string
  phone?: string
  servedAt: string
  userServed?: {
    id: number
    email: string
  }
  status: string
  company?: {
    id: number
    name: string
  }
  companyId?: number
  comment?: string
  ticketTopic?: ITopic
  urlEvidence01?: string
  urlEvidence02?: string
  urlEvidence03?: string
}

export interface ITopic {
  id: number
  typeManagementTopic: string,
  topic: string,
  ticketTopicLinks: ITopicLink[],
  reponsiblePhone?: string,
  responsible?: string,
}

export interface ITopicLink {
  id: number
  url: string
  position: number
  name: string
}

export interface ITicketSupportRequest {
  title: string
  reason: string
  comment?: string
  phone?: string
  topicId: number
  status: string
  companyId: number
  servedAt: string
  orderNumber?: string
  servedBy: number
  userServed: {
    id: number
    email: string
  }
  createdBy: number
  urlEvidence01?: string
  urlEvidence02?: string
  urlEvidence03?: string
}

export interface ITicketSupportUpdateRequest {
  reason: string
  comment?: string
  status: string
  phone?: string
  servedAt?: string
  servedBy?: number
  userServed?: {
    id: number
    email: string
  }
  urlEvidence01?: string
  urlEvidence02?: string
  urlEvidence03?: string
}

export const TYPE_MANAGEMENT_TOPIC = {
  PLATFORM: 'PLATFORM',
  COURIER: 'COURIER'
}
