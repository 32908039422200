/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IPaymentDeleteResponse } from './payment-delete.interface'
import { datadogLogs } from '../../hooks/use-datadog'
import { useEffect } from 'react'

export const usePaymentDelete = () => {
  const { authUser } = useAuthStore() 
  const { execute, response: paymentDeleted, loading } = useAxios<IPaymentDeleteResponse>()

  const paymentDelete = (paymentId: string) => {

    datadogLogs.logger.info('PAY_DELETE', { action: 'PAY_DELETE', pay: paymentId,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      }
    })

    execute({
      method: 'PATCH',
      url: `/payment/${paymentId}`,
      data: {
        isActive: false
      }
    })
  }

  useEffect(() => {
    if (paymentDeleted) {
      datadogLogs.logger.info('PAY_DELETE:POST', { action: 'PAY_DELETE:POST',
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        payResponse: paymentDeleted,
      })
    }
  }, [paymentDeleted])

  return {
    paymentDelete,
    paymentDeleted,
    loading,
  }
}
