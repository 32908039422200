import { IWarehouseResponse } from '../../services/warehouse-list/warehouse-list.interface'
import { IWarehouseCreateResponse } from '../../services/warehouse-create/warehouse-create.interface'

export const actionTypes = {
  SET_WAREHOUSE_SELECTED: 'SET_WAREHOUSE_SELECTED',
  SET_WAREHOUSE_UPDATED: 'SET_WAREHOUSE_UPDATED',
}

const setWarehouseSelected = (data: IWarehouseResponse | null) => ({
  type: actionTypes.SET_WAREHOUSE_SELECTED,
  payload: data,
})

const setWarehouseUpdated = (data: IWarehouseCreateResponse) => ({
  type: actionTypes.SET_WAREHOUSE_UPDATED,
  payload: data,
})

export const warehouseActions = {
  setWarehouseSelected,
  setWarehouseUpdated
}
