import React from 'react'
import { DepositListContainer } from './containers'

const ValidationDepositView = () => {
  return (
    <>
      <DepositListContainer/>
    </>
  )
}

export default ValidationDepositView
