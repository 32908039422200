import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { IOrderShopifyState } from '../../state/order-shopify/order-shopify.reducer'
import { IOrderShopify } from './order-shopify.interface'
import { orderShopifyActions } from '../../state/order-shopify/order-shopify.actions'

export const useOrderShopifyStore = () => {
  const dispatch = useDispatch()
  const {
    orderShopifySelected
  } = useSelector((state: IRootState) => state.orderShopify as IOrderShopifyState)

  const setOrderShopifySelected = (order: IOrderShopify | null) => {
    dispatch(orderShopifyActions.setOrderShopifySelected(order))
  }

  return {
    setOrderShopifySelected,
    orderShopifySelected,
  }
}
