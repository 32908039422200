/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './coverage.actions'
import { ICoverageItemResponse } from '../../services/coverage-create/coverage-create.interface'

export interface ICoverageState {
  coverageSelected: ICoverageItemResponse | null;
  coverageUpdated: ICoverageItemResponse | null;
}

const initialState: ICoverageState = {
  coverageSelected: null,
  coverageUpdated: null,
}

export const coverageReducer = (
  state: ICoverageState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_COVERAGE_SELECTED:
    return {
      ...state,
      coverageSelected: action.payload,
    }
  case actionTypes.SET_COVERAGE_UPDATED:
    return {
      ...state,
      coverageUpdated: action.payload,
    }
  default:
    return state
  }
}
