/* eslint-disable max-len */
import React, { FC, useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { EnvironmentOutlined, MessageOutlined, WhatsAppOutlined, PhoneOutlined } from '@ant-design/icons'
import { useTemplateMsg } from '../../../../../services/templateMsg/template.service'
import './OrderActionIcons.css'
import { ORDER_STATUS_LIST } from '../../../../../services/order-create/order.params'
import { IOrderItemResponse } from '../../../../../services/order-list/order.interface'
import { useCountryEdit } from '../../../../../services/pre-order/pre-order.edit'

interface IOrderActionIconsProps {
  order: IOrderItemResponse
}

const OrderActionIcons: FC<IOrderActionIconsProps> = ({ order }) => {
  const [message, setMessage] = useState<string>('')
  const { getOrderFollow, getOrderConfirm, getOrderSupport } = useTemplateMsg()
  const { requiredByCountry } = useCountryEdit()
  const [ messageSupport, setMessageSupport ] = useState<string>('')

  useEffect(() => {
    if (order) {
      const msg = order.status === ORDER_STATUS_LIST.PENDING_DELIVERY ? getOrderConfirm(order) : getOrderFollow(order)

      setMessageSupport(getOrderSupport(order))
      setMessage(msg)
    }
  }, [order])

  return <>
    <div className="OrderActionIcons">
      <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=${order.customerHistory.phone}`}>
        <Tooltip placement="bottom" title="WhatsApp">
          <WhatsAppOutlined
            className='OrderActionIcons-green'
          />
        </Tooltip>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://api.whatsapp.com/send?phone=${order.customerHistory.phone}&text=${message}`}>
        <Tooltip placement="bottom" title="Mensaje">
          <MessageOutlined
            className='OrderActionIcons-blue'
          />
        </Tooltip>
      </a>
    </div>
    <div className="OrderActionIcons">
      {(order.shipping?.lat && order.shipping?.lng) ? (
        
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.google.com/maps/search/?api=1&query=${order.shipping?.lat},${order.shipping?.lng}`}>
          <Tooltip placement="bottom" title="GPS">
            <EnvironmentOutlined
              className='OrderActionIcons-gray'
            />
          </Tooltip>
        </a>
        
      ) : null}

      <a href={`tel:+${order.customerHistory.phone}`}>
        <Tooltip placement="bottom" title="Llamar">
          <PhoneOutlined
            className='PreOrderActionIcons-gray'
          />
        </Tooltip>
      </a>
      { order.user.phone ? <>
        <a target="_blank" 
          rel="noreferrer"
          href={`https://api.whatsapp.com/send?phone=${requiredByCountry ? (order.user.phone.length === 9 ? `51${order.user.phone}` : (order.user.phone.length === 12 ? order.user.phone.replace('+', '') : order.user.phone)) : order.user.phone}&text=${messageSupport}`}>
          <Tooltip placement="bottom" title="Vendedor">
            <WhatsAppOutlined
              className='OrderActionIcons-blue'
            />
          </Tooltip>
        </a>   
      </> : null }
      
    </div>
  </>

}

export default OrderActionIcons
