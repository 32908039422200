import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Select, Typography } from 'antd'
import moment from 'moment'
import { downloadExcel } from 'react-export-table-to-excel'
import { useOrderListPayment } from '../../../../services/order-payment/order-payment.service'
import { useAuthStore } from '../../../../services/auth/auth.service'
import { PAYMENT_METHOD, PAYMENT_METHOD_LABEL } from '../../../../services/payment-create/payment.params'
import { 
  CurrentStockReport,
  DispatchReport,
  DropShalomReport,
  MovementsReport,
  OrderReport,
  ProductRemainingReport,
  ProductReport,
  StockReport
} from '../../form'

const { Title } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const ReportContainer = () => {
  const { authUser } = useAuthStore()
  const dateFormat = 'YYYY-MM-DD'
  const { getOrdersPayment, loadingPay, orders } = useOrderListPayment()
  const [paySelected, setPaySelected] = useState<string>(PAYMENT_METHOD.E)
  const [selectedDates, setSelectedDates] = useState([
    moment(new Date(), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])

  const header = [
    'FECHA ENTREGA', 'NRO. PEDIDO', 'MOTORIZADO', 'MÉTODO PAGO', 'TOTAL', 'TOTAL PAGADO', 'LINK PAGO', 'FECHA PAGO'
  ]

  function handleDownloadExcel() {

    downloadExcel({
      fileName: `order-pay-${selectedDates[0].format('yyyy-MM-DD')}-to-${selectedDates[1].format('yyyy-MM-DD')}`,
      sheet: 'react-export-table-to-excel',
      tablePayload: {
        header,
        // accept two different data structures
        body: orders.map(o => {
          return { 
            delivaryDate: o.deliveryDate,
            orderNumber: o.orderNumber,
            assignedMotorized: o.assignedMotorized,
            paymentMethod: o.paymentMethod,
            total: o.total,
            totalPay: o.totalPay,
            payLink: o.payLink,
            paymentDate: o.paymentDate
          }
        })
      }
    })
  }

  const handleCalendarChange = (dates: any, dateStrings: any) => {
    setSelectedDates(dates)
  }

  useEffect(() => {
    if (selectedDates.length === 2 && selectedDates[0] && selectedDates[1] && authUser.user.role.name === 'ADMIN') {
      getOrdersPayment(
        selectedDates[0].format('yyyy-MM-DD'),
        selectedDates[1].format('yyyy-MM-DD'),
        paySelected,
        authUser.user.company.countryCode
      )
    }
  }, [selectedDates])

  const handleChangePay = (pay: string) => {
    getOrdersPayment(
      selectedDates[0].format('yyyy-MM-DD'),
      selectedDates[1].format('yyyy-MM-DD'),
      pay,
      authUser.user.company.countryCode
    )

    setPaySelected(pay)
  }

  return <>
    <div>

      { authUser.user.role.name === 'ADMIN' ? <>
        
        <ProductRemainingReport />

        <Divider />

        <Title level={5} style={{ marginBottom: '0', alignContent: 'center', padding: '.0rem 1.5rem' }}>
          Reporte de pedidos por método de pago
        </Title>

        <div
          style={{
            padding: '.85rem 1.5rem', display: 'flex',
            gap: '1rem', alignItems: 'center',
          }}
        >

          <Title level={5} style={{ marginBottom: '0' }}>
            Seleccionar fecha
          </Title>

          <RangePicker format={dateFormat} 
            defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
            onCalendarChange={handleCalendarChange} clearIcon={false}/>

          <Title level={5} style={{ marginBottom: '0' }}>
            Método pago
          </Title>

          <Select placeholder='Método de pago' value={paySelected} onChange={handleChangePay}>
            <Option key={'ALL'} value={'ALL'}>TODOS</Option>
            {Object.keys(PAYMENT_METHOD_LABEL).map(key => (
              <Option key={key} value={key} >{PAYMENT_METHOD_LABEL[key].label}</Option>
            ))}
          </Select>

          <Button type='primary' onClick={handleDownloadExcel} 
            loading={loadingPay} disabled={orders.length === 0}>
              Descargar reporte
          </Button>

        </div>

        <DropShalomReport />

        <ProductReport />

      </> : null }

      { authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'ADMIN_STORE' ? <>
      
        <OrderReport />
  
      </> : null }

      { authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'ADMIN_STORE' ? <>
        <StockReport />
      </> : null }
      
      { authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'STORE' ? <>
        <DispatchReport />

        <MovementsReport />

        <CurrentStockReport />

      </> : null }

    </div>

  </>
}

export default ReportContainer
