import React, { FC, useEffect } from 'react'
import { Button, Divider, Form, Input, InputNumber } from 'antd'
import { IWarehouseCreateSchema } from '../../../../../services/warehouse-create/warehouse-create.schema'
import { useWarehouseStore } from '../../../../../services/warehouse-create/warehouse.store'
import { WarehouseLocationPart } from './parts'
import { useUsersList } from '../../../../../services/user-list/user-list.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import TextArea from 'antd/lib/input/TextArea'

interface IWarehouseEditFormProps {
  onSubmit: (data: IWarehouseCreateSchema) => void
}

const WarehouseEditForm: FC<IWarehouseEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<IWarehouseCreateSchema>()
  const { warehouseSelected } = useWarehouseStore()
  const { getStores } = useUsersList()
  const { companyGlobal } = useCompanyStore()

  useEffect(() => {
    if (warehouseSelected) {
      form.setFieldsValue({
        id: warehouseSelected.id,
        name: warehouseSelected.name,
        userId: warehouseSelected.userId,
        reference: warehouseSelected.reference,
        phone: warehouseSelected.phone,
      })
    }
  }, [warehouseSelected])

  useEffect(() => {
    if (companyGlobal) {
      getStores(companyGlobal ? `${companyGlobal.id}` : '')
    }
  }, [companyGlobal])

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='UserForm'
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item hidden name={['id']}>
          <Input />
        </Form.Item>
        
        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
          Nombre Almacén
          </span>}
          name={['name']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input style={{ display: 'inline-block', width: '100%', borderRadius: '8px' }}/>
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
          Telf. Soporte Almacén
          </span>}
          name={['phone']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <InputNumber
            controls={false}
            maxLength={11}
            style={{ display: 'inline-block', width: '100%', borderRadius: '8px' }}
          />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
          Contraentrega en
          </span>}
          name={['reference']} >
          <TextArea style={{ display: 'inline-block', width: '100%', borderRadius: '8px' }}/>
        </Form.Item>

        { !warehouseSelected ? <>
          <WarehouseLocationPart form={form} />
        </> : null }

        <Divider />

        <Button type='primary' htmlType='submit'>
          Guardar
        </Button>
      </Form>
    </>
  )
}

export default WarehouseEditForm
