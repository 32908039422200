import { datadogLogs } from '@datadog/browser-logs'

datadogLogs.init({
  clientToken: 'pub6e2c5df9d1caa3a58e2ed0e9ea80a2c7',
  site: 'us5.datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  env: 'web-prod',
  service: 'aliclik.app' //aliclik-develop
})

export {
  datadogLogs
}
