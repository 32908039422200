import React, { FC } from 'react'
import { Badge, Card, Divider } from 'antd'
import './OrdersMotorizedSummary.css'
import { MotocycleIcon } from '../../../../../components/Icons'
import { IMotorizedOrderGroup } from '../../../../../services/order-return/order-return.interface'

interface IOrderMotoSummaryProps {
  ordersMotorized: IMotorizedOrderGroup[]
}

const OrdersMotorizedSummary: FC<IOrderMotoSummaryProps> = ({ ordersMotorized }) => {
  return (
    <Card style={{ margin: '0.5rem 0.5rem' }}>
      <h4>Pedidos agrupados por motorizados</h4>

      <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))' }}>
        {ordersMotorized.sort((a, b) => b.quantityOrders - a.quantityOrders).map((orderByMoto) => (
          <div key={orderByMoto.motorized}>

            <div style={{ display: 'flex', gap: '0.35rem' }}>
              <Badge
                count={orderByMoto.quantityOrders}
                style={{ backgroundColor: orderByMoto.motorized === 'Sin asignar' ? '#ff0066' : '#1890ff' }}
              />
              <span style={{ color: '#888' }}>
                <MotocycleIcon />
              </span>
              <span style={{ fontSize: '16px' }}>
                {orderByMoto.motorized}
              </span>
            </div>

            <Divider style={{ margin: '.35rem 0' }} />

          </div>
        ))}
      </div>

    </Card>
  )
}

export default OrdersMotorizedSummary
