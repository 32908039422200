import React, { useEffect } from 'react'
import { PageHeader } from '../../../../../components'
import { CompanyChangeContainer } from '../../../../../containers'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useShopifyOrders } from '../../../../../services/order-shopify/order-shopify.service'
import { OrderShopifyTable } from '../../components'
import { Input } from 'antd'

const { Search } = Input

const OrderShopifyListContainer = () => {
  const { 
    orderAll, getOrderShopify, loading, getOrderShopifySearch
  } = useShopifyOrders()
  
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()

  useEffect(() => {

    if (authUser.user.company.countryCode === 'PER') {
      if (companyGlobal) {
        getOrderShopify(`${companyGlobal.id}`)
      } else {
        getOrderShopify(`${authUser.user.company.id}`)
      }
    } else if (authUser.user.company.countryCode === 'BOL') {
      getOrderShopify(`${authUser.user.company.id}`)
    }

  }, [companyGlobal])

  const onSearch = (value: string) => {

    if (value.length > 0) {

      getOrderShopifySearch(value.toLocaleLowerCase())

    } else {
      
      if (authUser.user.role.name === 'ADMIN_STORE' || authUser.user.role.name === 'SELLER') {
        getOrderShopify(`${authUser.user.company.id}`)
      } else if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'STORE') {
        if (companyGlobal) getOrderShopify(`${companyGlobal.id}`)
      }

    }
  }

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex', justifyContent: 'space-between', gap: '.5rem', alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
            {authUser.user.role?.permissions?.order_company_change && authUser.user.company.countryCode === 'PER' ? (
              <CompanyChangeContainer isAll={true} />
            ) : null}      
          </div>

          <Search placeholder="Buscar pedidos" onSearch={onSearch} enterButton allowClear style={{ width: 500 }} />

          <div>|</div>

        </div>
      </PageHeader>

      <OrderShopifyTable
        orders={orderAll}
        loading={loading}
      />
    </>
  )
}

export default OrderShopifyListContainer
